import SvgIcon from '@mui/material/SvgIcon';

function ThumbsUpOutlineIcon(props) {
  return (
    <SvgIcon {...props}>
      <g>
        <g>
          <g>
            <path
              fill="currentColor"
              stroke="none"
              d="M21,12.6l0,0.8h-2.5c0-0.1-0.1-0.3-0.1-0.4c0.1-0.1,0.1-0.3,0.2-0.4H21 M22.5,11.1h-5.3c0.1,0.2,0.1,0.4,0.1,0.6
				c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.4,0.1,0.5c0.2,0.2,0.3,0.6,0.2,0.9
				c0,0.1-0.1,0.3-0.1,0.4c0,0.1-0.1,0.2-0.1,0.2h5.8L22.5,11.1L22.5,11.1z"
            />
          </g>
        </g>
        <path
          fill="none"
          stroke="currentColor"
          d="M6.5,11.5c-0.1,0.1-0.2,0.3-0.3,0.5c-0.1,0.2-0.1,0.4-0.2,0.6c0,0.2-0.1,0.5-0.1,0.7c0,0.2,0,0.4,0,0.6
		c0,0.2,0,0.4,0,0.6c0,0.1,0,0.2,0,0.3c0,0,0,0.1,0,0.1c0,0.3,0,0.5,0,0.8c0,0.2,0,0.4,0,0.6c0,0.3,0,0.6,0.1,0.8
		c0,0.4,0.1,0.9,0.2,1.3c0.1,0.4,0.2,0.8,0.3,1.2c0,0.1,0.1,0.2,0.2,0.4c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0.1,0.1c0,0,0,0-0.1,0
		c-1.4,0-2.8,0-4.3,0c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1-0.3-0.2-0.7-0.2-1
		c0-0.1,0-0.2,0-0.3c0-0.1,0-0.3,0-0.4c0-0.2,0-0.4-0.1-0.7c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.4c0,0,0,0,0,0c0-0.2,0-0.3,0-0.5
		c0-0.2,0-0.3,0-0.5c0-0.3,0-0.7,0-1c0-0.2,0-0.4,0.1-0.6c0-0.1,0-0.2,0-0.3c0-0.2,0.1-0.4,0.1-0.6c0-0.2,0.1-0.4,0.2-0.5
		c0-0.1,0.1-0.2,0.2-0.2c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0.1,0C3.6,11.5,5,11.5,6.5,11.5C6.5,11.5,6.5,11.5,6.5,11.5z"
        />
        <path
          fill="none"
          stroke="currentColor"
          d="M16.8,12.9c0,0.2,0,0.4,0.1,0.5c0.2,0.2,0.3,0.6,0.2,0.9c0,0.1-0.1,0.3-0.1,0.4c0,0.1-0.1,0.2-0.1,0.2
		c0,0,0,0,0,0c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.3-0.1,0.5c0,0.1,0,0.1,0,0.2c0.1,0.3,0.1,0.7-0.1,1
		c-0.1,0.1-0.2,0.3-0.3,0.4c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.2-0.1,0.4,0,0.6c0,0.1,0,0.1,0,0.2
		c0,0.2,0,0.5-0.1,0.7c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.1-0.5,0.2-0.7,0.3c-0.3,0.1-0.7,0.1-1,0.1
		c-1.1,0-2.3,0-3.5,0c-0.6,0-1.2-0.1-1.8-0.4c-0.1-0.1-0.3-0.1-0.4-0.2c-0.2-0.1-0.4-0.3-0.5-0.6c-0.1-0.3-0.2-0.6-0.3-0.9
		c-0.1-0.3-0.1-0.6-0.1-0.9C6.6,17,6.6,16.5,6.5,16c0-0.3,0-0.6,0-0.9v-0.3c0-0.1,0-0.1,0-0.2c0-0.3,0-0.5,0-0.8
		c0-0.2,0-0.4,0.1-0.6c0-0.3,0.1-0.6,0.1-0.8c0-0.1,0-0.1,0.1-0.2c0,0,0-0.1,0.1-0.1c0.1,0,0.2,0,0.3,0c0.3,0,0.6-0.1,0.8-0.3
		c0.2-0.2,0.4-0.4,0.5-0.7c0.1-0.2,0.2-0.5,0.3-0.7c0,0,0-0.1,0.1-0.1c0,0,0,0,0-0.1c0.4-0.9,0.8-1.7,1.3-2.6c0-0.1,0.1-0.2,0.1-0.3
		c0.1-0.2,0.2-0.4,0.2-0.6c0.1-0.3,0.1-0.6,0.1-0.9c0-0.5,0-0.9,0.1-1.4c0-0.1,0-0.1,0-0.2c0-0.2,0.1-0.3,0.3-0.3c0.1,0,0.1,0,0.2,0
		c0.2,0.1,0.3,0.2,0.4,0.3C11.9,4.5,12,4.7,12,4.8c0.2,0.4,0.3,0.8,0.4,1.2c0.1,0.4,0.1,0.9,0.1,1.3c0,0.4,0,0.9,0,1.3
		c0,0.5-0.1,1-0.2,1.5c0,0.1,0,0.2-0.1,0.3c0,0.2,0,0.2,0.2,0.2h4.2c0.1,0,0.2,0,0.3,0c0.2,0.1,0.3,0.2,0.4,0.4
		c0.1,0.2,0.1,0.4,0.1,0.6c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.1-0.1,0.2-0.2,0.3C16.8,12.7,16.8,12.8,16.8,12.9z"
        />
        <path
          fill="none"
          stroke="currentColor"
          d="M20,3.7c0,0-2.1,1.4,0.1,3.2c2.1,1.7,2.1,4.3,2.1,4.3s0.5-2.5-0.7-4C20.6,6.1,19.3,5.3,20,3.7z"
        />
      </g>
    </SvgIcon>
  );
}

export default ThumbsUpOutlineIcon;
