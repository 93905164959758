import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import React, { useEffect } from 'react';
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

import Placeholder from '@/config/placeholder.config';
import type Brand from '@/models/Brand';
import type Venue from '@/models/Venue';
import { config } from '@/settings';
import { AppStore, SettingsStore, UserStore } from '@/stores';
import PlaceholderDark from '@/config/placeholder-dark.config';

export const getProfileImage = (image) => {
  if (image) {
    if (image.indexOf('http') === -1) {
      return `https://${image}`;
    }
    return image;
  }
  return null;
};

export const updateSelectedProfile = (profile: Venue | Brand, type: string) => {
  localStorage.setItem('selectedProfile', JSON.stringify(profile));
  localStorage.setItem('selectedProfileType', type);
  AppStore.update((s) => {
    s.selectedProfile = profile;
    s.selectedProfileType = type;
  });
};

function ProfileSelect(props) {
  const navigate = useNavigate();
  const darkMode = SettingsStore.useState((s) => s.darkMode);
  const currentUser = UserStore.useState((s) => s.user);
  const selectedProfile = AppStore.useState((s) => s.selectedProfile);
  const selectedProfileType = AppStore.useState((s) => s.selectedProfileType);
  const venues = AppStore.useState((s) => s.profiles.venues);
  const brands = AppStore.useState((s) => s.profiles.brands);
  const loading = AppStore.useState((s) => s.loading);

  useEffect(() => {
    if (selectedProfile) {
      if (selectedProfileType !== 'venue' && ['/inventory', '/fulfillments', '/inventory/editor'].indexOf(window.location.pathname) !== -1) {
        navigate('/');
      }
      if (selectedProfileType !== 'cigar_brand' && ['/cigars', '/cigars/editor'].indexOf(window.location.pathname) !== -1) {
        navigate('/');
      }
    }
  }, [selectedProfile]);

  useEffect(() => {
    if (!selectedProfile) {
      // TODO Store their last selection in local storage and re-init with that profile selected by default
      console.log('Setting active profile:');
      const profiles = venues.length > 0 || brands.length > 0;
      if (!loading && !profiles && currentUser.account_type === 'business') {
        window.location.href = `${config.appEndPoint}/claim`;
      } else {
        // FIXME If the user went through the claim process first, they likely won't have added their businesses details yet.
        //  We should add an error alert to their avatar drop down menu that lets them complete the business details before
        //  they can do certain things like list inventory or sell in our marketplace. Rather than force redirecting, it will
        //  just be a link they can follow to go to /onboarding - we would need to know so should it leave the account_type
        //  as basic until they fill out the onboarding form?
        // props.history.push('/onboarding');
      }
    }
  }, [venues, brands]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div
        style={{ flex: 1, maxHeight: 'calc(100% - 50px)', overflowY: 'scroll' }}
      >
        <div className="list-section">Your Venues (Shop, Lounge, etc)</div>
        <List>
          {venues.map((venue) => (
            <ListItem
              key={`venue-dropdown-${venue.id}`}
              style={{ cursor: 'pointer' }}
              onClick={() => props.onSelected(venue, 'venue')}
            >
              <ListItemAvatar>
                <Avatar
                  src={
                    getProfileImage(venue.profile_image_url) ||
                    Placeholder.venue
                  }
                >
                  <img
                    alt={selectedProfile ? selectedProfile.name : ''}
                    src={darkMode ? PlaceholderDark.venue : Placeholder.venue}
                    style={{ height: 40, width: 40 }}
                  />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={venue.name}
                secondary={venue.location?.formatted_address || venue.website}
              />
            </ListItem>
          ))}
        </List>
        <div className="list-section">
          Your Brands (Cigar brands, Blogs, Podcasts, etc)
        </div>
        <List>
          {brands.map((brand) => (
            <ListItem
              key={`brand-dropdown-${brand.id}`}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                console.debug('Selected brand:');
                console.debug(brand);
                if (brand.cigar_brand) {
                  props.onSelected(brand, 'cigar_brand');
                } else {
                  props.onSelected(brand, 'brand');
                }
              }}
            >
              <ListItemAvatar>
                <Avatar
                  src={
                    getProfileImage(brand.logo_image_url) || Placeholder.venue
                  }
                >
                  <img
                    alt={selectedProfile ? selectedProfile.name : ''}
                    src={darkMode ? PlaceholderDark.venue : Placeholder.venue}
                    style={{ height: 40, width: 40 }}
                  />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={brand.name} secondary={brand.website} />
            </ListItem>
          ))}
        </List>
      </div>
      <div style={{ borderTop: '1px solid #dfdfdf' }}>
        <Button
          color="light"
          onClick={() =>
            window.open(
              `${config.appEndpoint}/businesses/claim`,
              '_blank',
              'noopener noreferrer'
            )
          }
          style={{ width: '100%', padding: 12 }}
        >
          Claim New Business
        </Button>
      </div>
    </div>
  );
}

export default ProfileSelect;
