import Hashids from 'hashids';
import React from 'react';
import { Button, Card } from 'reactstrap';

import { getProfileImage } from '@/components/ProfileSelect';
import PromoteDialog from '@/components/PromoteDialog';
import ShareOptionsModal from '@/components/ShareOptionsModal';
import ShareItem from '@/models/ShareItem';
import { config } from '@/settings';
import { dateFromIsoTimestamp } from '@/utils/formatting';

import Icon from '../Icon';

const hashids = new Hashids('', 12);

function EventCard({ event, preview }) {
  const [showShareOptions, setShowShareOptions] = React.useState(false);

  const renderShareOptionsSheet = () => (
    <ShareOptionsModal
      url={`${config.appEndPoint}/events/${hashids.encode(event.id)}`}
      open={showShareOptions}
      onClose={() => setShowShareOptions(false)}
      data={
        new ShareItem({
          title: event.name,
          text: event.description, // FIXME Something more pertinent to our app instead? Like "View event details on Boxpressd"
          path: 'events',
          route: `/events/${hashids.encode(event.id)}`,
          image:
            getProfileImage(event.host?.profile_image_url) ||
            getProfileImage(event.host?.logo_image_url),
          promote_endpoint: '',
          item: event,
        })
      }
    />
  );

  return (
    <Card
      style={{
        marginBottom: 20,
        boxShadow: '0 2px 12px 1px hsla(0,0%,67.8%,.2)',
      }}
    >
      {event.image_url && (
        <div
          style={{
            background: `url('${event.image_url}')`,
            height: 150,
            backgroundSize: 'cover',
          }}
        />
      )}
      <div style={{ padding: 20 }}>
        <div style={{ display: 'flex', marginBottom: 16 }}>
          <div
            style={{
              borderRadius: 8,
              overflow: 'hidden',
              backgroundColor: '#efefef',
              height: 56,
              width: 56,
              boxShadow: '0 2px 12px 1px hsla(0,0%,67.8%,.2)',
              marginRight: 10,
            }}
          >
            <div
              style={{
                backgroundColor: '#f3425f',
                height: 16,
                color: '#ffffff',
                textAlign: 'center',
                lineHeight: '16px',
              }}
            >
              {dateFromIsoTimestamp(event.start_timestamp)
                .toLocaleString('en-us', { month: 'short' })
                .toUpperCase()}
            </div>
            <div
              style={{
                justifyContent: 'center',
                fontWeight: 600,
                fontSize: 24,
                width: '100%',
                margin: '-6px 14px',
              }}
            >
              {dateFromIsoTimestamp(event.start_timestamp).getDate()}
            </div>
          </div>
          <div style={{ flex: 1 }}>
            <h4 style={{ marginTop: 6 }}>{event.name}</h4>
          </div>
        </div>
        {/* <div>{event.venue.name}</div> */}
        {event.is_online && (
          <div>
            <Icon name="link" />
            {event.url || 'Unknown'}
          </div>
        )}
        {!event.is_online && (
          <div>
            <Icon name="map-pin" />
            {event.venue &&
              event.venue.location &&
              event.venue.location.formatted_address}
            {event.formatted_address ||
              (event.location && event.location.location.formatted_address)}
            {!event.venue &&
              !event.formatted_address &&
              !event.location &&
              'Unknown'}
          </div>
        )}
      </div>
      {!preview && (
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', padding: 10, float: 'right' }}>
            <Button
              color="text"
              style={{ marginRight: 10 }}
              onClick={() => {
                // FIXME Need a way to know if they already shared it so this can't happen more than once - they can
                //  effectively keep boosting their post if we leave it as is unless the server responds with "already posted".
                // TODO I think that's the best way to handle this - check server-side for a feed item matching
                //  the details of this post type and only add if not already added. This ensures that it doesn't
                //  get added back to the top. Should definitely respond with 304 or similar so we can say something like
                //  "this post already exists, would you like to promote it to the top of the feed?" to upsell them
                setShowShareOptions(true);
              }}
            >
              {'Share'}
            </Button>
            <Button
              color="brand"
              onClick={() =>
                PromoteDialog.show({ post: event, postType: 'event' })
              }
            >
              {'Promote'}
            </Button>
          </div>
        </div>
      )}
      {renderShareOptionsSheet()}
    </Card>
  );
}

export default EventCard;
