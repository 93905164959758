// import '@/resources/scss/comments/style.scss';

import { Avatar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Hashids from 'hashids';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import RichCard from '@/components/RichCard';
import { UserStore } from '@/stores';
import { renderTimestamp } from '@/utils/formatting';
import { Resize } from '@/utils/imageUtils';
import { language, messages } from '@/utils/localeUtils';

import { threadComments } from './helpers';
import Icon from '@/components/Icon';
import ThumbsUpIcon from '@/components/Icon/custom/ThumbUpIcon';
import ThumbsUpOutlineIcon from '@/components/Icon/custom/ThumbUpOutlineIcon';

const hashids = new Hashids('', 12);

const useStyles = makeStyles(() => ({
  small: {
    width: 32,
    height: 32,
    // display: 'inline',
    verticalAlign: 'top',
    margin: 8,
    marginTop: 14,
    marginLeft: 80,
  },
  medium: {
    width: 40,
    height: 40,
    // display: 'inline',
    verticalAlign: 'top',
    margin: 10,
  },
  large: {
    // width: isMobile ? 48 : 32,
    // height: isMobile ? 48 : 32,
    // display: 'inline',
    verticalAlign: 'top',
    margin: 16,
  },
}));

const CommentList = memo((props) => {
  const user = UserStore.useState((s) => s.user);
  const classes = useStyles();
  const threadedComments = threadComments(props.comments);
  console.debug('Threaded comments:');
  console.debug(threadedComments);
  const [liked, setLiked] = React.useState(props.liked);
  const [likeCount, setLikeCount] = React.useState(props.likeCount || 0);

  const hasMore = true;
  const virtuoso = React.useRef(null);

  const submitReply = (id) => () => {
    // if (this.state.commentRepliesText[id]) {
    //   // TODO Post - should we check here whether it is being edited vs first submission?
    // } else {
    //   // TODO Display error
    // }
  };

  const editReply = (comment) => () => {
    // TODO Follow the handling
  };

  const deleteReply = (id) => () => {
    // TODO Prompt user to confirm
    // TODO POST to delete the comment with the given ID (should we add another check to make sure the user is authorized?)
  };

  const showReplyForm = (id) => () => {};

  const renderUserActions = (comment) => {
    if (user && comment.user_id === user.id) {
      return (
        <div>
          <button
            onClick={editReply(comment)}
            style={{
              border: 'none',
              background: 'transparent',
              fontSize: 12,
              color: '#69798f',
              marginRight: 8,
              padding: 0,
            }}
          >
            Edit
          </button>
          <button
            onClick={deleteReply(comment.id)}
            style={{
              border: 'none',
              background: 'transparent',
              fontSize: 12,
              color: '#69798f',
              padding: 0,
            }}
          >
            Delete
          </button>
        </div>
      );
    }
    return (
      <>
        {/* <span */}
        {/*  style={{ */}
        {/*    margin: '0px 8px 0 16px', */}
        {/*    fontWeight: 700, */}
        {/*  }} */}
        {/* > */}
        {/*  {messages[language]?.like || 'Like'} */}
        {/* </span> */}
        <span
          style={{
            margin: '0px 16px 0 8px',
            fontWeight: 700,
            cursor: 'pointer',
          }}
          onClick={() => props.onReply(comment)}
        >
          {messages[language]?.reply || 'Reply'}
        </span>
      </>
    );
  };

  const getProfileImage = (message) => {
    console.debug(`Message: ${message}`);
    if (message.venue && message.venue.profile_image_url) {
      return Resize.thumbnail(message.venue.profile_image_url);
    }
    if (message.brand && message.brand.profile_image_url) {
      return Resize.thumbnail(message.brand.profile_image_url);
    }
    if (message.brand && message.brand.logo_image_url) {
      return Resize.thumbnail(message.brand.logo_image_url);
    }
    return (
      message.user.image_url &&
      message.user.image_url.replace(
        '/users/profile/',
        '/100x100/users/profile/'
      )
    );
  };

  const getProfileName = (message) => {
    if (message.venue) {
      return message.venue.name;
    }
    if (message.brand) {
      return message.brand.name;
    }
    return message.user.full_name;
  };

  const Comment = ({ message }) => {
    console.debug('Rendering message:');
    console.debug(message);
    if (message) {
      if (props.compact) {
        return (
          <div
            style={{
              // marginLeft: message.parent_id ? 40 : 0,
              marginTop: 30,
            }}
            itemProp="comment"
            itemScope
            itemType="https://schema.org/Comment"
          >
            {/* FIXME ^^^ Should only be a max indent allowed */}
            <div className="row" style={{ display: 'flex' }}>
              <Avatar
                src={getProfileImage(message)}
                className={!message.parent_id ? classes.medium : classes.small}
                onClick={() => {
                  // router.push(`/users/${hashids.encode(message.user.id)}`);
                }}
              >
                {getProfileName(message).charAt(0)}
              </Avatar>
              <div
                style={{
                  float: 'left',
                  margin: '-5px 20px',
                  flex: 1,
                }}
                itemProp="author"
                itemScope
                itemType="https://schema.org/Person"
              >
                <span
                  style={{ fontWeight: 600 }}
                  onClick={() => {
                    // router.push(`/users/${hashids.encode(message.user.id)}`);
                  }}
                  itemProp="name"
                >
                  {getProfileName(message)}
                  <span style={{ fontSize: 12, fontWeight: 400, marginLeft: 8 }}>
                    {renderTimestamp(message.comment_timestamp)}
                  </span>
                </span>
                <RichCard
                  compact={message.comment && message.comment.length > 100}
                >
                  {message.comment}
                </RichCard>
                <div>{renderUserActions(message)}</div>
                <div>
                  {/* { this.state.visibleReplyIds[message.id] && this.renderReplyForm(message) } */}
                </div>
              </div>
              <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 12 }}>
                <Icon
                  vendor="svg"
                  component={liked ? ThumbsUpIcon : ThumbsUpOutlineIcon}
                  onClick={() => {
                    if (!liked) {
                      setLikeCount(likeCount + 1);
                    } else {
                      setLikeCount(likeCount - 1);
                    }
                    setLiked(!liked);
                  }}
                  style={{ margin: 16 }}
                />
                <span style={{ marginTop: -12 }}>{likeCount || ''}</span>
              </span>
            </div>

            {message.children.map((childComment, index) => (
              <Comment key={index} message={childComment} />
            ))}
          </div>
        );
      }
      console.debug('Rendering desktop message:');
      console.debug(message);
      return (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Avatar
              src={getProfileImage(message)}
              className={!message.parent_id ? classes.large : classes.small}
            >
              {getProfileName(message).charAt(0)}
            </Avatar>
            <div
              className={`lounge-messenger-message ${
                !message.parent_id ? 'lounge-message-incoming' : ''
              }`}
              style={{ width: '100%' }}
            >
              <ul>
                <li>
                  <div style={{ fontWeight: 700, fontSize: 14 }}>
                    {getProfileName(message)}
                  </div>
                  <RichCard
                    compact={message.comment && message.comment.length > 100}
                  >
                    {message.comment}
                  </RichCard>
                </li>
              </ul>
              <small className="lounge-message-time">
                {renderTimestamp(message.comment_timestamp)}
                {renderUserActions(message)}
              </small>
              {/* FIXME Include actions to edit and delete if comment user === the active user */}
            </div>
          </div>
          {message.children.map((childComment, index) => (
            <Comment key={index} message={childComment} />
          ))}
        </>
      );
    }
    console.debug('No message, returning null');
    return null;
  };

  return (
    <div className="boxpressd-comments">
      {/* FIXME This wasn't always rendering the rows for some reason - figure out why to add it back, if necessary */}
      {/* TODO This needs to have a "Show More" option on desktop */}
      {threadedComments.map((comment, index) => (
        <Comment key={index} message={comment} />
      ))}
    </div>
  );
});

CommentList.propType = {
  comments: PropTypes.array.isRequired,
  compact: PropTypes.bool,
};

CommentList.defaultProps = {
  compact: false,
};

export default CommentList;
