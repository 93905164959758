import Fab from '@mui/material/Fab';
import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

import ImgDecode from '@/components/BarcodeScanModal/ImgDecode/ImgDecode';
import VideoDecode from '@/components/BarcodeScanModal/VideoDecode/VideoDecode';
import BottomSheet from '@/components/BottomSheet';
import Icon from '@/components/Icon';
import { isMobile } from 'mobile-device-detect';
import { AppStore } from '@/stores';

const BarcodeScanModal = ({ open, type = 'ean13' }) => {
  const closeModal = () => {
    AppStore.update((s) => {
      s.showInventoryScannerModal = false;
    });
  }

  const onResult = (result) => {
    // TODO Do something with the result - need to see if it can be
    //  cross-referenced with an existing product and ask for quantity or
    //  create a new product
    console.debug('Barcode Scan Result:');
    console.debug(result);
  }

  if (isMobile) {
    return (
      <BottomSheet open={open} onClose={closeModal}>
        <VideoDecode onResult={onResult} />
        <div
          style={{
            position: 'absolute',
            zIndex: 1002,
            height: '100%',
            width: '100%',
          }}
        >
          <Fab
            size="small"
            color="secondary"
            aria-label="close"
            style={{
              position: 'fixed',
              top: 20,
              left: 20,
              backgroundColor: 'rgba(0, 0, 0, 0.4)',
            }}
            onClick={closeModal}
          >
            <Icon name="x" style={{ color: 'white' }} />
          </Fab>
          <ImgDecode />
          {/* SEE https://stackoverflow.com/questions/14387690/how-can-i-show-only-corner-borders for corners only */}
          <div
            style={{
              position: 'absolute',
              zIndex: 1001,
              top: `calc(50% - ${type === 'qr' ? '45vw' : '10vw'})`,
              left: '5vw',
              width: '90vw',
              height: type === 'qr' ? '90vw' : '20vw',
              border: '4px white solid',
              borderRadius: '20px',
            }}
          />
        </div>
      </BottomSheet>
    );
  }
  return (
    <Modal isOpen={open} style={{ maxWidth: 600 }} fade>
      <div className="modal-header">
        <Button className="close" color="" onClick={closeModal}>
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody>
        {
          'Please use a mobile device to use this feature!'
        }
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={closeModal}>
          Close
        </Button>
        <Button color="primary">Submit</Button>
      </ModalFooter>
    </Modal>
  );
};

export default BarcodeScanModal;
