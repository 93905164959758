import './style.scss';

import { IonFab, IonFabButton, IonFooter, IonIcon } from '@ionic/react';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { createMuiTheme, ThemeProvider } from '@mui/material/styles';
import {
  add,
  albumsOutline,
  calendarClearOutline,
  createOutline,
  filmOutline,
} from 'ionicons/icons';
import { isIOS, isMobileOnly } from 'mobile-device-detect';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import ActionSheet from '@/components/ActionSheet';
import CreateAdModal from '@/components/CreateAdModal';
import CreateEventModal from '@/components/CreateEventModal';
import Icon from '@/components/Icon';
import { AppStore } from '@/stores';
import { adFormats } from '@/utils/adFormats';
import { language, messages } from '@/utils/localeUtils';
import { isStandalone } from '@/utils/useStandalone';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#d3a966',
      dark: '#d3a966',
    },
    secondary: {
      main: '#17191d',
      dark: '#17191d',
      contrastText: '#efefef',
    },
  },
});

const PageTabs = () => {
  const location = useLocation();
  const { pathname } = location;
  const selectedProfileType = AppStore.useState((s) => s.selectedProfileType);
  const navigate = useNavigate();
  const [showActionItems, setShowActionItems] = useState(false);
  const [value, setValue] = useState('home');

  useEffect(() => {
    setValue(pathname !== '/' ? pathname.split('/')[1] : 'home');
  }, [pathname]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (pathname === '/install') {
    return null;
  }

  return (
    <IonFooter
      style={{
        minHeight: 44,
        paddingBottom: isStandalone && isMobileOnly && isIOS ? 24 : 0,
      }}
    >
      <IonFab>
        <IonFabButton onClick={() => setShowActionItems(true)}>
          <IonIcon icon={add}></IonIcon>
        </IonFabButton>
      </IonFab>
      <ThemeProvider theme={theme}>
        <BottomNavigation showLabels value={value} onChange={handleChange}>
          <BottomNavigationAction
            label="Home"
            value="home"
            icon={<Icon name="user" />}
            onClick={() => navigate('/')}
          />
          <BottomNavigationAction
            label="Planner"
            value="planner"
            icon={<Icon name="clock" />}
            onClick={() => navigate('/planner')}
          />
          <BottomNavigationAction label="" value="blank" />
          {selectedProfileType === 'venue' && (
            <BottomNavigationAction
              label="Inventory"
              value="inventory"
              icon={<Icon name="package" />}
              onClick={() => navigate('/inventory')}
            />
          )}
          {selectedProfileType === 'cigar_brand' && (
            <BottomNavigationAction
              label="Cigars"
              value="cigars"
              icon={<Icon name="edit2" />}
              onClick={() => navigate('/cigars')}
            />
          )}
          {selectedProfileType === 'brand' && (
            <BottomNavigationAction
              label="Insights"
              value="insights"
              icon={<Icon name="pie-chart" />}
              onClick={() => navigate('/insights')}
            />
          )}
          <BottomNavigationAction
            label="Tools"
            value="menu"
            icon={<Icon name="menu" />}
            onClick={() => navigate('/tools')}
          />
        </BottomNavigation>
      </ThemeProvider>
      <ActionSheet
        id="create-action-sheet"
        title={messages[language]?.create_content || 'Create New Content'}
        open={showActionItems}
        toggle={() => setShowActionItems(false)}
        items={[
          {
            content: messages[language]?.create_post || adFormats[0],
            icon: createOutline,
            onClick: () => {
              setShowActionItems(false);
              CreateAdModal.show({
                tab: adFormats[0],
                onClose: () => CreateAdModal.close(),
              });
            },
          },
          {
            content: messages[language]?.create_carousel || adFormats[1],
            icon: albumsOutline,
            onClick: () => {
              setShowActionItems(false);
              CreateAdModal.show({
                tab: adFormats[1],
                onClose: () => CreateAdModal.close(),
              });
            },
          },
          {
            content: messages[language]?.create_nubs || adFormats[2],
            icon: filmOutline,
            onClick: () => {
              setShowActionItems(false);
              CreateAdModal.show({
                tab: adFormats[2],
                onClose: () => CreateAdModal.close(),
              });
            },
          },
          {
            content: messages[language]?.create_event || 'Event',
            icon: calendarClearOutline,
            onClick: () => {
              setShowActionItems(false);
              CreateEventModal.show({
                onClose: () => CreateEventModal.close(),
              });
            },
          },
        ]}
      />
    </IonFooter>
  );
};

export default PageTabs;
