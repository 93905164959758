import './style.scss';

import { Badge } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import clsx from 'clsx';
import { isMobile } from 'mobile-device-detect';
import React, { useEffect } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { NavLink } from 'react-router-dom';

import Dropdown from '@/components/Dropdown';
// import Icon from '@/components/Icon';
import Icon from '@/components/Icon';
import ProfileSelect, {
  getProfileImage,
  updateSelectedProfile,
} from '@/components/ProfileSelect';
import firebaseApp from '@/config/firebase.config';
import Placeholder from '@/config/placeholder.config';
import PlaceholderDark from '@/config/placeholder-dark.config';
import type Brand from '@/models/Brand';
import type Venue from '@/models/Venue';
import settings, { config } from '@/settings';
import { AppStore, SettingsStore } from '@/stores';
import { Resize } from '@/utils/imageUtils';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    backgroundColor: 'transparent',
    width: drawerWidth,
    // transition: theme.transitions.create('width', {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
  },
  drawerClose: {
    backgroundColor: 'transparent',
    // transition: theme.transitions.create('width', {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
    overflowX: 'hidden',
    // width: theme.spacing(7) + 1,
    // [theme.breakpoints.up('sm')]: {
    //   width: theme.spacing(9) + 1,
    // },
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
  },
}));

// export const ads = [{
//   image_url: Resize.size('https://www.cigarboxmarketing.com/wp-content/uploads/2023/01/Insights-Informational-Brochure_Page_1-1024x686.jpg'),
//   title: 'Extract instant insights',
//   message: 'Insights uses Natural Language Processing to analyze reviews automatically',
//   url: 'https://www.cigarboxmarketing.com/',
// }, {
//   image_url: Resize.size('https://cindtoro.com/wp-content/uploads/2021/10/Traditional-Vs-Online-Advertising.jpg.webp'),
//   title: 'Improve Online Advertising',
//   message: 'What’s your current marketing plan? Who is tracking your advertising ROI? What is your online advertising presence?',
//   url: 'https://cindtoro.com/',
// }];

function PageDrawer(props) {
  const classes = useStyles();
  const darkMode = SettingsStore.useState((s) => s.darkMode);
  const darkNavbar = SettingsStore.useState((s) => s.darkNavbar);
  const [open, setOpen] = React.useState(true);
  const [adData, setAdData] = React.useState({});
  const [locationDropDownOpen, setLocationDropDownOpen] = React.useState(false);
  const selectedProfile = AppStore.useState((s) => s.selectedProfile);
  const selectedProfileType = AppStore.useState((s) => s.selectedProfileType);
  // const showNavDrawer = AppStore.useState((s) => s.showNavDrawer)

  const mobileToggleStyle = () => {
    return {
      color: isMobile || darkMode ? '#c7d1db' : 'initial',
    };
  };

  useEffect(() => {
    axios
      .post('https://ads.boxpressd.io/decode', {
        url: 'https://trk.aso1.net/link?z=113829',
      })
      .then((res) => {
        console.log('AdServer Data');
        console.log(res.data);
        setAdData(res.data); // Our server parses this to an object including the redirect URL
        // setLoadingAd(false);
      })
      .catch((err) => {
        console.log(err);
        // setLoadingAd(false);
      });
  }, []);

  const logOut = () => {
    console.debug('Logging out...');
    const { updateAuth, signOutUser } = props;
    updateAuth({
      token: '',
    });
    signOutUser(() => {
      firebaseApp
        .auth()
        .signOut()
        .then(() => {
          window.location.replace(
            `${config.authEndPoint}?action=sign_out&path=${
              window.location.href.split('?')[0]
            }`
          );
        })
        .catch((err) => {
          window.location.replace(
            `${config.authEndPoint}?action=sign_out&path=${
              window.location.href.split('?')[0]
            }`
          );
        });
    });
  };

  const toggleSidebar = (isOpen) => {
    // AppStore.update((s) => {
    //   s.showNavDrawer = false
    //   // if (typeof open !== 'undefined') {
    //   //   s.showNavDrawer = isOpen;
    //   // } else {
    //   //   s.showNavDrawer = !s.showNavDrawer;
    //   // }
    // })
  };

  const toggleDropDownOpen = () => {
    setLocationDropDownOpen(!locationDropDownOpen);
  };

  const toggleMenuOpen = () => {
    setOpen(!open);
  };

  const handleSelectChange = (selection: Venue | Brand, type: string) => {
    updateSelectedProfile(selection, type);
    setLocationDropDownOpen(false);
  };

  const renderSubmenus = (nav, returnObject = false) => {
    let thereIsActive = false;

    const result = Object.keys(nav).map((url) => {
      const data = nav[url];
      const isActive = window.location.hash === `#${url}`;
      let isOpened = false;

      if (isActive) {
        thereIsActive = true;
      }

      let sub = '';
      if (data.sub) {
        const subData = renderSubmenus(data.sub, true);

        sub = (
          <List style={{ position: 'relative', zIndex: 1 }}>
            {subData.content}
          </List>
        );

        if (subData.thereIsActive) {
          isOpened = true;
          thereIsActive = true;
        }
      }

      // FIXME Give this a max height to avoid the ads at the bottom - include a "show more" button to expand
      return (
        <React.Fragment key={`${url}-${data.name}`}>
          {data.label ? (
            <ListItem button key={data.label}>
              <ListItemText primary={data.label} />
            </ListItem>
          ) : (
            ''
          )}
          <NavLink
            to={data.sub ? '#' : url}
            className={data.sub ? 'yay-sub-toggle' : ''}
          >
            <ListItem button key={data.name}>
              {data.name === 'Inbox' && (
                <Badge
                  badgeContent={0}
                  color="error"
                  variant="dot"
                  overlap="circular"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <ListItemIcon>
                    <Icon name={data.icon} />
                  </ListItemIcon>
                </Badge>
              )}
              {data.name !== 'Inbox' && (
                <ListItemIcon>
                  <Icon name={data.icon} />
                </ListItemIcon>
              )}
              <ListItemText primary={data.name} />
            </ListItem>
          </NavLink>
        </React.Fragment>
      );
    });

    if (returnObject) {
      return {
        content: result,
        thereIsActive,
      };
    }

    return result;
  };

  const renderProfileSelect = () => {
    return (
      <div style={{ marginTop: 52 }}>
        {open && (
          <div style={{ margin: 20, marginTop: 0, position: 'relative' }}>
            <div>
              <div
                style={{
                  marginTop: 6,
                  marginBottom: -12,
                  color: !isMobile ? '#d3a966' : 'black',
                  fontSize: 14,
                }}
              >
                Your Claimed Profiles
              </div>
            </div>
          </div>
        )}
        {open && (
          <Dropdown
            showTriangle
            dropdownOpen={locationDropDownOpen}
            toggle={toggleDropDownOpen}
            className="div"
            style={{
              width: '100%',
            }}
          >
            <Dropdown.Toggle
              tag="button"
              className="profile-select-dropdown"
              style={{ display: 'flex', width: '100%', alignItems: 'center' }}
            >
              <Avatar
                src={
                  (selectedProfile &&
                    (getProfileImage(selectedProfile.profile_image_url) ||
                      getProfileImage(selectedProfile.logo_image_url))) ||
                  (darkMode ? PlaceholderDark.venue : Placeholder.venue)
                }
                style={{
                  height: 20,
                  width: 20,
                  // display: 'inline-block',
                  marginRight: 5,
                }}
              >
                <img
                  alt={selectedProfile ? selectedProfile.name : ''}
                  src={darkMode ? PlaceholderDark.venue : Placeholder.venue}
                  style={{ height: 20, width: 20 }}
                />
              </Avatar>
              <span
                className="text"
                style={{
                  lineHeight: '20px',
                  flex: 1,
                  height: 20,
                  overflow: 'hidden',
                }}
              >
                {selectedProfile && selectedProfile.name}
              </span>
              <span className="icon">
                <Icon name="chevron-down" />
              </span>
            </Dropdown.Toggle>
            {/* FIXME I think this should be a collapse menu inside of the sidebar instead of a popover */}
            <Dropdown.Menu
              tag="div"
              className="profile-select-list"
              style={{
                width: 300,
                zIndex: 1000,
                overflowY: 'scroll',
                maxHeight: 800,
              }}
            >
              <ProfileSelect onSelected={handleSelectChange} />
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
    );
  };

  const renderMenu = () => {
    if (props.app?.loading) {
      return (
        <div style={{ paddingTop: 30 }}>
          <SkeletonTheme color="#24262a" highlightColor="#444">
            <p>
              <Skeleton
                height={60}
                width="90%"
                style={{ margin: '5px 10px' }}
              />
            </p>
            <p>
              <Skeleton
                height={40}
                width="90%"
                style={{ margin: '2px 10px' }}
              />
            </p>
            <p>
              <Skeleton
                height={40}
                width="90%"
                style={{ margin: '2px 10px' }}
              />
            </p>
            <p>
              <Skeleton
                height={40}
                width="90%"
                style={{ margin: '2px 10px' }}
              />
            </p>
            <p>
              <Skeleton
                height={40}
                width="90%"
                style={{ margin: '2px 10px' }}
              />
            </p>
          </SkeletonTheme>
        </div>
      );
    }
    return (
      <div>
        <div className="yay-wrap-menu" style={{ marginTop: 10 }}>
          <div className="yaybar-wrap">
            {!open && (
              <Avatar
                src={
                  (selectedProfile &&
                    (getProfileImage(selectedProfile.profile_image_url) ||
                      getProfileImage(selectedProfile.logo_image_url))) ||
                  (darkMode ? PlaceholderDark.venue : Placeholder.venue)
                }
                style={{
                  height: 32,
                  width: 32,
                  display: 'inline-block',
                  marginRight: 5,
                  marginLeft: 20,
                  marginTop: 16,
                }}
              >
                <img
                  alt={selectedProfile ? selectedProfile.name : ''}
                  src={darkMode ? PlaceholderDark.venue : Placeholder.venue}
                  style={{ height: 32, width: 32 }}
                />
              </Avatar>
            )}
            {renderSubmenus(
              settings[`${selectedProfileType || 'main'}_navigation_sidebar`]
            )}
          </div>
        </div>
      </div>
    );
  };

  // const renderDrawerMenu = () => (
  //   <>
  //     <List>
  //       <div style={{ height: 48 }}>
  //         {settings.nav_logo_path ? (
  //           <Link
  //             to={settings.nav_logo_url}
  //             className="rui-navbar-logo"
  //             style={{ flex: 1, marginTop: 4, marginLeft: 20 }}
  //           >
  //             <img src={settings.nav_logo_path} alt="" width="125" />
  //           </Link>
  //         ) : (
  //           ''
  //         )}
  //         <div style={{ float: 'right', padding: '14px 30px' }}>
  //           {/* <span className="btn btn-custom-round"> */}
  //           {/*  <Icon name="bell" className="mr-0" /> */}
  //           {/*  <span className="badge badge-circle badge-brand">3</span> */}
  //           {/* </span> */}
  //           <img
  //             src={
  //               props.auth && props.auth.user && props.auth.user.image_url
  //                 ? props.auth.user.image_url
  //                 : darkMode
  //                 ? PlaceholderDark.avatar
  //                 : Placeholder.avatar
  //             }
  //             alt=""
  //             style={{
  //               height: 30,
  //               width: 30,
  //               borderRadius: '50%',
  //               marginLeft: 30,
  //             }}
  //             onError={({ currentTarget }) => {
  //               currentTarget.onerror = null; // prevents looping
  //               currentTarget.src = darkMode
  //                 ? PlaceholderDark.avatar
  //                 : Placeholder.avatar;
  //             }}
  //           />
  //         </div>
  //       </div>
  //       <hr />
  //       <ListItem
  //         onClick={() => {
  //           toggleSidebar();
  //           window.open(`${config.appEndPoint}/profile`);
  //         }}
  //       >
  //         <ListItemIcon>{/* <Icon name="user" /> */}</ListItemIcon>
  //         <ListItemText primary="View Your Profile" />
  //         <ListItemSecondaryAction>
  //           <Icon name="external-link" />
  //         </ListItemSecondaryAction>
  //       </ListItem>
  //       <hr />
  //       <ListItem
  //         onClick={() => {
  //           toggleSidebar();
  //           props.history.push('/profile/settings');
  //         }}
  //       >
  //         <ListItemIcon>{/* <Icon name="users" /> */}</ListItemIcon>
  //         <ListItemText primary="Users & Social" />
  //       </ListItem>
  //       <ListItem
  //         onClick={() => {
  //           toggleSidebar();
  //           props.history.push('/settings');
  //         }}
  //       >
  //         <ListItemIcon>
  //           <Icon name="settings" />
  //         </ListItemIcon>
  //         <ListItemText primary="Account & Billing" />
  //       </ListItem>
  //       <ListItem
  //         onClick={() => {
  //           toggleSidebar();
  //           window.open(
  //             'https://boxpressd.freshdesk.com/support/solutions/150000047760'
  //           );
  //         }}
  //       >
  //         <ListItemIcon>{/* <Icon name="help-circle" /> */}</ListItemIcon>
  //         <ListItemText primary="Help & Support" />
  //         <ListItemSecondaryAction>
  //           <Icon name="external-link" />
  //         </ListItemSecondaryAction>
  //       </ListItem>
  //       <hr />
  //       <ListItem
  //         onClick={(e) => {
  //           e.preventDefault();
  //           toggleSidebar();
  //           // window.FreshworksWidget('open', 'ticketForm')
  //         }}
  //       >
  //         <ListItemIcon>
  //           <Icon name="edit" />
  //         </ListItemIcon>
  //         <ListItemText
  //           primary="Leave Feedback"
  //           secondary="Help us improve your experience with Boxpressd"
  //         />
  //       </ListItem>
  //       <hr />
  //     </List>
  //     <List
  //       subheader={
  //         <ListSubheader component="div" id="nested-list-subheader">
  //           Display Options
  //         </ListSubheader>
  //       }
  //     >
  //       <ListItem>
  //         <ListItemIcon>
  //           {/* <Icon name="moon" style={isMobile ? mobileToggleStyle() : {}} /> */}
  //         </ListItemIcon>
  //         <ListItemText id="switch-list-label-dark-mode" primary="Dark Mode" />
  //         <Switch
  //           edge="end"
  //           checked={darkMode}
  //           onChange={() => {
  //             SettingsStore.update((s) => {
  //               ls('dark_mode', !darkMode);
  //               s.darkMode = !darkMode;
  //             });
  //           }}
  //           inputProps={{
  //             'aria-labelledby': 'switch-list-label-dark-mode',
  //           }}
  //         />
  //       </ListItem>
  //       <hr />
  //       <ListItem onClick={logOut}>
  //         <ListItemIcon>
  //           <Icon name="log-out" />
  //         </ListItemIcon>
  //         <ListItemText primary="Log Out" />
  //       </ListItem>
  //     </List>
  //   </>
  // );

  if (isMobile) {
    // FIXME I may add this back as a menu from "Tools"
    return null;
    // return (
    //   <IonMenu
    //     menuId="main-menu"
    //     type={isIOS ? 'push' : 'overlay'}
    //     contentId="main-content"
    //     side="end"
    //   >
    //     {renderDrawerMenu()}
    //   </IonMenu>
    // );
  }
  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      {renderProfileSelect()}
      {renderMenu()}
      {adData.image_url && (
        <div
          className="ad-banner"
          onClick={() => {
            window.open(adData.url, '_blank', 'noopener noreferrer');
          }}
        >
          <img
            src={Resize.size(adData.image_url, {
              height: 150,
              width: 200,
              cropType: 'crop',
            })}
            alt={adData.title}
          />
          <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <p className="heading">{adData.title}</p>
            <p className="text">{adData.message}</p>
            <p className="sponsored">Sponsored</p>
          </div>
        </div>
      )}
    </Drawer>
  );
}

export default PageDrawer;
