import { Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { useActionSheet } from '@/components/ActionSheet';
import Icon from '@/components/Icon';
import { config } from '@/settings';

function fallbackCopyTextToClipboard(text) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    console.log(`Fallback: Copying text command was ${msg}`);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    () => {
      console.log('Async: Copying to clipboard was successful!');
    },
    (err) => {
      console.error('Async: Could not copy text: ', err);
    }
  );
}

/**
 * @deprecated Use `ShareOptionsModal` instead.
 * @constructor
 */
const ShareIntent = () => {
  const { showActionSheet } = useActionSheet();
  const [data, setData] = useState({});
  const [showSnackBar, setShowSnackBar] = useState(false);

  const createLink = (service) =>
    new Promise((resolve, reject) => {
      console.debug('Creating link...');
      if (data.url) {
        resolve(data.url);
      }

      // if (isNative) {
      // const analytics = {
      //   channel: service,
      //   // TODO Anything else?
      // };
      // const properties = {
      //   $desktop_url: `${config.appUrl}${data.route}`,
      //   // $android_url: 'http://www.example.com/android',
      //   // $ios_url: 'http://www.example.com/ios',
      //   // $ipad_url: 'http://www.example.com/ipad',
      // };
      // // FIXME Getting "BranchDeepLinks does not have web implementation" for some reason...
      // BranchDeepLinks.generateShortUrl({ analytics, properties }).then((res) => {
      //   resolve(res.url);
      // }).catch((err) => {
      //   alert(JSON.stringify(err.message));
      // });
      // BranchDeepLinks.showShareSheet({ analytics, properties, shareText: data.text }).then((res) => {
      //   // resolve(res.url);
      //   alert('Ok');
      // }).catch((err) => {
      //   alert(JSON.stringify(err.message));
      // });
      resolve(`${config.appUrl}${data.route}`);
      // } else {
      const linkData = {
        // campaign: 'content 123',
        // feature: 'dashboard',
        // stage: 'new user',
        // alias: '',
        // tags: ['tag1', 'tag2', 'tag3'], // FIXME What are these used for? Marketing campaigns?
        channel: service,
        data: {
          path: data.path,
          route: data.route,
          id: data.id,
          $og_title: data.title,
          $og_description: data.text,
          $og_image_url: data.image,
          $og_video: data.video,
        },
      };
      console.debug(linkData);
      if (typeof window !== 'undefined') {
        window.branch.link(linkData, (err, link) => {
          console.debug('Generating link');
          if (!err) {
            console.log('Created Deep Link:');
            console.log(link);
            resolve(link);
          } else {
            console.log(err);
            reject(err);
          }
        });
      }
      // }
    });

  useEffect(() => {
    (async () => {
      console.debug('Data changed:');
      console.debug(data); // FIXME Seems like this might be undefined initially
      let imageData;
      if (data.image) {
        try {
          imageData = await fetch(data.image).then((r) => r.blob());
        } catch (e) {
          console.error(e);
        }
      }
      if (data && (data.route || data.url)) {
        console.debug('Sharing data...');

        if (window.navigator.share) {
          console.debug('Using window.navigator.share');
          // FIXME This stopped showing the default share options on some views (like the profile)
          createLink('navigator').then((link) => {
            console.log('Found navigator.share...');
            window.navigator
              .share({
                title: data.title,
                text: data.text,
                files: imageData
                  ? [
                      new File(
                        [imageData],
                        `${data.title}.${imageData.type.split('/')[1]}`,
                        {
                          type: imageData.type,
                        }
                      ),
                    ]
                  : undefined,
                url: link,
              })
              .then(() => console.log('Successful share'))
              .catch((error) => console.error('Error sharing', error));
          });
        } else {
          console.log('Showing in-browser share options...');
          // FIXME Make this more like Instagram or Threads - I think this might be better for all platforms,
          //  window.navigator.share would only be one of the options (Share To) but this allows us to start including
          //  support for our Virtual Lounge and ability to share to Nubs (Reels/Stories)
          showActionSheet({
            id: 'share-items',
            items: shareItems,
          });
        }
      }
    })();
  }, [data]);

  const shareTo = (service) => {
    createLink(service).then((link) => {
      let shareUrl = '';
      if (service === 'email') {
        if (typeof window !== 'undefined') {
          window.open(
            `mailto:?body=${
              data.text
                ? encodeURIComponent(data.text)
                : `Check%20out%20${encodeURIComponent(
                    data.title
                  )}%20on%20Boxpressd`
            }.%20Learn%20more%20at%20${link}&subject=Check%20this%20out%20on%20Boxpressd`
          );
        }
        return false;
      }
      if (service === 'facebook') {
        shareUrl = `https://www.facebook.com/sharer.php?u=${link}`;
      } else if (service === 'twitter') {
        shareUrl = `https://twitter.com/share?url=${link}&text=${data.title}`;
      } else if (service === 'reddit') {
        shareUrl = `https://reddit.com/submit?url=${link}&title=${data.title}`;
      } else if (service === 'pinterest') {
        shareUrl = `https://pinterest.com/pin/create/button/?url=${link}&media=${data.image}&description=${data.title}`;
      } else if (service === 'copy') {
        console.debug(`Copied link: ${link}`);
        copyTextToClipboard(link);
        setShowSnackBar(true);
        return false;
      } else if (service === 'embed') {
        if (typeof window !== 'undefined') {
          window.open(
            `${config.embedEndPoint}?query=${
              data.url ||
              (data.route && `${config.appUrl}${data.route}`) ||
              window.location.href
            }`,
            '_blank'
          );
        }
        return false;
      }

      if (typeof window !== 'undefined') {
        const shareWindow = window.open(
          shareUrl,
          'share-popup',
          'height=350,width=600'
        );
        if (shareWindow.focus) {
          shareWindow.focus();
        }
      }
      return false;
    });
  };

  const shareItems = [
    {
      content: 'Share with Friends',
      onClick: () => {
        // FIXME Use library
        if (typeof window !== 'undefined') {
          window.alert('Sharing with friends is coming soon!');
        }
      },
    },
    {
      content: 'Share in Virtual Lounge',
      onClick: () => {
        // FIXME Use library
        if (typeof window !== 'undefined') {
          window.alert('Sharing in Virtual Lounge is coming soon!');
        }
      },
    },
    {
      content: 'Share via email',
      icon: (
        <Icon
          vendor="fa"
          name={['far', 'envelope']}
          style={{ marginRight: 8 }}
        />
      ),
      onClick: () => {
        shareTo('email');
      },
    },
    {
      icon: (
        <Icon
          vendor="fa"
          name={['fab', 'facebook']}
          style={{ marginRight: 8 }}
        />
      ),
      content: 'Share to Facebook',
      onClick: () => {
        shareTo('facebook');
      },
    },
    {
      icon: (
        <Icon
          vendor="fa"
          name={['fab', 'twitter']}
          style={{ marginRight: 8 }}
        />
      ),
      content: 'Share to Twitter',
      onClick: () => {
        shareTo('twitter');
      },
    },
    {
      icon: (
        <Icon vendor="fa" name={['fab', 'reddit']} style={{ marginRight: 8 }} />
      ),
      content: 'Share to Reddit',
      onClick: () => {
        shareTo('reddit');
      },
    },
    {
      icon: (
        <Icon
          vendor="fa"
          name={['fab', 'pinterest']}
          style={{ marginRight: 8 }}
        />
      ),
      content: 'Share to Pinterest',
      onClick: () => {
        shareTo('pinterest');
      },
    },
    {
      icon: (
        <Icon vendor="fa" name={['fas', 'code']} style={{ marginRight: 8 }} />
      ),
      content: 'Embed',
      onClick: () => {
        shareTo('embed');
      },
    },
    {
      icon: (
        <Icon vendor="fa" name={['fas', 'link']} style={{ marginRight: 8 }} />
      ),
      content: 'Copy link',
      onClick: () => {
        shareTo('copy');
      },
    },
  ];

  ShareIntent.generateLink = (obj, service = 'navigator') =>
    new Promise((resolve, reject) => {
      const linkData = {
        // campaign: 'content 123',
        // feature: 'dashboard',
        // stage: 'new user',
        // alias: '',
        // tags: ['tag1', 'tag2', 'tag3'], // FIXME What are these used for? Marketing campaigns?
        channel: service,
        data: {
          path: obj.path,
          route: obj.route,
          id: obj.id,
          $og_title: obj.title,
          $og_description: obj.text,
          $og_image_url: obj.image,
          $og_video: obj.video,
        },
      };
      console.debug(linkData);
      if (typeof window !== 'undefined') {
        window.branch.link(linkData, (err, link) => {
          console.debug('Generating link');
          if (!err) {
            console.debug('Created Deep Link:');
            console.debug(link);
            resolve(link);
          } else {
            console.debug(err);
            reject(err);
          }
        });
      }
    });

  // FIXME How to make sure this is public? Use useReducer type hook? https://reactjs.org/docs/hooks-custom.html
  //  Is this the best way to handle it? It seems to work...
  /*
      {
        id: hashids.encode(item.id), // Hash ID of the object
        title: 'Share Title',
        text: 'X shared their virtual humidor with you.',
        image: 'some url',
        video: 'some url',
        path: 'article', // Branch.io path
        route: `/articles/${hashids.encode(item.id)}`,
      }
   */
  ShareIntent.share = (newData) => {
    console.debug('Clicked on share button...');
    setData(newData);
  };

  // if (navigator.share) {
  //   // Sharing is handled by the OS
  //   return <noscript />;
  // }

  // const [intentState, dispatch] = useReducer(reducer, { share: false });

  if (!navigator.share) {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={showSnackBar}
        onClose={() => setShowSnackBar(false)}
        autoHideDuration={3000}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message="Link Copied!"
      />
    );
  }
};

export default ShareIntent;
