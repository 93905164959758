import './style.scss';

import {
  isAndroid,
  isChrome,
  isIOS,
  isMobile,
  isSafari,
  isTablet,
} from 'mobile-device-detect';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import InstallPrompt from '@/components/InstallPrompt';

const urlParams = new URLSearchParams(window.location.search);
const standalone = urlParams.get('standalone') === 'true';
const isInStandaloneMode =
  standalone ||
  window.matchMedia('(display-mode: standalone)').matches ||
  window.navigator.standalone === true;

function PWAInstall() {
  const navigate = useNavigate();
  useEffect(() => {
    if (
      (!isMobile &&
        !(isAndroid && isChrome) &&
        !(isIOS && !isSafari) &&
        !((isIOS && isSafari && !isTablet) || (isAndroid && isChrome))) ||
      isInStandaloneMode
    ) {
      navigate('/');
    }
  }, []);

  return <InstallPrompt />;
}

export default PWAInstall;
