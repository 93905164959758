import './style.scss'

import Avatar from '@mui/material/Avatar'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Card, Carousel, CarouselItem, Container, Row } from 'reactstrap'

import { getProfileImage } from '@/components/ProfileSelect'
import VideoSource from '@/components/VideoSource'
import { AppStore, SettingsStore } from '@/stores'
import { Resize } from '@/utils/imageUtils'

import Icon from '../../Icon'

const InstagramSocialCard = ({ post }) => {
  const selectedProfile = AppStore.useState((s) => s.selectedProfile);
  // const selectedProfileType = AppStore.useState((s) => s.selectedProfileType);
  const darkMode = SettingsStore.useState((s) => s.darkMode);
  const [activeIndex, setActiveState] = useState(0);

  const renderHeader = () => {
    return (
      <div style={{ display: 'flex', padding: 5 }}>
        <Avatar
          src={
            selectedProfile &&
            (getProfileImage(selectedProfile.profile_image_url) ||
              getProfileImage(selectedProfile.logo_image_url))
          }
          style={{ height: 28, width: 28, margin: '8px 12px' }}
        >
          {selectedProfile && selectedProfile.name.charAt(0)}
        </Avatar>
        <div style={{ flex: 1 }}>
          <div style={{ fontWeight: 600, color: '#2b2b2b !important' }}>
            {/* TODO Use the linked account name instead */}
            {selectedProfile && selectedProfile.name}
            <div>{'Just Now'}</div>
          </div>
        </div>
      </div>
    );
  };

  const renderInnerMedia = () => {
    if (post.media && post.media.length) {
      if (
        post.media[0].media_type === 'video' ||
        post.media[0].media_url.indexOf('.mp4') !== -1
      ) {
        return (
          <div>
            <VideoSource
              videoSrc={post.media[0].media_url}
              scaleWidth="100%"
              scaleHeight="100%"
              // placeholder={post.image}
              title={post.title}
              thumbnailTime={post.media[0].video_timestamp_start || 0.1}
            />
          </div>
        );
      }
      return (
        <Container>
          <Row>
            {post.media.length > 1 && (
              <Carousel
                slide={false}
                interval={false}
                activeIndex={activeIndex}
                next={() => {
                  setActiveState((prevIndex) =>
                    prevIndex === post.media.length - 1 ? 0 : prevIndex + 1
                  );
                }}
                previous={() => {
                  setActiveState((prevIndex) =>
                    prevIndex === 0 ? post.media.length - 1 : prevIndex - 1
                  );
                }}
              >
                {post.media.map((item) => (
                  <CarouselItem key={item.media_url}>
                    <img
                      src={Resize.size(item.media_url)}
                      style={{ width: '100%', height: 318, objectFit: 'cover' }}
                    />
                  </CarouselItem>
                ))}
                <a
                  className="carousel-control-prev"
                  role="button"
                  tabIndex="0"
                  href="#"
                  onKeyDown={() => {}}
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveState((prevIndex) =>
                      prevIndex === 0 ? post.media.length - 1 : prevIndex - 1
                    );
                  }}
                >
                  <Icon name="chevron-left" style={{ height: 50 }} />
                </a>
                <a
                  className="carousel-control-next"
                  role="button"
                  tabIndex="0"
                  href="#"
                  onKeyDown={() => {}}
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveState((prevIndex) =>
                      prevIndex === post.media.length - 1 ? 0 : prevIndex + 1
                    );
                  }}
                >
                  <Icon name="chevron-right" style={{ height: 50 }} />
                </a>
              </Carousel>
            )}
            {post.media.length === 1 && (
              <img
                src={post.media[0].media_url}
                style={{ width: '100%', height: 318, objectFit: 'cover' }}
              />
            )}
          </Row>
        </Container>
      );
    }
    // if (post.media && post.media.length) {
    //   return (
    //     <MediaGrid
    //       media={post.media.map((media) => ({ src: media.media_url }))}
    //       thumbnailMedia={post.media.map((media) => ({ src: media.media_url && media.media_url.replace('/posts/', '/posts/500x500/').replace('/businesses/', '/businesses/500x500/') }))}
    //     />
    //   );
    // }
    return null;
  };

  const renderMedia = () => (
    <div className="image">
      {renderHeader()}
      {renderInnerMedia()}
    </div>
  );

  return (
    <Card style={{ width: 320, margin: 'auto' }}>
      <img
        src="https://business.facebook.com/images/instagram/ads/preview/header-background-2x.png"
        width="100%"
        style={{ filter: `invert(${darkMode ? 1 : 0})` }}
      />
      <div
        className="feed-card-item instagram-card"
        style={{ background: darkMode ? 'black' : 'white' }}
      >
        {renderMedia()}
        <div style={{ display: 'flex', flexDirection: 'row', padding: 10 }}>
          <div style={{ flex: 1 }}>
            <img
              src="https://business.facebook.com/images/instagram/ads/preview/Like.png"
              style={{
                height: 20,
                width: 20,
                marginRight: 15,
                filter: `invert(${darkMode ? 1 : 0})`,
              }}
            />
            <img
              src="https://business.facebook.com/images/instagram/ads/preview/Comment.png"
              style={{
                height: 20,
                width: 20,
                marginRight: 15,
                filter: `invert(${darkMode ? 1 : 0})`,
              }}
            />
            <img
              src="https://business.facebook.com/images/instagram/ads/preview/direct-share.png"
              style={{
                height: 20,
                width: 20,
                filter: `invert(${darkMode ? 1 : 0})`,
              }}
            />
          </div>
          <img
            src="https://business.facebook.com/images/instagram/ads/preview/save.png"
            style={{
              height: 20,
              width: 20,
              filter: `invert(${darkMode ? 1 : 0})`,
            }}
          />
        </div>
        <div className="content" style={{ marginBottom: 0 }}>
          <div className="content-right" style={{ width: '100%', padding: 10 }}>
            {(post.content || post.text || '').substring(0, 100) +
              ((post.content || post.text || '').length > 100
                ? ' ...more'
                : '')}
          </div>
          <div className="clearfix" />
        </div>
      </div>
    </Card>
  );
};

InstagramSocialCard.propTypes = {
  post: PropTypes.object.isRequired,
};

export default InstagramSocialCard;
