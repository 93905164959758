import './style.scss';

import { isMobile } from 'mobile-device-detect';
import React, { useEffect, useRef, useState } from 'react';
import { Gallery } from 'react-grid-gallery';
import { Alert, Button, ModalFooter } from 'reactstrap';

import BottomSheet from '@/components/BottomSheet';
import FileUploader from '@/components/FileUploader';
import Icon from '@/components/Icon';
import { Resize } from '@/utils/imageUtils';
import { language, messages } from '@/utils/localeUtils';

function DropzoneModal({
  initialMedia,
  onMediaSelected,
  onMediaUploaded,
  closeModal,
  isOpen,
  multiple,
}) {
  const [thumbnails, setThumbnails] = useState([]);
  const [uploadingMedia, setUploadingMedia] = useState(false);
  const [showNext, setShowNext] = useState(false);
  const addMedia = useRef(null);

  useEffect(() => {
    setThumbnails(initialMedia);
  }, [initialMedia]);

  useEffect(() => {
    setShowNext(
      thumbnails.filter((thumbnail) => thumbnail.isSelected).length > 0
    );
  }, [thumbnails]);

  const selectMedia = () => {
    if (typeof onMediaSelected === 'function') {
      onMediaSelected(thumbnails.filter((thumbnail) => thumbnail.isSelected));
    }
    if (typeof closeModal === 'function') {
      closeModal();
    }
  };

  const deleteSelectedMedia = () => {
    const selectedMedia = thumbnails.filter(
      (thumbnail) => thumbnail.isSelected
    );
    // TODO Prompt and then pass request to server
  };

  return (
    <BottomSheet
      open={isOpen}
      onClose={() => {
        if (typeof closeModal === 'function') {
          closeModal();
        }
      }}
      title="Select Media"
      style={{ maxWidth: 562 }}
      footer={
        <ModalFooter style={{ padding: 12 }}>
          <Button
            style={{ position: 'absolute', left: 20 }}
            onClick={() =>
              addMedia && addMedia.current && addMedia.current.click()
            }
          >
            <Icon name="upload" style={{ marginRight: 10 }} /> Upload
          </Button>
          {/* FIXME May want to add an edit button to toggle full multi select and disable continue - this way, users can delete multiple videos at once */}
          {thumbnails.filter((thumbnail) => thumbnail.isSelected).length >
            0 && (
            <Button
              color="danger"
              style={{ position: 'absolute', left: 170 }}
              onClick={deleteSelectedMedia}
            >
              <Icon name="trash" style={{ marginRight: 10 }} /> Delete{' '}
              {thumbnails.filter((thumbnail) => thumbnail.isSelected).length}{' '}
              files
            </Button>
          )}
          <Button color="text" onClick={closeModal}>
            {messages[language].cancel || 'Cancel'}
          </Button>
          <Button color="brand" disabled={uploadingMedia} onClick={selectMedia}>
            {messages[language].continue || 'Continue'}
          </Button>
        </ModalFooter>
      }
    >
      {multiple && (
        <Alert color="info" style={{ marginBottom: 10 }}>
          You can select 1 video OR multiple images
        </Alert>
      )}
      {/* TODO If media, show selection grid, otherwise dropzone? */}
      <FileUploader
        id="image-picker"
        multiple={multiple}
        // FIXME Only mp4 videos?
        accept="image/*,video/*"
        forwardedRef={addMedia}
        files={thumbnails}
        folder="businesses"
        filenameTemplate={() => `${new Date().getTime()}-uuid`}
        // uploading={(isUploading) => {
        //   setUploadingMedia(isUploading);
        //   // TODO Loading indicator
        // }}
        onUploading={(uploadingFile, files) => {
          console.debug('Uploading file:');
          console.debug(uploadingFile);
          console.debug('Uploading files:');
          console.debug(files);
        }}
        onUploaded={(uploadedFile, files) => {
          console.debug('Uploaded files:');
          console.debug(files);
          setUploadingMedia(false);
          // FIXME Should merge uploaded files with files from the server
          if (!multiple) {
            files.forEach((file) => {
              // Auto select the most recent one only
              if (file.key !== uploadedFile.key) {
                file.isSelected = false;
              }
            });
          }
          setThumbnails(files);
          if (typeof onMediaUploaded === 'function') {
            onMediaUploaded(uploadedFile);
          }
        }}
      />
      {/* FIXME Make the entire modal a dropzone as an alternative to the upload button */}
      {/* {!isMobile && ( */}
      {/*  <Dropzone */}
      {/*    id="dropzone" */}
      {/*    autoUpload */}
      {/*    folder="businesses" */}
      {/*    onChange={async (files) => { */}
      {/*      console.debug('Got files onChange:'); */}
      {/*      console.debug(files); */}
      {/*      const imageFiles = []; */}
      {/*      // for (let i = 0; i < imageFiles.length; i++) { */}
      {/*      //   const file = imageFiles[i]; */}
      {/*      //   console.debug('Converting to base64...'); */}
      {/*      //   await toBase64(file).then((imageFile) => { */}
      {/*      //     imageFiles.push(imageFile); */}
      {/*      //   }); */}
      {/*      // } */}
      {/*      setThumbnails(imageFiles); */}
      {/*    }} */}
      {/*    style={{ minHeight: 'auto', opacity: thumbnails.length ? 0 : 1 }} */}
      {/*  /> */}
      {/* )} */}
      <div style={{ flex: 1, overflow: 'scroll' }}>
        {/* TODO It would be nice to add an expand button to the gallery grid items so users can expand the image (default lightbox function from onClickThumbnail) */}
        {thumbnails.length > 0 && (
          <Gallery
            enableImageSelection
            enableLightbox
            images={thumbnails.map((thumbnail) => {
              console.debug('Adding thumbnail:');
              console.debug(thumbnail);
              let thumbnailImage =
                thumbnail.src ||
                (thumbnail.media_url && Resize.thumbnail(thumbnail.media_url));
              if (thumbnail.media_url) {
                // FIXME How to get the thumbnail from the video? This may also be on thumbnail.src instead (as base64)
                thumbnailImage = Resize.size(
                  thumbnail.media_url.replace('.mp4', '.jpg'),
                  { width: 200, height: 200, cropType: 'crop' }
                );
              }
              return {
                src: thumbnailImage,
                thumbnail: thumbnailImage,
                thumbnailWidth: 80,
                thumbnailHeight: 80,
                isSelected: thumbnail.isSelected,
                tags:
                  thumbnail.media_type === 'video'
                    ? [{ value: 'Video', title: 'Video' }]
                    : [],
              };
            })}
            onClick={(index, image) => {
              console.debug(`Selected ${image}`);
              const images = [...thumbnails];
              let hasVideo = false;

              images.forEach((thumbnail) => {
                if (
                  thumbnail.isSelected &&
                  (thumbnail.src?.includes('.mp4') ||
                    thumbnail.media_url?.includes('.mp4'))
                ) {
                  hasVideo = true;
                }
              });

              const img = images[index];
              if (
                img.src?.includes('.mp4') ||
                img.media_url?.includes('.mp4')
              ) {
                hasVideo = true;
              }

              if (!multiple || hasVideo) {
                images.forEach((thumbnail, i) => {
                  if (i !== index) {
                    thumbnail.isSelected = false;
                  }
                });
              }

              img.isSelected = !img.isSelected;
              setThumbnails(images);
            }}
          />
        )}
      </div>
      {!thumbnails.length && (
        <div style={{ textAlign: 'center', margin: 30 }}>
          Upload media below
        </div>
      )}
      {/* FIXME Only if no selections - otherwise, show Next button - see Instagram */}
      {!showNext && isMobile && (
        <div style={{ padding: 12 }}>
          <Button
            color="brand"
            style={{ width: '100%' }}
            onClick={() => {
              addMedia && addMedia.current && addMedia.current.click();
            }}
          >
            {'Upload Media'}
          </Button>
        </div>
      )}
      {showNext && isMobile && (
        <div style={{ padding: 12 }}>
          <Button color="brand" style={{ width: '100%' }} onClick={selectMedia}>
            {'Next'}
          </Button>
        </div>
      )}
    </BottomSheet>
  );
}

export default DropzoneModal;
