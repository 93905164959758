import '@/assets/scss/bootstrap-custom.scss';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import 'emoji-mart/css/emoji-mart.css';
import './styles.css';

import { setupIonicReact } from '@ionic/react';
import { Logtail } from '@logtail/browser';
import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';

import packageJson from '../package.json';
import App from './App';

const logtail = new Logtail('UJRk4A36F9811bReSSTCXmii');

if (process.env.NODE_ENV === 'production') {
  console.log = logtail.log.bind(logtail);
  console.error = logtail.error.bind(logtail);
  console.warn = logtail.warn.bind(logtail);
  console.debug = logtail.debug.bind(logtail);
}

setupIonicReact();

Sentry.init({
  dsn: 'https://96c16f0380e34138a3e980cb7fe133df@o351878.ingest.us.sentry.io/2449729',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.NODE_ENV,
  release: packageJson.version,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
    <ToastContainer />
  </React.StrictMode>
);
