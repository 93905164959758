import axios from 'axios';

import { config } from '@/settings';

// TODO Rename this file to mediaUtils or similar

function request(url, opts = {}, onProgress) {
  return new Promise((res, rej) => {
    const xhr = new XMLHttpRequest();
    xhr.open(opts.method || 'GET', url);
    // eslint-disable-next-line no-restricted-syntax,guard-for-in
    for (const k in opts.headers || {}) {
      xhr.setRequestHeader(k, opts.headers[k]);
    }
    xhr.onload = (e) => res(e.target.responseText);
    xhr.onerror = rej;
    if (xhr.upload && onProgress) xhr.upload.onprogress = onProgress;
    xhr.send(opts.body);
  });
}

export const mediaInstance = axios.create();
mediaInstance.defaults.headers.common = {
  'Content-Type': 'multipart/form-data',
  Accept: 'text/html',
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

/**
 * Handler to ensure cropped images are used in place of original images, but do not overwrite them.
 * @param originalImages The original, un-cropped images.
 * @param croppedImages The cropped images, based on the original images.
 * @return uploadedImages The images to be attached to the post (smoke session, checkin, etc). If not cropped, these will be the original images.
 */
// FIXME Better name for this function?
export const imageHolder = (originalImages, croppedImages) => {
  const uploadedImages = [];
  const thumbnailImages = [];
  return {
    originalImages,
    thumbnailImages,
    croppedImages,
    uploadedImages,
  };
};

export const uploadToS3 = (
  file,
  folder,
  filename,
  uuid,
  callback,
  setUploadStatus
) =>
  new Promise((resolve, reject) => {
    axios
      .post(
        `${config.mediaEndPoint}/sign`,
        {},
        {
          params: {
            uuid,
            filename,
            filetype: file.type,
            type: folder,
          },
        }
      )
      .then((result) => {
        console.debug('Signed response:');
        console.debug(JSON.stringify(result.data.signed_url));
        const options = {
          method: 'PUT',
          body: file,
        };

        if (typeof callback === 'function') {
          callback(result);
        }

        console.debug('Direct upload in progress...');
        // INFO This wasn't working consistently with Axios
        request(result.data.signed_url, options, (event) => {
          console.log('Upload progress:');
          const progress = Math.round((event.loaded / event.total) * 100);
          console.log(progress);
          setUploadStatus({ progress, status: 'uploading' });
        })
          .then(() => {
            console.debug('S3 success');
            setUploadStatus({ progress: 100, status: 'completed' });
            resolve(result);
          })
          .catch((err) => {
            console.debug('S3 failure:');
            console.debug(err);
            setUploadStatus({ progress: 0, status: 'failed' });
            reject(err);
          });
      })
      .catch((err) => {
        console.debug('Failed');
        reject(err);
      });
  });

// FIXME Make another account for dev on cloudimage?
export const Resize = {
  // TODO https://docs.cloudimage.io/go/cloudimage-documentation-v7/en/image-resizing
  //  `func=crop` => cover, `func=bound` => contain, can also contain with blurred background with `func=fit&bg_img_fit=1&bg_opacity=0.75`
  //  https://docs.cloudimage.io/go/cloudimage-documentation-v7/en/image-resizing/
  thumbnail: (imageUrl, { cropType = 'crop', additionalParams = '' } = {}) =>
    `https://aouikjkrpo.cloudimg.io/v7/${imageUrl}?w=200&h=200&func=${cropType}&force_format=webp${additionalParams}`,
  size: (
    imageUrl,
    { width = 500, height = 500, cropType = 'crop', additionalParams = '' } = {}
  ) =>
    `https://aouikjkrpo.cloudimg.io/v7/${imageUrl}?w=${width}&h=${height}&func=${cropType}&force_format=webp${additionalParams}`,
  transparent: (
    imageUrl,
    { width = 500, height = 500, cropType = 'crop' } = {}
  ) =>
    `https://aouikjkrpo.cloudimg.io/v7/${imageUrl}?w=${width}&h=${height}&func=${cropType}&force_format=webp&bg_remove=1`,
  fromCDN: (originalUrl, prefix, { width = 500, height = 500 } = {}) =>
    originalUrl.replace(prefix, `${width}x${height}${prefix}`),
};
