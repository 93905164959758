import type { Dispatch, ReactNode, SetStateAction } from 'react';
import React, { createContext, useContext, useState } from 'react';

interface UploadStatus {
  progress: number;
  status: 'idle' | 'uploading' | 'completed' | 'failed';
}

interface UploadContextValue {
  uploadStatus: UploadStatus;
  setUploadStatus: Dispatch<SetStateAction<UploadStatus>>;
}

const UploadContext = createContext<UploadContextValue | undefined>(undefined);

interface UploadProviderProps {
  children: ReactNode;
}

export const UploadProvider: React.FC<UploadProviderProps> = ({ children }) => {
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>({
    progress: 0,
    status: 'idle',
  });

  return (
    <UploadContext.Provider value={{ uploadStatus, setUploadStatus }}>
      {children}
    </UploadContext.Provider>
  );
};

export const useUploadStatus = (): UploadContextValue => {
  const context = useContext(UploadContext);
  if (!context) {
    throw new Error('useUploadStatus must be used within an UploadProvider');
  }
  return context;
};
