import './style.scss';

import Avatar from '@mui/material/Avatar';
import Hashids from 'hashids';
import { isMobile } from 'mobile-device-detect';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Rating from 'react-rating';
import { Link } from 'react-router-dom';

import FeedCardActions from '@/components/FeedCardActions';
import MediaGrid from '@/components/MediaGrid';
import PlaceholderDark from '@/config/placeholder-dark.config';
// import axios from 'axios';
import Product from '@/models/Product';
import ShareItem from '@/models/ShareItem';
import { Resize } from '@/utils/imageUtils';
// import { config } from '../../settings';
import ShareIntent from '@/utils/shareUtils';

const hashids = new Hashids('', 12);

// FIXME Dark mode vs light mode
const defaultImageUrl = PlaceholderDark.cigar; // : Placeholder.cigar;

const BuyButton = ({ product }) => (
  <button
    className="btn btn-success"
    style={{
      textAlign: 'center',
      fontSize: 14,
      display: 'inline-block',
    }}
  >
    {product.getFormattedPrice()}
  </button>
);

class FeedProductCard extends PureComponent {
  constructor(props) {
    super(props);
    if (window.analytics) {
      window.analytics.track('Product Impressions', {
        product_id: this.props.item.id,
        venue_id: this.props.item.venue_id,
        user_id:
          this.props.auth && this.props.auth.user && this.props.auth.user.id,
      });
    }
  }

  showProductDetail = (product) => {};

  formatDate = (dateStr) => {
    // TODO Include format like "4 hours ago" or "1 day ago" max
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const date = new Date(dateStr);
    return date.toLocaleDateString('en-US', options); // TODO How to get user's locale?
  };

  renderHeader = () => {
    const product = this.props.item;
    const venue = product.venue || {};
    if (!this.props.preview) {
      return (
        <div
          style={{
            display: 'flex',
            padding: 5,
          }}
          className="feed-session-card-header"
        >
          <Link to={`/venues/${hashids.encode(venue.id)}`}>
            <Avatar
              src={`//${venue.profile_image_url}`}
              style={{
                height: 28,
                width: 28,
                margin: '8px 12px',
              }}
            >
              {venue.name && (venue.name.charAt(0) || 'B')}
            </Avatar>
          </Link>
          <div style={{ flex: 1 }}>
            <Link to={`/venues/${hashids.encode(venue.id)}`}>
              <div
                style={{
                  fontWeight: 600,
                  color: '#2b2b2b !important',
                }}
              >
                {venue.name}
              </div>
            </Link>
            <div style={{ fontSize: 12 }}>
              {(venue.location && venue.location.formatted_address) ||
                venue.website}
            </div>
          </div>
        </div>
      );
    }
    return (
      <div
        style={{
          display: 'flex',
          padding: 5,
        }}
        className="feed-session-card-header"
      >
        <a href="#">
          <Avatar
            src={`//${venue.profile_image_url}`}
            style={{
              height: 28,
              width: 28,
              margin: '8px 12px',
            }}
          >
            {venue.name && (venue.name.charAt(0) || 'B')}
          </Avatar>
        </a>
        <div style={{ flex: 1 }}>
          <a href="#">
            <div
              style={{
                fontWeight: 600,
                color: '#2b2b2b !important',
              }}
            >
              {venue.name}
            </div>
          </a>
          <div style={{ fontSize: 12 }}>
            {(venue.location && venue.location.formatted_address) ||
              venue.website}
          </div>
        </div>
      </div>
    );
  };

  renderMedia = () => (
    <div className="image" style={{ cursor: 'pointer' }}>
      {!this.props.hideHeader && this.renderHeader()}
      {this.renderInnerMedia()}
    </div>
  );

  renderInnerMedia = () => {
    const product = this.props.item;
    const { orientation } = this.props;
    const imageURL = Product.getImage(product);
    if (imageURL || orientation === 'horizontal') {
      return (
        <MediaGrid
          media={[
            {
              type: 'image',
              src: imageURL,
            },
          ]}
          lazyImages={[
            {
              type: 'image',
              src: Resize.size(imageURL, {
                height: 100,
                width: 100,
                cropType: 'crop',
              }),
            },
          ]}
          thumbnailMedia={[
            {
              type: 'image',
              src: Resize.size(imageURL),
            },
          ]}
          onClick={() => this.showProductDetail(product)}
        />
      );
    }
    return null;
  };

  // FIXME As previously discussed, consider using product.contents[0].cigar.full_name when available for consistency and
  //  then fall back to the name otherwise - we don't want the same cigar shown with multiple variations of its name
  productTitle = () => {
    const product = this.props.item;
    if (product.packaging && product.packaging.type !== 'sampler') {
      if (
        product.contents &&
        product.contents.length === 1 &&
        product.contents[0].cigar &&
        product.contents[0].vitola
      ) {
        return `${product.contents[0].cigar.full_name} ${product.contents[0].vitola.formatted_name}`;
      }
      if (
        product.contents &&
        product.contents.length === 1 &&
        product.contents[0].vitola
      ) {
        return `${product.name} ${product.contents[0].vitola.formatted_name}`;
      }
    }
    return product.name;
  };

  productPackaging = () => {
    const product = this.props.item;
    if (product.packaging) {
      let qty = parseInt(product.packaging.quantity);
      if (product.packaging.type === 'Sampler' && product.contents) {
        for (let i = 0; i < product.contents.length; i++) {
          qty += product.contents[i].quantity;
        }
      }
      if (qty > 0) {
        return `${product.packaging.type} of ${qty}`;
      }
      return `${product.packaging.type}`;
    }
    return null;
  };

  // FIXME Rewrite this for header
  renderShopInfo = () => {
    const cigar = this.props.item;
    if (isMobile) {
      return (
        <div
          className="products"
          style={{
            position: 'absolute',
            bottom: 20,
            marginRight: 20,
            borderTop: '1px solid #efefef',
            paddingTop: 10,
          }}
        >
          Sold by
          <a
            href={`https://sigaro-shop.com/module/trylist/productlisting?c=${cigar.id}`}
          >
            <img
              src="http://cdn.sigaro.io/assets/img/shop-logo.svg"
              alt="Sigaro Smoke Shop"
              className="shop-logo"
            />
          </a>
        </div>
      );
    }
    return (
      <div className="products" style={{ float: 'left', marginBottom: 10 }}>
        Sold by
        <a
          href={`https://sigaro-shop.com/module/trylist/productlisting?c=${cigar.id}`}
        >
          <img
            src="http://cdn.sigaro.io/assets/img/shop-logo.svg"
            alt="Sigaro Smoke Shop"
            className="shop-logo"
          />
        </a>
      </div>
    );
  };

  render() {
    const { orientation } = this.props;
    const product = this.props.item;
    // console.log(product);
    let desc = '';

    if (product.description) {
      desc = `${product.description.substring(0, 250)}...`;
    }

    let price;
    const { msrp } = product;
    if (product.sale_price) {
      price = product.sale_price;
    }

    const savings = msrp
      ? `${(msrp > price ? (1 - price / msrp) * 100 : 0).toFixed(0)}%`
      : 0;

    if (orientation === 'horizontal') {
      return (
        <div className="feed-card-item">
          {this.renderHeader()}
          <div
            style={{
              display: 'flex',
              flexDirection: orientation === 'horizontal' ? 'row' : 'column',
            }}
          >
            <div className="image-wrapper">{this.renderInnerMedia()}</div>
            <div className="content">
              <div
                className="content-right"
                style={{ width: '100%', paddingTop: 1 }}
              >
                <h3
                  onClick={() => this.showProductDetail(product)}
                  style={{ marginBottom: 0, cursor: 'pointer' }}
                >
                  {this.productTitle()}
                </h3>
                <div>{this.productPackaging()}</div>
                <div>
                  <Rating
                    initialRating={product.avg_rating / 20}
                    emptySymbol="far fa-star"
                    fullSymbol="fas fa-star"
                    fractions={2}
                    readonly
                    style={{ color: '#d3a966', marginBottom: 10 }}
                  />
                  <span style={{ marginLeft: 10 }}>
                    {`${product.rating_count || 0} ratings`}
                  </span>
                </div>
                <div>
                  <BuyButton product={new Product(product)} />
                  {msrp && msrp > price && (
                    <div style={{ marginLeft: 10, display: 'inline-block' }}>
                      {'MSRP'}{' '}
                      <span
                        style={{
                          textDecoration: 'line-through',
                          marginRight: 8,
                        }}
                      >
                        {`$${msrp}`}
                      </span>
                      <span>{`(Save ${savings})`}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="feed-card-item">
        {this.renderMedia()}
        <div className="content">
          <div
            className="content-right"
            style={{ width: '100%', paddingTop: 1 }}
          >
            <h3
              onClick={() => this.showProductDetail(product)}
              style={{ marginBottom: 0, cursor: 'pointer' }}
            >
              {this.productTitle()}
            </h3>
            <div>{this.productPackaging()}</div>
            <div>
              <Rating
                initialRating={product.avg_rating / 20}
                emptySymbol="far fa-star"
                fullSymbol="fas fa-star"
                fractions={2}
                readonly
                style={{ color: '#d3a966', marginBottom: 10 }}
              />
              <span style={{ marginLeft: 10 }}>
                {`${product.rating_count || 0} ratings`}
              </span>
            </div>
            <div>
              <BuyButton product={new Product(product)} />
              {msrp && msrp > price && (
                <div style={{ marginLeft: 10, display: 'inline-block' }}>
                  {'MSRP'}{' '}
                  <span
                    style={{ textDecoration: 'line-through', marginRight: 8 }}
                  >
                    {`$${msrp}`}
                  </span>
                  <span>{`(Save ${savings})`}</span>
                </div>
              )}
            </div>
            {!this.props.hideDescription && (
              <p
                dangerouslySetInnerHTML={{ __html: desc }}
                style={{ margin: '10px 10px 0 0' }}
              />
            )}
          </div>
        </div>
        <FeedCardActions
          preview={this.props.preview}
          postId={product.product_id}
          commentType="product"
          commentRoute="products"
          commentCount={this.props.commentCount}
          likeCount={this.props.likeCount}
          commentOnClick={
            this.props.compact
              ? () => {
                  // FIXME Just pass it no matter what?
                  if (typeof this.props.onCommentClick === 'function') {
                    this.props.onCommentClick();
                  }
                }
              : null
          }
          onLikedToggle={(liked) => {
            console.debug(`The post is ${liked ? 'liked' : 'not liked'}`);
          }}
          onPostComment={(comment, parentId) => {
            console.debug('Comment posted...');
            console.debug(comment);
            // axios.post(`${config.apiEndPoint}/products/${product.product_id}/comments`, {
            //   product_id: product.product_id,
            //   // FIXME We don't want any HTML past this point - convert to plain text - use something better than this approach?
            //   comment: comment.replace('<br>', ''),
            //   user_id: props.auth.user.id,
            //   [`${selectedProfileType.replace('cigar_', '')}_id`]: selectedProfile.id,
            //   parent_id: parentId,
            //   comment_timestamp: new Date().toISOString(),
            // });
          }}
          onShare={() =>
            ShareIntent.share(
              new ShareItem({
                title: Product.title(product),
                text: `Check out ${Product.title(
                  product
                )} for ${Product.formatPrice(product)} on Boxpressd`,
                path: 'product',
                route: `/products/${hashids.encode(product.product_id)}`,
                image: Product.getImage(product),
              })
            )
          }
        />
      </div>
    );
  }
}

FeedProductCard.propTypes = {
  item: PropTypes.object.isRequired,
  // name: PropTypes.string,
  orientation: PropTypes.string,
};

FeedProductCard.defaultProps = {
  orientation: 'grid',
};

export default FeedProductCard;
