import { useEffect } from 'react';

const Freshchat = () => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      // @ts-ignore
      function initFreshChat() {
        window.fcWidget.init({
          token: '959694cd-2585-4050-a09a-6ce56f628b7f',
          host: 'https://boxpressd.freshchat.com',
          config: {
            headerProperty: {
              hideChatButton: true,
            },
          },
        });

        window.fcWidget.on('widget:closed', function () {
          console.log('Widget Closed');
          document.querySelector('#fc_frame')!.style.display = 'none';
        });
      }

      const scriptId = 'freshchat-js-sdk';

      if (document.getElementById(scriptId)) {
        return;
      }

      const script = document.createElement('script');
      script.id = scriptId;
      script.async = true;
      script.defer = true;
      script.src = 'https://boxpressd.freshchat.com/js/widget.js';
      script.onload = initFreshChat;

      document.head.appendChild(script);
    }
  }, []);

  return null;
};

export default Freshchat;
