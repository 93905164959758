import { useIonToast } from '@ionic/react';
import { isMobile } from 'mobile-device-detect';
import { toast } from 'react-toastify';

const colorMap = {
  success: 'success',
  danger: isMobile ? 'danger' : 'error',
  error: isMobile ? 'danger' : 'error',
  info: isMobile ? 'primary' : 'info',
  warning: 'warning',
  primary: isMobile ? 'primary' : 'default',
  secondary: isMobile ? 'secondary' : 'default',
};

const useToast = () => {
  const [present] = useIonToast();

  const showToast = ({
    content,
    duration = 1500,
    position = 'bottom',
    color = 'success',
  }) => {
    if (isMobile) {
      // @ts-ignore
      present({
        message: content,
        duration,
        position,
        color: colorMap[color] || color,
      });
    } else {
      console.log('showToast', content);
      toast[colorMap[color]](content, {
        position: 'top-right',
      });
    }
  };
  return { showToast };
};

export default useToast;
