import './style.scss';

import { IonFooter, useIonModal } from '@ionic/react';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';
import { isIOS, isMobile } from 'mobile-device-detect';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ContentEditable from 'react-contenteditable';

import CommentList from '@/components/CommentList';
import Icon from '@/components/Icon';
import ThumbsUpIcon from '@/components/Icon/custom/ThumbUpIcon';
import ThumbsUpOutlineIcon from '@/components/Icon/custom/ThumbUpOutlineIcon';
import useDialogAlert from '@/components/ModalDialog';
import PostActionIcons from '@/components/PostActionIcons';
import { config } from '@/settings';
import { AppStore, SettingsStore } from '@/stores';
import { Resize } from '@/utils/imageUtils';
import { language, messages } from '@/utils/localeUtils';

function FeedCardActions(props) {
  const { showDialogAlert } = useDialogAlert();
  const darkMode = SettingsStore.useState((s) => s.darkMode);
  const selectedProfile = AppStore.useState((s) => s.selectedProfile);
  const [liked, setLiked] = useState(false);
  const [attachment, setAttachment] = useState(null);
  const [likesCount, setLikesCount] = useState(0);
  const [commentCount, setCommentCount] = useState(0);
  const [comments, setComments] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [comment, setComment] = useState('');
  // FIXME Should be false by default - get it working smoothly like FB
  const [showReply, setShowReply] = useState(true);

  const textContent = useRef(null);

  const submitComment = () => {
    if (typeof props.onPostComment === 'function') {
      // TODO Sanitize text before passing to onPostComment
      props.onPostComment(
        textContent.current,
        selectedMessage ? selectedMessage.id : null
      ); // TODO Include the attached media here?
    }
    textContent.current = null;
    setCommentCount(commentCount + 1);
    // TODO Post it to the comment list
  };

  // FIXME Reset comments, etc onDismiss?
  const MyModalContent = useCallback(
    ({ onDismiss }) => (
      <>
        <div style={{ fontWeight: 600, textAlign: 'center', marginTop: 30 }}>
          Comments
        </div>
        <div style={{ flex: 1 }}>
          <CommentList
            compact
            comments={comments}
            likeCount={likesCount}
            // FIXME Get this from server / cache
            // liked={liked}
          />
        </div>
        <IonFooter className="comments-footer">
          <div style={{ display: 'flex' }}>
            <ContentEditable
              id={`text-content-${props.postId}`}
              // FIXME Resize if textContent.current.length > 0 and add in send icon
              style={{
                borderRadius: 20,
                margin: 8,
                width: 'calc(100% - 16px)',
              }}
              className="contenteditable form-control form-input form-control-contrast"
              tagName="div"
              html={textContent.current}
              placeholder="Press enter to submit your comment"
              onChange={(e) => {
                textContent.current = e.target.value;
                // FIXME This causes a re-render of the whole component and slows everything down, plus this input loses focus
                setComment(e.target.value);
              }}
            />
            {showReply && (
              <IconButton
                onClick={() => {
                  if (comment.length > 0) {
                    submitComment();
                  } else {
                    // FIXME "Ok" button?
                    showDialogAlert({
                      title: 'Missing text',
                      message: 'Please include text for your comment',
                    });
                  }
                }}
              >
                <Icon
                  name="send-rounded"
                  vendor="material"
                  style={{ height: 26, width: 26 }}
                />
              </IconButton>
            )}
          </div>
          <div
            style={{
              marginRight: 8,
              width: 'auto',
              position: 'absolute',
              right: showReply ? 42 : 0,
              top: 6,
              textAlign: 'right',
            }}
          >
            <PostActionIcons
              uid={props.postId}
              // type="popover"
              gifPickerWidth={280}
              gifPickerColumns={1}
              emojiPickerWidth={350}
              onGifSelected={(item) => setAttachment(item.embed_url)}
              onEmojiSelected={(data) => {
                console.debug('Adding emoji!');
                console.debug(data);
                if (data.native) {
                  textContent.current = `${textContent.current || ''} ${
                    data.native
                  }`;
                } else if (data.imageUrl) {
                  textContent.current = `${
                    textContent.current || ''
                  } <img src="${data.imageUrl}" class="emoji" />`;
                  // TODO How can we set an image in the text field? This is the only way to handle the custom emojis
                  //  Slack does contenteditable="true" div and then an image in a <p> <img /> some text <img /></p>
                  // <img data-id=":boxpressd-like:" data-title=":boxpressd-like:" data-stringify-text=":boxpressd-like:" class="emoji" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" alt=":boxpressd-like:" style="background-image: url(https://emoji.slack-edge.com/TN8BSDVHR/boxpressd-like/e86a64aefa8ef638.png);">
                }
                setForceUpdate(!forceUpdate);
              }}
              onMediaSelected={(e) => {
                // TODO Attach to state and upload to server
              }}
            />
          </div>
        </IonFooter>
      </>
    ),
    [showReply]
  );

  const [present, dismiss] = useIonModal(MyModalContent, {
    onDismiss: () => dismiss(),
  });

  useEffect(() => {
    let commentsEndpoint;
    const decodedId = props.postId;
    if (props.commentType === 'session') {
      commentsEndpoint = `${config.apiEndPoint}/sessions/${decodedId}/comments`;
    } else if (props.commentType === 'checkin') {
      commentsEndpoint = `${config.apiEndPoint}/venues/checkins/${decodedId}/comments`;
    } else if (props.commentType === 'product_review') {
      commentsEndpoint = `${config.apiEndPoint}/products/reviews/${decodedId}/comments`;
    } // TODO Other types

    if (commentsEndpoint) {
      axios
        .get(commentsEndpoint)
        .then((response) => {
          console.log('Got post comments:');
          console.log(response.data);
          setComments(response.data);
          // setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          // setLoading(false);
        });
    } else {
      // 404? Error? Sentry notice?
    }
  }, []);

  useEffect(() => {
    setCommentCount(props.commentCount);
  }, [props.commentCount]);

  useEffect(() => {
    setLikesCount(props.likeCount);
  }, [props.likeCount]);

  // useEffect(() => {
  //   setShowReply(comment.length > 0);
  // }, [comment]);

  const getProfileImage = (profile) => {
    if (profile) {
      const image = profile.profile_image_url || profile.logo_image_url;
      if (image) {
        if (image.indexOf('base64') !== -1) {
          return Resize.size(image, {
            height: 50,
            width: 50,
            croptType: 'crop',
          });
        }
        if (image.indexOf('http') === -1) {
          return Resize.size(`https://${image}`, {
            height: 50,
            width: 50,
            croptType: 'crop',
          });
        }
        return Resize.size(image, {
          height: 50,
          width: 50,
          croptType: 'crop',
        });
      }
    }
    return null;
  };

  const share = () => {
    // TODO Show users to share with? For now, just show the typical share menu
    if (props.onShare) {
      props.onShare();
    }
  };

  return (
    <div className="post-actions-wrapper">
      <div style={{ display: 'none' }}>{forceUpdate}</div>
      <div
        id="post-actions"
        style={{
          display: 'flex',
          borderTop: darkMode
            ? '1px solid #3c424c'
            : '1px solid rgb(210, 210, 210, 0.5)',
          // eslint-disable-next-line no-nested-ternary
          borderBottom: isMobile
            ? 'none'
            : darkMode
            ? '1px solid #3c424c'
            : '1px solid rgb(210, 210, 210, 0.5)',
        }}
      >
        {props.preview && (
          <div
            style={{
              flex: 1,
              textAlign: 'center',
              padding: 10,
              cursor: 'pointer',
            }}
          >
            <Icon
              vendor="svg"
              component={liked ? ThumbsUpIcon : ThumbsUpOutlineIcon}
            />
            <span
              style={{ marginLeft: 8, display: 'inline-block' /* width: 40 */ }}
            >
              {liked
                ? messages[language].liked || 'Liked'
                : messages[language].like || 'Like'}
            </span>
          </div>
        )}
        {/* FIXME Allow the business to like these, too - if they are their own post, show them who liked it? */}
        {!props.preview && (
          <div
            style={{
              flex: 1,
              textAlign: 'center',
              padding: 10,
              cursor: 'pointer',
            }}
          >
            {props.likeCount}{' '}
            <span
              style={{ marginLeft: 8, display: 'inline-block' /* width: 40 */ }}
            >
              {messages[language].likes || 'Likes'}
            </span>
          </div>
        )}
        {props.preview && (
          <div
            style={{
              flex: 1,
              textAlign: 'center',
              padding: 10,
              cursor: 'pointer',
            }}
            onClick={() => {
              if (typeof props.commentOnClick === 'function') {
                props.commentOnClick();
              } else if (isMobile) {
                present({
                  // FIXME Revert back to 0.8 once the sticky comment input is fixed
                  // initialBreakpoint: 0.8,
                  initialBreakpoint: 1,
                  breakpoints: [0, 0.8, 1],
                  // cssClass: 'auto-height-sheet',
                });
              } else {
                // jQuery(`#text-content-${props.postId}`).focus();
              }
            }}
          >
            <Icon name="message-circle" />
            <span style={{ marginLeft: 8 }}>
              {messages[language].comment || 'Comment'}
            </span>
          </div>
        )}
        {!props.preview && props.onPostComment && (
          <div
            style={{
              flex: 1,
              textAlign: 'center',
              padding: 10,
              cursor: 'pointer',
            }}
            onClick={() => {
              if (typeof props.commentOnClick === 'function') {
                props.commentOnClick();
              } else if (isMobile) {
                present({
                  // FIXME Revert back to 0.8 once the sticky comment input is fixed
                  // initialBreakpoint: 0.8,
                  initialBreakpoint: 1,
                  breakpoints: [0, 0.8, 1],
                  // cssClass: 'auto-height-sheet',
                });
              } else {
                // jQuery(`#text-content-${props.postId}`).focus();
              }
            }}
          >
            {commentCount}{' '}
            <span style={{ marginLeft: 8 }}>
              {messages[language].comments || 'Comments'}
            </span>
          </div>
        )}
        {/* FIXME If share link generation in progress, show loading spinner instead of share icon - see Hevy app */}
        {props.onShare && (
          <div
            style={{
              flex: 1,
              textAlign: 'center',
              padding: 10,
              cursor: 'pointer',
            }}
            onClick={share}
          >
            {isIOS ? <Icon name="share" /> : <Icon name="share2" />}
            <span
              style={{ marginLeft: 8, display: 'inline-block' /* width: 40 */ }}
            >
              {messages[language].share || 'Share'}
            </span>
          </div>
        )}
      </div>
      {!isMobile && !props.preview && (
        <div className="desktop-comment-container">
          <CommentList
            comments={comments}
            // isTyping={isTyping} // Boolean: is the recipient typing
            onReply={(message) => {
              setSelectedMessage(message);
              // FIXME This is good to display it but it needs to post to the server as `[@${message.user.full_name}|userId:${message.user.id}] ${textContent || ''}`
              textContent.current = `<a href="#">${
                message.user.full_name
              }</a>&nbsp;${textContent.current || ''}`;
              // FIXME Use vanilla js or react ref for this
              // jQuery(`#text-content-${props.postId}`).focus();
            }}
          />
        </div>
      )}
      {!isMobile && props.showComments && (
        <div
          style={{
            paddingTop: 10,
            borderTop: darkMode
              ? '1px solid #3c424c'
              : '1px solid rgb(210, 210, 210), 0.5',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Avatar
              src={selectedProfile && getProfileImage(selectedProfile)}
              style={{ height: 28, width: 28, margin: '8px 12px' }}
            >
              {selectedProfile && selectedProfile.name.charAt(0)}
            </Avatar>
            <ContentEditable
              id={`text-content-${props.postId}`}
              style={{ borderRadius: 20, marginRight: 8 }}
              className="contenteditable form-control form-input form-control-contrast"
              tagName="div"
              html={textContent.current}
              placeholder="Press enter to submit your comment"
              onChange={(e) => {
                textContent.current = e.target.value;
              }}
              // onBlur={sanitize}
              onKeyUp={(e) => {
                if (!isMobile && e.key === 'Enter') {
                  e.preventDefault();
                  submitComment();
                }
              }}
            />
            <div style={{ marginRight: 8, width: 200 }}>
              <PostActionIcons
                uid={props.postId}
                // type="popover"
                gifPickerWidth={280}
                gifPickerColumns={1}
                emojiPickerWidth={350}
                onGifSelected={(item) => setAttachment(item.embed_url)}
                onEmojiSelected={(data) => {
                  console.debug('Adding emoji!');
                  console.debug(data);
                  if (data.native) {
                    textContent.current = `${textContent.current || ''} ${
                      data.native
                    }`;
                  } else if (data.imageUrl) {
                    textContent.current = `${
                      textContent.current || ''
                    } <img src="${data.imageUrl}" class="emoji" />`;
                    // TODO How can we set an image in the text field? This is the only way to handle the custom emojis
                    //  Slack does contenteditable="true" div and then an image in a <p> <img /> some text <img /></p>
                    // <img data-id=":boxpressd-like:" data-title=":boxpressd-like:" data-stringify-text=":boxpressd-like:" class="emoji" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" alt=":boxpressd-like:" style="background-image: url(https://emoji.slack-edge.com/TN8BSDVHR/boxpressd-like/e86a64aefa8ef638.png);">
                  }
                  setForceUpdate(!forceUpdate);
                }}
                onMediaSelected={(e) => {
                  // TODO Attach to state and upload to server
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

FeedCardActions.propTypes = {
  commentCount: PropTypes.number,
  likeCount: PropTypes.number,
  showComments: PropTypes.bool,
};

FeedCardActions.defaultProps = {
  commentCount: 0,
  likeCount: 0,
  showComments: true,
};

export default FeedCardActions;
