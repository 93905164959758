import './style.scss';

import PropTypes from 'prop-types';
import React from 'react';
import SlickCarousel from 'react-slick';

import Icon from '@/components/Icon';

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <Icon name="chevron-right" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <Icon name="chevron-left" />
    </div>
  );
}

function Carousel(props) {
  const {
    className,
    dots,
    infinite,
    centerMode,
    slidesToShow,
    slidesToScroll,
    variableWidth,
    arrows,
    nextArrow,
    prevArrow,
  } = props;

  const settings = {
    className,
    dots,
    infinite,
    centerMode,
    slidesToShow,
    slidesToScroll,
    variableWidth,
    arrows,
    nextArrow,
    prevArrow,
  };

  return (
    <SlickCarousel {...settings} {...props}>
      {props.children}
    </SlickCarousel>
  );
}

Carousel.propTypes = {
  className: PropTypes.string,
  dots: PropTypes.bool,
  infinite: PropTypes.bool,
  centerMode: PropTypes.bool,
  slidesToShow: PropTypes.number,
  slidesToScroll: PropTypes.number,
  variableWidth: PropTypes.bool,
  arrows: PropTypes.bool,
  nextArrow: PropTypes.element,
  prevArrow: PropTypes.element,
  children: PropTypes.element.isRequired,
};

Carousel.defaultProps = {
  className: 'slider variable-width',
  dots: false,
  infinite: false,
  centerMode: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  arrows: true,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};

export default Carousel;
