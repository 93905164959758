import { isMobile } from 'mobile-device-detect';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';

import Icon from '@/components/Icon';
import { config } from '@/settings';
import { SettingsStore, UserStore } from '@/stores';

const initialCardState = {
  cardNumber: '#### #### #### ####',
  cardHolder: 'FULL NAME',
  cardMonth: '',
  cardYear: '',
  cardCvv: '',
  isCardFlipped: false,
};

function CreditCardModal({ show, price, lineItems, onToken, onClose }) {
  const darkMode = SettingsStore.useState((s) => s.darkMode);
  const currentUser = UserStore.useState((s) => s.user);
  const [creditCardState, setCreditCardState] = useState(initialCardState);
  const [currentFocusedElm, setCurrentFocusedElm] = useState(null);

  const updateStateValues = useCallback(
    (keyName, value) => {
      setCreditCardState((prevState) => ({
        ...prevState,
        [keyName]: value || initialCardState[keyName],
      }));
    },
    [creditCardState]
  );

  const formFieldsRefObj = {
    cardNumber: useRef(),
    cardHolder: useRef(),
    cardDate: useRef(),
    cardCvv: useRef(),
  };

  const focusFormFieldByKey = useCallback((key) => {
    formFieldsRefObj[key].current.focus();
  }, []);

  // This are the references for the Card DIV elements.
  const cardElementsRef = {
    cardNumber: useRef(),
    cardHolder: useRef(),
    cardDate: useRef(),
  };

  const onCardFormInputFocus = (_event, inputName) => {
    const refByName = cardElementsRef[inputName];
    setCurrentFocusedElm(refByName);
  };

  const onCardInputBlur = useCallback(() => {
    setCurrentFocusedElm(null);
  }, []);

  const ref = React.useRef();

  useEffect(() => {
    window.addEventListener('message', (message) => {
      // if (message.origin && message.origin.indexOf('boxpressd') !== -1) {
      //   console.debug('iFrame message:');
      //   console.debug(message);
      // }
      if (message.data.type === 'resize' && ref.current) {
        ref.current.style.height = `${message.data.height}px`;
      }
      if (
        message.data.type === 'payment_transaction' &&
        message.data.success === true
      ) {
        if (typeof onToken === 'function') {
          onToken(message.data.payment_token);
        }
      }
    });
  }, []);

  // const renderCardForm = () => (
  //   <CardForm
  //     cardMonth={creditCardState.cardMonth}
  //     cardYear={creditCardState.cardYear}
  //     onUpdateState={updateStateValues}
  //     cardNumberRef={formFieldsRefObj.cardNumber}
  //     cardHolderRef={formFieldsRefObj.cardHolder}
  //     cardDateRef={formFieldsRefObj.cardDate}
  //     onCardInputFocus={onCardFormInputFocus}
  //     onCardInputBlur={onCardInputBlur}
  //     price={price}
  //     lineItems={lineItems}
  //     useNMI
  //     onToken={(token) => {
  //       if (typeof onToken === 'function') {
  //         onToken(token);
  //       }
  //     }}
  //   >
  //     {!isMobile && (
  //       <CreditCard
  //         cardNumber={creditCardState.cardNumber}
  //         cardHolder={creditCardState.cardHolder}
  //         cardMonth={creditCardState.cardMonth}
  //         cardYear={creditCardState.cardYear}
  //         cardCvv={creditCardState.cardCvv}
  //         isCardFlipped={creditCardState.isCardFlipped}
  //         currentFocusedElm={currentFocusedElm}
  //         onCardElementClick={focusFormFieldByKey}
  //         cardNumberRef={cardElementsRef.cardNumber}
  //         cardHolderRef={cardElementsRef.cardHolder}
  //         cardDateRef={cardElementsRef.cardDate}
  //       />
  //     )}
  //   </CardForm>
  // );

  const renderCardForm = () => (
    <iframe
      ref={ref}
      src={`${config.shopEndPoint}/forms/payments.html?theme=${
        darkMode ? 'dark' : 'light'
      }&buttonText=Add%20Card&userId=${currentUser.id}&mode=${
        isMobile ? 'form' : 'card'
      }&action=addOnly`}
      style={{
        width: '100%',
        border: 'transparent',
        height: '100%',
      }}
    />
  );

  const renderFooter = () => (
    <div style={{ textAlign: 'center' }}>
      <hr />
      <div>
        <Icon name="lock" />
        {' Your payment methods are saved and stored securely.'}
      </div>
      <a href="https://boxpressd.com/legal/terms" target="_blank">
        Terms Apply
      </a>
    </div>
  );

  if (!isMobile) {
    return (
      <Modal isOpen={show} toggle={() => {}} style={{ maxWidth: 600 }} fade>
        <div className="modal-header">
          <h3>Add Payment Information</h3>
          <Button className="close" color="text" onClick={onClose}>
            <Icon name="x" />
          </Button>
        </div>
        <ModalBody>
          {renderCardForm()}
          {renderFooter()}
        </ModalBody>
        {/* <ModalFooter style={{ display: 'flex' }}> */}
        {/*  <Button color="secondary" onClick={onClose}>Cancel</Button> */}
        {/*  <Button id="authorize-payment-btn" color="primary">Continue</Button> */}
        {/* </ModalFooter> */}
      </Modal>
    );
  }
  return (
    <>
      {renderCardForm()}
      {renderFooter()}
    </>
  );
}

export default CreditCardModal;
