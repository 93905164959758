import Avatar from '@mui/material/Avatar';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Linkify from 'react-linkify';
import { Button, Spinner } from 'reactstrap';

import AudioCard from '@/components/AudioCard';
import FeedCardActions from '@/components/FeedCardActions';
import { getProfileImage } from '@/components/ProfileSelect';
import PromoteDialog from '@/components/PromoteDialog';
import RichCard from '@/components/RichCard';
import ShareOptionsModal from '@/components/ShareOptionsModal';
import ShareItem from '@/models/ShareItem';
import { config } from '@/settings';
import { AppStore, SocialStore, UserStore } from '@/stores';
import { renderTimestamp } from '@/utils/formatting';

const addOrUpdatePost = (episode, profileType, profileId) => {
  console.debug('Adding podcast episode to feed:');
  console.debug(episode);
  SocialStore.update((s) => {
    if (!s.profiles[`${profileType}s`]) {
      s.profiles[`${profileType}s`] = {};
    }
    if (!s.profiles[`${profileType}s`][profileId]) {
      s.profiles[`${profileType}s`][profileId] = {};
    }
    const profile =
      (s.profiles[`${profileType}s`] &&
        s.profiles[`${profileType}s`][profileId]) ||
      {};
    const posts = profile.posts || [];
    const foundPost = false;
    // TODO Iterate existing posts to see if this one exists
    if (foundPost) {
      // TODO Update/replace the post
    } else if (episode.url) {
      posts.unshift({
        type: 'podcast_episode',
        podcast_episode: episode,
      });
    } else {
      posts.unshift({
        type: 'business_post',
        business_post: episode,
      });
    }
    s.profiles[`${profileType}s`][profileId].posts = posts;
  });
};

function PodcastEpisodeCard({
  details,
  episode,
  preview,
  showActions,
  commentCount,
  likeCount,
  showComments,
  compact,
  hideStats,
  onCommentClick,
}) {
  const selectedProfile = AppStore.useState((s) => s.selectedProfile);
  const selectedProfileType = AppStore.useState((s) => s.selectedProfileType);
  const currentUser = UserStore.useState((s) => s.user);
  const title =
    (episode.itunes && episode.itunes.author) ||
    episode.author ||
    (details.image && details.image.title);
  const [impressions, setImpressions] = useState(null);
  const [clicks, setClicks] = useState(null);
  const [meta, setMeta] = useState(null);
  const [loadingMeta, setLoadingMeta] = useState(false);
  const [showShareOptions, setShowShareOptions] = useState(false);

  useEffect(() => {
    if (episode && episode.id) {
      if (!impressions) {
        axios
          .get(`${config.businessEndPoint}/analytics/impressions`, {
            params: {
              id: episode.id,
              type: 'podcast_episode',
            },
          })
          .then((response) => {
            console.debug('Analytics:');
            console.debug(response.data);
            setImpressions(response.data.total);
          })
          .catch((err) => {
            console.error(err);
          });
      }
      if (!clicks) {
        axios
          .get(`${config.businessEndPoint}/analytics/clicks`, {
            params: {
              id: episode.id,
              type: 'podcast_episode',
            },
          })
          .then((response) => {
            console.debug('Analytics:');
            console.debug(response.data);
            setClicks(response.data.total);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
    if (episode.link && episode.link.indexOf('youtube') !== -1) {
      setLoadingMeta(true);
      axios
        .get(`${config.mediaEndPoint}/meta`, {
          params: {
            url: episode.link,
          },
        })
        .then((response) => {
          setMeta(response.data);
          setLoadingMeta(false);
          console.log('Podcast Meta:', response.data);
        })
        .catch((err) => {
          console.error(err); // Do anything?
          setLoadingMeta(false);
        });
    }
  }, [episode && episode.id]);

  const getDisplayName = () => {
    let name;
    const { venue, brand } = episode;
    const item = venue || brand;
    console.debug('Post:');
    console.debug(episode);
    if (item) {
      name = item.name;
    } else {
      name = selectedProfile.name;
    }
    return name;
  };

  const getDisplayImage = () => {
    let image;
    const { venue, brand } = episode;
    const item = venue || brand;
    if (
      (episode.external_url &&
        episode.external_url.indexOf('boxpressd') !== -1) ||
      (episode.link && episode.link.indexOf('boxpressd') !== -1)
    ) {
      image =
        'https://s3-us-west-2.amazonaws.com/anchor-generated-image-bank/production/podcast_uploaded400/1127061/1127061-1610332167987-f90a6a35533d.jpg';
    } else if (item) {
      image = item.profile_image_url || item.logo_image_url;
    } else {
      image =
        getProfileImage(selectedProfile.profile_image_url) ||
        getProfileImage(selectedProfile.logo_image_url);
    }
    return image;
  };

  const renderShareOptionsSheet = () => {
    const episodePost =
      episode.link?.indexOf('youtube') !== -1
        ? {
            user_id: currentUser.id,
            content: `${meta?.ogDescription} ${episode.link}`.trim(),
            business_type: selectedProfileType.replace('cigar_', ''),
            [`${selectedProfileType.replace('cigar_', '')}_id`]:
              selectedProfile.id,
            [`${selectedProfileType.replace('cigar_', '')}`]: selectedProfile,
            timestamp: episode.pubDate,
            // INFO These are only used for the share link meta fields
            title: meta?.ogTitle,
            contentSnippet: meta?.ogDescription,
            itunes: {
              image: meta?.ogImage,
            },
          }
        : {
            user_id: currentUser.id,
            external_id: episode.guid,
            external_url: episode.link, // FIXME What if it's null
            author: title,
            author_image_url:
              (episode.itunes && episode.itunes.author) ||
              (details.image && details.image.url),
            title: episode.title,
            excerpt: episode.contentSnippet,
            image_url: episode.itunes && episode.itunes.image,
            audio_clip_url: episode.enclosure && episode.enclosure.url,
            timestamp: episode.isoDate,
            business_type: selectedProfileType.replace('cigar_', ''),
            [`${selectedProfileType.replace('cigar_', '')}_id`]:
              selectedProfile.id,
          };
    // FIXME It needs to be added to the server first - how can we get it?
    const postId = ''; // hashids.encode(post.id);
    const route =
      episode.link?.indexOf('youtube') !== -1
        ? `/${selectedProfileType}s/${selectedProfile.hash_id}?postId=${postId}`
        : '';
    const endpoint =
      episode.link?.indexOf('youtube') !== -1
        ? `${config.businessEndPoint}/posts`
        : `${config.businessEndPoint}/posts/podcasts`;
    return (
      <ShareOptionsModal
        url={episode.link}
        open={showShareOptions}
        onClose={() => setShowShareOptions(false)}
        data={
          new ShareItem({
            title: episode.title && episode.title.replace(/<[^>]*>?/gm, ''),
            text:
              episode.contentSnippet &&
              episode.contentSnippet.replace(/<[^>]*>?/gm, ''),
            route,
            url: episode.link,
            image: episode.itunes && episode.itunes.image,
            promote_endpoint: endpoint,
            item: episodePost,
            onPromoteSuccess: () => {
              addOrUpdatePost(
                episodePost,
                selectedProfileType,
                selectedProfile.id
              );
              setShowShareOptions(false);
            },
          })
        }
      />
    );
  };

  return (
    <div className="feed-card">
      <div className="feed-card-header">
        <Avatar
          src={getDisplayImage()}
          style={{
            height: 28,
            width: 28,
            margin: '8px 12px',
          }}
        >
          {getDisplayName().charAt(0)}
        </Avatar>
        <div style={{ flex: 1 }}>
          <div
            style={{
              fontWeight: 600,
              color: '#2b2b2b !important',
            }}
          >
            {getDisplayName()}
          </div>
          <div style={{ fontSize: 12 }}>
            {`Posted ${renderTimestamp(episode.isoDate || episode.timestamp)}`}
          </div>
        </div>
      </div>
      <div
        style={{
          minHeight: 120,
          float: 'none',
          display: 'inline',
        }}
      >
        {loadingMeta && !meta && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: 10,
            }}
          >
            <Spinner color="secondary" type="grow" />
            <span className="text-secondary">&nbsp;Loading...</span>
          </div>
        )}
        {!loadingMeta && meta && (
          <RichCard compact hideMeta={false} meta={meta}>
            {meta.ogDescription || ''}
          </RichCard>
        )}
        {/* FIXME This seems too slow - clone it to see if it can be sped up and also include loading skeleton view */}
        {((episode.enclosure && episode.enclosure.url) ||
          episode.audio_clip_url) && (
          <AudioCard
            title={episode.title}
            art={(episode.itunes && episode.itunes.image) || episode.image_url}
            source={
              (episode.enclosure && episode.enclosure.url) ||
              episode.audio_clip_url
            }
          />
        )}
      </div>
      <div style={{ padding: 6, fontSize: 14 }}>
        {/* FIXME This isn't a good way to handle this - malicious users can inject tracking code or worse - I think we can just linkify URLs instead */}
        {/* <div dangerouslySetInnerHTML={{ __html: episode['content:encoded'] }} /> */}
        <Linkify>{episode.contentSnippet || episode.excerpt}</Linkify>
        <div className="clearfix" />
        {!preview && !hideStats && (
          <div style={{ display: 'flex', padding: 10, float: 'left' }}>
            <span style={{ marginRight: 16 }}>
              <span style={{ fontSize: 14, fontWeight: 700 }}>
                {impressions || '-'}
              </span>
              <p>Impressions</p>
            </span>
            <span>
              <span style={{ fontSize: 14, fontWeight: 700 }}>
                {clicks || '-'}
              </span>
              <p>Clicks</p>
            </span>
          </div>
        )}
        {!preview && (
          <div style={{ display: 'flex', padding: 10, float: 'right' }}>
            {!showActions && (
              <Button
                color="text"
                style={{ marginRight: 10 }}
                onClick={() => {
                  // FIXME Need a way to know if they already shared it so this can't happen more than once - they can
                  //  effectively keep boosting their episode if we leave it as is unless the server responds with "already posted".
                  // TODO I think that's the best way to handle this - check server-side for a feed item matching
                  //  the details of this episode type and only add if not already added. This ensures that it doesn't
                  //  get added back to the top. Should definitely respond with 304 or similar so we can say something like
                  //  "this episode already exists, would you like to promote it to the top of the feed?"
                  setShowShareOptions(true);
                }}
              >
                {'Share'}
              </Button>
            )}
            <Button
              color="brand"
              onClick={() =>
                PromoteDialog.show({
                  post: episode,
                  postType: 'podcast_episode',
                  podcastDetails: details,
                })
              }
            >
              {'Promote'}
            </Button>
          </div>
        )}
        <div className="clearfix" />
        {showActions && (
          <FeedCardActions
            episodeId={episode.id}
            commentType="business_episode"
            commentRoute="episodes"
            commentCount={commentCount}
            likeCount={likeCount}
            showComments={showComments}
            commentOnClick={
              compact
                ? () => {
                    if (typeof onCommentClick === 'function') {
                      onCommentClick();
                    }
                  }
                : null
            }
            onLikedToggle={(liked) => {
              console.debug(`The episode is ${liked ? 'liked' : 'not liked'}`);
            }}
            onepisodeComment={(comment) => {
              console.debug('Comment posted...');
              console.debug(comment);
            }}
            onShare={() => setShowShareOptions(true)}
          />
        )}
      </div>
      {renderShareOptionsSheet()}
    </div>
  );
}

export default PodcastEpisodeCard;
