import axios from 'axios';
import { useCallback } from 'react';

import useDialogAlert from '@/components/ModalDialog';

const usePromoteUtils = () => {
  const { showDialogAlert } = useDialogAlert();

  const post = useCallback(
    (url, data, onSuccess, onError) => {
      showDialogAlert({
        title: 'Post to public feed',
        message:
          'Would you like to post this to your public feed on Boxpressd?',
        buttons: [
          {
            label: 'No, cancel',
            color: 'text',
            onClick: () => console.log('Cancelled'),
          },
          {
            label: 'Yes, post now',
            color: 'brand',
            onClick: () => {
              axios
                .post(url, data)
                .then(() => {
                  if (typeof onSuccess === 'function') {
                    onSuccess();
                  }
                })
                .catch((err) => {
                  console.error(err);
                  if (typeof onError === 'function') {
                    onError();
                  }
                });
            },
          },
        ],
      });
    },
    [showDialogAlert]
  );

  return { showPromoteUtils: post };
};

export default usePromoteUtils;
