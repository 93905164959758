import { useCallback } from 'react';

import useDialogAlert from '@/components/ModalDialog';

const useEditorDiscardConfirmation = () => {
  const { showDialogAlert } = useDialogAlert();

  const post = useCallback(
    ({ title, message, onClick }) => {
      showDialogAlert({
        title: title || 'Discard Post',
        message: message || 'Are you sure you want to discard this post?',
        buttons: [
          {
            label: 'No, Cancel',
            color: 'text',
          },
          {
            label: 'Yes, Discard',
            style: { color: 'rgb(239, 81, 100)' },
            color: 'danger',
            onClick: () => {
              if (typeof onClick === 'function') {
                onClick();
              }
            },
          },
        ],
      });
    },
    [showDialogAlert]
  );

  return { showEditorDiscardConfirmation: post };
};

export default useEditorDiscardConfirmation;
