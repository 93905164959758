import FsLightbox from 'fslightbox-react';
import React from 'react';

import { AppStore } from '@/stores';

const Lightbox = () => {
  const media = AppStore.useState((s) => s.lightbox.media);
  const slide = AppStore.useState((s) => s.lightbox.slide);
  const type = AppStore.useState((s) => s.lightbox.type);
  const toggleLightbox = AppStore.useState((s) => s.toggleLightbox);

  return (
    <FsLightbox
      toggler={toggleLightbox}
      sources={media.map((m) => m.src)}
      slide={slide}
      type={type || 'image'}
      types={media.map((m) => m.type)}
    />
  );
};

export const openLightboxOnSlide = (
  media,
  type: 'image' | 'video',
  number: number
) => {
  console.log('Showing lightbox on slide', media, type, number);
  AppStore.update((s) => {
    s.lightbox = {
      slide: number,
      media,
      type,
    };
    s.toggleLightbox = !s.toggleLightbox;
  });
};

export default Lightbox;
