import './style.scss';

import React from 'react';

const UrlPreview = (props) => (
  <div className="og-container">
    {props.ogImage && props.ogImage.url ? (
      <div
        className="border og-image-wrapper"
        style={{ backgroundImage: `url(${props.ogImage.url}` }}
      />
    ) : null}
    <div className="og-info">
      <span className="og-site-name">{props.ogUrl}</span>
      <span className="og-title">{props.ogTitle}</span>
      <span className="og-description">{props.ogDescription}</span>
    </div>
  </div>
);

export default UrlPreview;
