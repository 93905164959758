import './style.scss';

import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import React, { createRef, useEffect, useRef, useState } from 'react';
// import { Mousewheel } from 'swiper/modules';
// import { Swiper, SwiperSlide } from 'swiper/react';

import { isIOS, isMobile } from 'mobile-device-detect';
import useSizeMode, { sizeObj } from './hooks/size';
import { SettingsStore } from '@/stores';
import Icon from '@/components/Icon';

const ReelsComponent = ({
  reels,
  initialIndex = 0,
  reelMetaInfo,
  onLikeClicked,
  onCommentClicked,
  onShareClicked,
  onAvatarClicked,
}) => {
  const darkMode = SettingsStore.useState((s) => s.darkMode);

  /* Assigning the size mode according to screen (Custom Hook) */
  const sizeMode = useSizeMode();

  /* Collecting all the Video Element References from the DOM */
  const videoElementRefs = useRef(
    reels.map(() => createRef())
  );

  /* State Variables */
  const [currentVideoElementRef, setCurrentVideoElementRef] = useState(
    videoElementRefs.current[initialIndex]
  ); /* Ref. of Current Playing Video Element */
  const [isPlayingVideo, setIsPlayingVideo] = useState(true); /* Video is playing or not */
  const [isAudioOn, setIsAudioOn] = useState(false); /* Audio is on or not */
  const [reelData, setReelData] = useState(
    reels.map((reel) => ({
      id: reel.id,
      likes: {
        isTap: false,
        count: reel.total_likes,
      },
    }))
  );

  /* Life Cycle Hook (In this case runs only once after load) */
  useEffect(() => {
    /* Playing the First video */
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isPlayingVideo
      ? currentVideoElementRef?.current?.play()
      : currentVideoElementRef?.current?.pause();

    /* Pausing the rest of videos */
    const otherVideoElementRefs = videoElementRefs.current.filter(
      (_, index) => index !== initialIndex
    );
    otherVideoElementRefs.forEach((ref) => {
      ref.current?.pause();
    });
  }, []); // Dependency array should be blank in order to run useEffect only once

  /**
   * @param event
   * Runs after Every Swipe of Video
   */
  const handleSlideChange = (event) => {
    /* Current Video Ref. */
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const currentVideoElementRef = videoElementRefs.current.find(
      (_, index) => index === event.realIndex
    );
    /* Other Video Refs. */
    const otherVideoElementRefs = videoElementRefs.current.filter(
      (_, index) => index !== event.realIndex
    );

    /* Set the ref. of Current Video and required booleans */
    if (currentVideoElementRef) {
      setCurrentVideoElementRef(currentVideoElementRef);
      setIsPlayingVideo(true);
    }

    /* Play the current Video */
    currentVideoElementRef?.current?.play();

    /* Pausing other videos */
    otherVideoElementRefs.forEach((ref) => {
      ref.current?.pause();
      // @ts-ignore
      ref.current.currentTime = 0;
    });
  };

  /**
   * Responsible for playing and pausing current video by clicking the play/pause button
   * @param isPlaying
   */
  const handlePlayPauseVideo = (isPlaying) => {
    if (isPlaying) {
      setIsPlayingVideo(true);
      currentVideoElementRef?.current?.play();
    } else {
      setIsPlayingVideo(false);
      currentVideoElementRef?.current?.pause();
    }
  };

  /**
   * Responsible for playing and pausing the current video by clicking on the video itself
   */
  const handleClickOnVideo = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isPlayingVideo ? handlePlayPauseVideo(false) : handlePlayPauseVideo(true);
  };

  /**
   * Responsible for on/off audio by clicking the Audio on/off button
   * @param event
   * @param isOn
   */
  const handleAudio = (event, isOn) => {
    /* Preventing the click event to propagate to video element */
    event.stopPropagation();
    const otherVideoElementRefs = videoElementRefs.current.filter(
      (_, index) => index !== event.realIndex
    );
    if (isOn) {
      setIsAudioOn(true);
      currentVideoElementRef.current.muted = false;
    } else {
      setIsAudioOn(false);
      currentVideoElementRef.current.muted = true;
    }

    otherVideoElementRefs.forEach((ref) => {
      ref.current.muted = !isOn;
    });
  };

  /**
   * Fire when like icon is clicked
   * @param reel
   */
  const handleLikeClick = (reel) => {
    const reelDataDeepCopy = [...reelData];

    reelDataDeepCopy.forEach((_reel) => {
      if (_reel.id === reel.id) {
        if (!_reel.likes.isTap) {
          _reel.likes.count += 1;
          _reel.likes.isTap = true;
          // if (_reel.dislikes.isTap) {
          //   _reel.dislikes.isTap = false;
          //   _reel.dislikes.count -= 1;
          // }
        } else {
          _reel.likes.count -= 1;
          _reel.likes.isTap = false;
        }
      }
    });

    setReelData(reelDataDeepCopy);

    if (onLikeClicked) onLikeClicked(reel);
  };

  /**
   * @deprecated
   * Fire When dislike icon is clicked
   * @param reel
   */
  // const handleDislikeClick = (reel) => {
  //   const reelDataDeepCopy = [...reelData];
  //
  //   reelDataDeepCopy.forEach((_reel: any) => {
  //     if (_reel.id === reel.id) {
  //       if (!_reel.dislikes.isTap) {
  //         _reel.dislikes.count += 1;
  //         _reel.dislikes.isTap = true;
  //         if (_reel.likes.isTap) {
  //           _reel.likes.isTap = false;
  //           _reel.likes.count -= 1;
  //         }
  //       } else {
  //         _reel.dislikes.count -= 1;
  //         _reel.dislikes.isTap = false;
  //       }
  //     }
  //   });
  //
  //   setReelData(reelDataDeepCopy);
  //
  //   if (onDislikeClicked) onDislikeClicked(reel);
  // };

  /**
   * Fire when comment icon is clicked
   * @param reel
   */
  const handleCommentClick = (reel) => {
    if (onCommentClicked) onCommentClicked(reel);
  };

  /**
   * Fire when share icon is clicked
   * @param reel
   */
  const handleShareClick = (reel) => {
    if (onShareClicked) onShareClicked(reel);
  };

  /**
   * Fire when the Avatar is clicked
   * @param reel
   */
  const handleAvatarClicked = (reel) => {
    if (onAvatarClicked) onAvatarClicked(reel);
  };

  return (
    <>
      <div
        className="swiper"
        style={{
          // height: '100vh',
          backgroundColor: darkMode ? '#121212' : '#ffffff',
        }}
        // direction="vertical"
        // mousewheel
        // initialSlide={initialIndex || 0}
        // spaceBetween={10}
        // modules={[Mousewheel]}
        // slidesPerView={sizeMode === sizeObj.extraSmallScreen ? 1 : 1.1}
        // onSlideChange={(event) => handleSlideChange(event)}
      >
        {reels.map((reel, index) => (
          <div className="swiper-slide" key={reel.id}>
            {/* Background of the Reels */}
            <div
              style={{
                backgroundColor: darkMode ? '#121212' : '#ffffff',
              }}
              className="background"
            >
              {/* Wrapper of the video element */}
              <div
                style={{
                  height:
                      sizeMode === sizeObj.extraSmallScreen
                        ? '100%'
                        : `${reelMetaInfo?.videoDimensions?.height}px`
                          || '580px',
                  width:
                      sizeMode === sizeObj.extraSmallScreen
                        ? '100%'
                        : `${reelMetaInfo?.videoDimensions?.width}px`
                          || '330px',
                  borderRadius:
                      sizeMode === sizeObj.extraSmallScreen
                        ? '0px'
                        : `${reelMetaInfo?.borderRadius}px` || '10px',
                }}
                className="videoWrapper"
              >
                {reel.media_type === 'video' && (
                  <>
                    {
                      /* Play/Pause buttons (Only for Desktop Screens) */
                      sizeMode !== sizeObj.extraSmallScreen && (isPlayingVideo ? (
                        <Icon
                          name="pause"
                          size={25}
                          className="pauseIcon"
                          onClick={() => handlePlayPauseVideo(false)}
                        />
                      ) : (
                        <Icon
                          name="play"
                          size={27}
                          className="playIcon"
                          onClick={() => handlePlayPauseVideo(true)}
                        />
                      ))
                      /* Play/Pause buttons (Only for Desktop Screens) */
                    }

                    {
                      /* Audio On/Off buttons (Only for Desktop Screens) */
                      isAudioOn ? (
                        <Icon
                          name="volume2"
                          size={27}
                          className="speakerOnIcon"
                          onClick={(e) => handleAudio(e, false)}
                        />
                      ) : (
                        <Icon
                          name="volume-x"
                          size={27}
                          className="speakerOffIcon"
                          onClick={(e) => handleAudio(e, true)}
                        />
                      )
                      /* Audio On/Off buttons (Only for Desktop Screens) */
                    }

                    {
                      /* Middle play/pause Icon */
                      isPlayingVideo ? (
                        <Icon
                          name="play"
                          size={55}
                          className="bigPlayIcon"
                        />
                      ) : (
                        <Icon
                          name="pause"
                          size={50}
                          className="bigPauseIcon"
                        />
                      )
                      /* Middle play/pause Icon */
                    }
                  </>
                )}

                {reel.media_type === 'video' && (
                  <video
                    style={{
                      borderRadius:
                          sizeMode === sizeObj.extraSmallScreen
                            ? '0px'
                            : `${reelMetaInfo?.borderRadius}px` || '10px',
                    }}
                    className="video"
                    ref={videoElementRefs.current[index]}
                    controls={false} // Default control options off
                    muted // audio off by default
                    autoPlay // autoplay video is on by default
                    playsInline // needed for Safari browser
                    loop // starting the video again when ended
                    onClick={handleClickOnVideo}
                  >
                    <source src={reel.media_url} type="video/mp4" />
                  </video>
                )}

                {reel.media_type === 'image' && (
                  <img
                    alt=""
                    style={{
                      borderRadius:
                          sizeMode === sizeObj.extraSmallScreen
                            ? '0px'
                            : `${reelMetaInfo?.borderRadius}px` || '10px',
                    }}
                    className="video"
                    src={reel.media_url}
                  />
                )}

                {/* Right Side Options */}
                <div
                  className={
                    sizeMode === sizeObj.extraSmallScreen
                      ? 'sideBarIconsForSmallScreen'
                      : 'sideBarIcons'
                  }
                >
                  {/* Like Icon */}
                  <div onClick={() => handleLikeClick(reel)}>
                    {/* TODO Use our custom thumbs up SVG */}
                    <Icon
                      name="thumbs-up"
                      size={25}
                      color={
                        // eslint-disable-next-line no-nested-ternary
                        reelData[index].likes.isTap
                          ? reelMetaInfo?.likeActiveColor || '#3da6ff'
                          : isMobile || darkMode
                            ? '#ffffff'
                            : '#2b2b2b'
                      }
                    />
                    <span className="likeText">
                      {'Like'}
                    </span>
                  </div>
                  {/* Like Icon */}

                  {/* Comment Icon */}
                  <div onClick={() => handleCommentClick(reel)}>
                    <Icon
                      name="message-circle"
                      size={25}
                      style={{
                        color: isMobile || darkMode ? '#ffffff' : '#2b2b2b',
                      }}
                    />
                    <span className="commentText">
                      {'Comment'}
                    </span>
                  </div>
                  {/* Comment Icon */}

                  {/* Share Icon */}
                  <div onClick={() => handleShareClick(reel)}>
                    <Icon
                      name={isIOS ? 'share' : 'share2'}
                      size={25}
                      style={{
                        color: isMobile || darkMode ? '#ffffff' : '#2b2b2b',
                      }}
                    />
                    <span className="shareText">
                      {'Share'}
                    </span>
                  </div>
                  {/* Share Icon */}

                  {/* Three Dot Menu Icon */}
                  {/* FIXME Make this part of the upper component, not the reels individually - pass the current reel in */}
                  {reel.rightMenu ? (
                    <div
                      onClick={() => {
                        // TODO Show BottomSheet with rightMenu as {children}
                      }}
                    >
                      <Icon
                        name="more-horizontal"
                        size={25}
                        color="#ffffff"
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {/* Three Dot Menu Icon */}
                </div>
                {/* Right Side Options */}

                {/* Bottom Side */}
                <div className="bottomBar">
                  {/* FIXME Linkify this - it should be okay for users to post URLs in the text. Also mentions and similar? */}
                  {reel.description && (
                    <div>
                      {/* TODO Max height with read more / collapse */}
                      <label style={{ width: '70%', margin: 12 }}>
                        {reel.description}
                      </label>
                    </div>
                  )}

                  {reel.posted_by && (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div style={{ alignItems: 'center', display: 'flex' }}>
                        <img
                          src={reel.posted_by.image_url}
                          alt="profile-image"
                          onClick={() => handleAvatarClicked(reel)}
                        />
                        <label onClick={() => handleAvatarClicked(reel)}>
                          {reel.posted_by.name}
                        </label>
                      </div>
                    </div>
                  )}

                  {(reel.sponsored
                      || (reel.external_link_url
                        && reel.external_link_url.length > 0)
                      || (reel.tags && reel.tags.length > 0)) && (
                    <div
                        className="simple-carousel"
                        style={{ marginTop: 8, maxWidth: '100vw' }}
                      >
                        {reel.sponsored && (
                        <div
                          onClick={() => {
                            // TODO Show BottomSheet with sponsored/ad terms and options
                          }}
                        >
                          <Chip
                            label="Sponsored"
                            style={{
                              height: 28,
                              color: '#efefef',
                              backgroundColor: 'rgba(0, 0, 0, 0.4)',
                              fontSize: 12,
                              marginLeft: 0,
                              marginRight: 16,
                            }}
                          />
                        </div>
                      )}
                        {reel.external_link_url && reel.external_link_url.length > 0 && (
                        <Chip
                          icon={<Icon name="link" size={20} />}
                          // FIXME Only show the domain part of the URL, remove the path and protocol
                          label={reel.external_link_url}
                          style={{
                            height: 28,
                            color: '#efefef',
                            backgroundColor: 'rgba(0, 0, 0, 0.4)',
                            fontSize: 12,
                            marginLeft: 16,
                            marginRight: 16,
                          }}
                          onClick={() => {
                            // TODO Track click
                            window.open(reel.external_link_url, '_blank', 'noopener');
                          }}
                        />
                      )}
                        {reel.tags && reel.tags.map((tag, i) => (
                        <Chip
                            key={i}
                            icon={(
                            <Avatar
                                alt={tag.title}
                                src={tag.image_url}
                                style={{ height: 20, width: 20 }}
                              />
                          )}
                            label={tag.title}
                            style={{
                            height: 28,
                            color: '#efefef',
                            backgroundColor: 'rgba(0, 0, 0, 0.4)',
                            fontSize: 12,
                            marginLeft:
                                  index === 0
                                  && (!reel.external_link_url
                                    || reel.external_link_url.length === 0)
                                    ? 0
                                    : 16,
                            marginRight: 16,
                          }}
                          // TODO Track click
                            onClick={() => {
                            // TODO
                          }}
                          />
                      ))}
                      </div>
                  )}
                </div>
                {/* Bottom Side */}
              </div>
              {/* Wrapper of the video element */}
            </div>
            {/* Background of the Reels */}
          </div>
        ))}
      </div>
    </>
  );
};

export default ReelsComponent;
