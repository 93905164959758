import './style.scss';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ReactPlayer from 'react-player';

class VideoSource extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showPlaceholder: true,
    };
    this.play = this.play.bind(this);
  }

  // FIXME This is a hack to get the video thumbnail to work for BXPR-333 - still doesn't show quite fast enough
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.videoSrc !== this.props.videoSrc) {
      this.setState({ force: true });
    }
  }

  play() {
    this.setState({ showPlaceholder: false });
  }

  videoUrl(videoSrc) {
    const video = this.src;
    if (videoSrc) {
      if (videoSrc instanceof File || videoSrc instanceof Blob) {
        if (typeof window !== 'undefined') {
          const URL = window.URL || window.webkitURL;
          return URL.createObjectURL(videoSrc);
        }
      }
      return `${videoSrc}#t=0.1`;
    }
    return null;
  }

  generateThumbnail() {
    const { videoSrc, thumbnailTime } = this.props;
    // console.debug('Video Src:');
    // console.debug(videoSrc);
    if (videoSrc) {
      if (videoSrc instanceof File || videoSrc instanceof Blob) {
        return this.videoUrl(videoSrc);
      }
      return `${videoSrc}#t=${thumbnailTime}`;
    }
    return null;
  }

  renderPlaceholder() {
    const { placeholder, scaleWidth, scaleHeight } = this.props;
    console.log(`Video height: ${scaleHeight}, Width: ${scaleWidth}`);
    if (!placeholder) {
      return (
        <div
          className={`video video-${this.props.objectFit}`}
          style={{
            width: scaleWidth,
            height: scaleHeight,
            backgroundColor: '#000000',
          }}
          onClick={this.props.onClick || this.play}
        >
          <video width={scaleWidth} height={scaleHeight} preload="metadata">
            <source src={this.generateThumbnail()} type="video/mp4" />
          </video>
        </div>
      );
    }
    /* eslint-disable */
    return (
      <div className="video" style={{width: scaleWidth, height: scaleHeight, backgroundImage: 'url(' + placeholder + ')', backgroundColor: '#000000'}} onClick={this.play} />
    );
    /* eslint-disable */
  }

  render() {
    if (this.state.showPlaceholder) {
      return this.renderPlaceholder();
    }

    return (
      <ReactPlayer
        className={`video-${this.props.objectFit}`}
        url={this.videoUrl(this.props.videoSrc)}
        playing
        controls={this.props.controls}
        playsinline={!this.props.fullScreen}
        width={this.props.scaleWidth ? this.props.scaleWidth : '310'}
        height={this.props.scaleHeight ? this.props.scaleHeight : '207'}
        style={{ backgroundColor: '#000000' }}
        // onStart={() => {
        //   if (isIOS) {
        //     vid.webkitEnterFullscreen();
        //   }
        // }}
      />
    );
  }
}

VideoSource.propTypes = {
  videoSrc: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  scaleWidth: PropTypes.string,
  scaleHeight: PropTypes.string,
  fullScreen: PropTypes.bool,
  controls: PropTypes.bool,
  objectFit: PropTypes.string,
  thumbnailTime: PropTypes.number,
};

VideoSource.defaultProps = {
  placeholder: null,
  // scaleWidth: 608,
  scaleWidth: typeof window !== 'undefined' && window.screen.width,
  // FIXME Include isMobile here? It would be in the functional component itself, not here
  // scaleHeight: isMobile ? 'auto' : 342,
  fullScreen: false,
  controls: true,
  objectFit: 'contain',
  thumbnailTime: 0.1,
};

export default VideoSource;
