import './style.scss';

import { isMobile } from 'mobile-device-detect';
import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import useDialogAlert from '@/components/ModalDialog';
import { config } from '@/settings';
import { UserStore } from '@/stores';
import { getBusinessClaims } from '@/utils/api/businessClaims';
import { updateAuth } from '@/utils/authentication';
import {
  maybeUpdateCurrentUser,
  unableToAuthenticate,
} from '@/utils/axiosAuthHelper';
import { isStandalone } from '@/utils/useStandalone';

const urlParams = new URLSearchParams(window.location.search);
const env = process.env.NODE_ENV || 'development';

const redirect = () => {
  console.log('Redirecting to auth...');
  const authUrl = `${config.authEndPoint}/login?path=${
    window.location.href.split('?')[0]
  }`;
  window.location.replace(authUrl);
  return null;
};

const Loading = () => (
  <div className="loader-wrapper">
    <div className="loader">
      <div className="line" />
      <div className="line" />
      <div className="line" />
      <div className="line" />
      <h4>
        {"'Bout that time..."}
        <span>
          <img
            src="https://cdn.boxpressd.io/placeholder/boxpressd-liked.png"
            height={32}
          />
        </span>
      </h4>
    </div>
  </div>
);

// INFO For now, this isn't being used - see app.js for handling using onEnter prop
const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();
  const { showDialogAlert } = useDialogAlert();
  const currentUser = UserStore.useState((s) => s.user);
  const token = UserStore.useState((s) => s.token);
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);

  const startOnboarding = () => {
    window.location.href = `${config.appEndPoint}/businesses/claim?token=${token}`;
  };

  const checkAuth = () =>
    new Promise((resolve, reject) => {
      console.log('Checking for user token...');

      if (currentUser) {
        resolve(true);
      } else {
        maybeUpdateCurrentUser()
          .then(({ user, tokenId }) => {
            try {
              updateAuth(user, tokenId, () => {
                console.log('User SSO successful');
                resolve(true);
              });
            } catch (err) {
              console.error(err);
              alert('Error updating auth (updateAuth failed)...');
              reject();
            }
          })
          .catch((err) => {
            console.error(err);
            console.log('No auth token to use, redirecting...');
            reject();
          });
      }
    });

  useEffect(() => {
    checkAuth()
      .then(() => {
        console.log('Authenticated, updating state...');
        setAuthenticated(true); // This line needs to fire before loading changes
        setLoading(false);
        if (urlParams.get('token')) {
          urlParams.delete('token');
          navigate({
            pathname: window.location.pathname,
            search: urlParams.toString(),
          });
        }
      })
      .catch((err) => {
        if (err && err.message === unableToAuthenticate) {
          showDialogAlert({
            title: 'Unable to authenticate',
            message:
              'There was a problem with authentication. We are working on a fix, but if the problem persists, please contact support.',
            onClose: redirect,
          });
        } else {
          setLoading(false);
          setAuthenticated(false);
        }
      });
  }, []);

  useEffect(() => {
    if (currentUser) {
      if (
        currentUser.account_type !== 'business' &&
        currentUser.account_type !== 'admin'
      ) {
        startOnboarding();
      } else {
        console.log('Getting claims for user...');
        getBusinessClaims(currentUser.id);
      }
    }
  }, [currentUser]);

  // if (!isStandalone && isMobile && env !== 'development') {
  //   return <Navigate to="/install" />;
  // }

  if (loading) {
    return <Loading />;
  }
  if (!loading && !authenticated) {
    return redirect();
  }
  if (!loading && authenticated) {
    return <>{children}</>;
  }
  return null;
};

export default PrivateRoute;
