import './style.scss';

import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';
import Hashids from 'hashids';
import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Row, Spinner } from 'reactstrap';

import { useActionSheet } from '@/components/ActionSheet';
import Carousel from '@/components/Carousel';
import CreateAdModal from '@/components/CreateAdModal';
import FeedCardActions from '@/components/FeedCardActions';
import Icon from '@/components/Icon';
import MediaGrid from '@/components/MediaGrid';
import useDialogAlert from '@/components/ModalDialog';
import { getProfileImage } from '@/components/ProfileSelect';
import PromoteDialog from '@/components/PromoteDialog';
import RichCard from '@/components/RichCard';
import ShareOptionsModal from '@/components/ShareOptionsModal';
import VideoSource from '@/components/VideoSource';
import ShareItem from '@/models/ShareItem';
import { config } from '@/settings';
import { AppStore, SocialStore, UserStore } from '@/stores';
import { adFormats } from '@/utils/adFormats';
import { renderTimestamp } from '@/utils/formatting';
import { Resize } from '@/utils/imageUtils';
import ShareIntent from '@/utils/shareUtils';

const hashids = new Hashids('', 12);

// FIXME All of these cards should extend from a top level one that handles all the common things like saving items
//  Or just rename this to something generic - the only difference is probably who created it (a brand, venue, or group)
//  so we can include title, subtitle, avatar, and link instead
const BusinessPostCardList = ({
  post = {},
  type,
  posts,
  preview = false,
  compact = false,
  commentCount,
  likeCount,
  showActions,
  showStats,
  showPromotions,
  onMeta,
  onCommentClick,
}) => {
  const currentUser = UserStore.useState((s) => s.user);
  const selectedProfile = AppStore.useState((s) => s.selectedProfile);
  const selectedProfileType = AppStore.useState((s) => s.selectedProfileType);

  const { showActionSheet } = useActionSheet();
  const { showDialogAlert } = useDialogAlert();
  const [impressions, setImpressions] = useState(null);
  const [clicks, setClicks] = useState(null);
  const [meta, setMeta] = useState(null);
  const [loadingMeta, setLoadingMeta] = useState(false);
  const [showShareOptions, setShowShareOptions] = useState(false);

  useEffect(() => {
    if (post.id) {
      axios
        .get(`${config.apiEndPoint}/analytics/impressions`, {
          params: {
            id: post.id,
            type: 'business_post',
          },
        })
        .then((response) => {
          console.debug('Analytics:');
          console.debug(response.data);
          setImpressions(response.data.total);
        })
        .catch((err) => {
          console.error(err);
        });

      axios
        .get(`${config.apiEndPoint}/analytics/clicks`, {
          params: {
            id: post.id,
            type: 'business_post',
          },
        })
        .then((response) => {
          console.debug('Analytics:');
          console.debug(response.data);
          setClicks(response.data.total);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [post.id]);

  useEffect(() => {
    const { content, meta: postMeta } = post;
    if (!postMeta) {
      const urlPattern =
        /([a-zA-Z0-9]+:\/\/)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\.[A-Za-z]{2,4})(:[0-9]+)?(\/.*)?/;
      if (urlPattern.test(content)) {
        setLoadingMeta(true);
        const url = content.match(/(https?:\/\/[^ ]*)/)[1];
        axios
          .get(`${config.mediaEndPoint}/meta`, {
            params: {
              url,
            },
          })
          .then((response) => {
            setMeta(response.data);
            setLoadingMeta(false);
            if (typeof onMeta === 'function') {
              onMeta(response.data);
            }
          })
          .catch((err) => {
            console.log(
              `Failed to get meta data for ${
                content.match(/(https?:\/\/[^ ]*)/)[1]
              }`
            );
            console.log(err); // Do anything?
            setLoadingMeta(false);
          });
      }
    }
  }, [post.content, post.meta]);

  // moreOptions = [{
  //   content: 'Save for later',
  //   icon: 'bookmark',
  //   value: 'save',
  //   onClick: () => {
  //     const { post } = props;
  //     const { auth } = props;
  //     const { user } = auth;
  //     saveItem(user, {
  //       singular: 'venue_post',
  //       plural: 'venue_posts',
  //     }, post);
  //     addToast({
  //       content: (<>
  //         {'Saved post'}
  //       </>),
  //       duration: 6000,
  //     });
  //   },
  // }];

  const renderShareOptionsSheet = () => {
    // TODO Build the object or just pass it in?
    return (
      <ShareOptionsModal
        feed={false}
        // TODO What should this be? Link to the post directly? Comments view?
        url={''}
        open={showShareOptions}
        onClose={() => setShowShareOptions(false)}
        data={
          // FIXME
          new ShareItem({
            // title: review.product.name,
            // text: `View my latest review of ${review.product.name} on Boxpressd`,
            // path: post.business_type,
            // route: `/${post.business_type}s/${hashids.encode(
            //   post[post.business_type].id
            // )}?postId=${hashids.encode(post.id)}`,
            // image: reviewImage(),
            promote_endpoint: `${config.businessEndPoint}/posts/podcasts`,
            item: post,
            onPromoteSuccess: () => {
              // FIXME
              // addOrUpdatePost(
              //   post,
              //   selectedProfileType,
              //   selectedProfile.id
              // );
            },
          })
        }
      />
    );
  };

  const getFeaturedImage = () => {
    let imageURL = null; // TODO Default image
    if (post.image) {
      imageURL = post.image;
    } else if (post.featured_image) {
      imageURL = post.featured_image.url;
    } else if (post.images && post.images.length) {
      imageURL = post.images[0];
    }
    // FIXME For now, this isn't working as expected - seems like an issue with resizing
    // if (typeof config.CDNUrl !== 'undefined' && config.CDNUrl !== '') {
    //   imageURL = imageURL.replace(config.wpUrl, config.CDNUrl);
    // }

    return imageURL;
  };

  // toggleSavedItem = (save) => {
  //   let { post, auth } = props;
  //   const { user } = auth;
  //   post = JSON.parse(JSON.stringify(post));
  //   console.log('Toggling saved post for state:');
  //   if (save) {
  //     console.log('save');
  //     saveItem(user, { singular: 'venue_post', plural: 'venue_posts' }, post);
  //   } else {
  //     console.log('unsave');
  //     unsaveItem(user, post);
  //   }
  // };

  const renderHeader = () => {
    const { venue, brand } = post;
    const item = venue || brand;
    const date = post.timestamp;
    return (
      <div style={{ display: 'flex', padding: 5 }}>
        <Avatar
          src={
            item &&
            (getProfileImage(item.profile_image_url) ||
              getProfileImage(item.logo_image_url))
          }
          style={{ height: 28, width: 28, margin: '8px 12px 12px 0' }}
        >
          {item && item.name && item.name.charAt(0)}
        </Avatar>
        <div style={{ flex: 1 }}>
          <div style={{ fontWeight: 600, color: '#2b2b2b !important' }}>
            {item ? item.name : ''}
          </div>
          <div style={{ fontSize: 12 }}>
            {`Posted ${renderTimestamp(date)}`}
          </div>
        </div>
        {!preview && (
          <IconButton
            edge="end"
            color="inherit"
            aria-label="More Options"
            style={{ marginRight: 5, marginTop: -16 }}
            onClick={() => {
              // FIXME Should they be allowed to edit after they promote them? FB Doesn't allow because of the verification
              //  process but since we're not reviewing posts, do we care if they edit them? Right now, I think the answer is no
              showActionSheet({
                items: [
                  {
                    icon: <Icon name="edit" />,
                    content: 'Edit Post',
                    onClick: () => {
                      // alert('Coming soon!');
                      CreateAdModal.show({
                        post,
                        onClose: () => CreateAdModal.close(),
                      });
                    },
                  },
                  {
                    icon: <Icon name="trash" />,
                    content: 'Delete Post',
                    onClick: () => {
                      showDialogAlert({
                        title: 'Delete Post',
                        message: 'Are you sure you want to delete this post?',
                        buttons: [
                          {
                            label: 'No, cancel',
                            color: 'text',
                          },
                          {
                            label: 'Yes, delete',
                            color: 'danger',
                            variant: 'contained',
                            onClick: async () => {
                              SocialStore.update((s) => {
                                const allPosts = [
                                  ...s.profiles[`${selectedProfileType}s`][
                                    selectedProfile.id
                                  ].posts,
                                ];
                                for (let i = 0; i < allPosts.length; i++) {
                                  console.debug(
                                    `Testing ${
                                      allPosts[i].business_post_id
                                    } against ${parseInt(post.id)}`
                                  );
                                  console.debug(allPosts[i]);
                                  console.debug(post);
                                  if (
                                    parseInt(allPosts[i].business_post_id) ===
                                    parseInt(post.id)
                                  ) {
                                    console.debug('Found! Removing!');
                                    allPosts.splice(i, 1);
                                    break;
                                  }
                                }
                                s.profiles[`${selectedProfileType}s`][
                                  selectedProfile.id
                                ].posts = allPosts;
                              });
                              await axios.delete(
                                `${config.businessEndPoint}/posts/${post.id}`
                              );
                            },
                          },
                        ],
                      });
                    },
                  },
                ],
              });
            }}
          >
            <Icon name="more-horizontal" style={{ height: 22, width: 22 }} />
          </IconButton>
        )}
      </div>
    );
  };

  const renderInnerMedia = () => {
    if (type === adFormats[1] || (post.cards && post.cards.length)) {
      if (!posts) {
        posts = [...post.cards]
          .sort((a, b) => a.id - b.id)
          .map((card) => ({
            ...card,
            mediaUrl: card.src || card.media_url || card.imageUrl,
            mediaType: card.type || card.media_type,
          }));
      }
      console.debug('Carousel cards:');
      console.debug(posts);
      return (
        <Carousel slidesToShow={1} slidesToScroll={1} style={{ width: '100%' }}>
          {posts.map(({ src, headline, description, mediaUrl, mediaType }) => (
            <div>
              <Card style={{ width: 250, margin: 4 }}>
                {(mediaType === 'video' ||
                  (mediaUrl && mediaUrl.indexOf('.mp4') !== -1)) && (
                  <div style={{ height: 250 }}>
                    <VideoSource
                      videoSrc={src || mediaUrl}
                      scaleWidth="100%"
                      scaleHeight="100%"
                      // placeholder={mediaUrl.replace('.mp4', '.jpg')}
                      title={post.title}
                      // thumbnailTime={video_timestamp_start || 0.1}
                    />
                  </div>
                )}
                {mediaType === 'image' && (
                  <img
                    alt=""
                    src={
                      src ||
                      mediaUrl ||
                      'https://cdn.boxpressd.io/placeholder/500x500/no_cigar.png'
                    }
                    style={{
                      height: 250,
                      objectFit: 'cover',
                    }}
                  />
                )}
                <div style={{ margin: 16 }}>
                  <p
                    style={{
                      marginTop: 6,
                      marginBottom: 4,
                      fontSize: 14,
                      fontWeight: 600,
                    }}
                  >
                    {headline}
                  </p>
                  <RichCard
                    compact
                    hideMeta={post.media && post.media.length > 0}
                  >
                    {description || ''}
                  </RichCard>
                </div>
              </Card>
            </div>
          ))}
        </Carousel>
      );
    }
    if (post.media && post.media.length) {
      if (
        post.media[0].media_type === 'video' ||
        post.media[0].media_url.indexOf('.mp4') !== -1
      ) {
        return (
          <div style={{ margin: '10px 0' }}>
            <VideoSource
              videoSrc={post.media[0].media_url}
              scaleWidth="100%"
              scaleHeight="100%"
              // placeholder={post.image}
              title={post.title}
              thumbnailTime={post.media[0].video_timestamp_start || 0.1}
            />
          </div>
        );
      }
      return (
        <Container>
          <Row>
            <MediaGrid
              media={post.media.map((media) => ({
                type: media.media_type,
                src: media.media_url,
              }))}
              direction="vertical"
              // direction={compact ? 'horizontal' : 'vertical'}
              lazyImages={post.media.map((media) => ({
                type: media.media_type,
                src:
                  media.media_type !== 'video'
                    ? Resize.size(media.media_url, {
                        height: 100,
                        width: 100,
                        cropType: 'crop',
                      })
                    : media.media_url,
              }))}
              thumbnailMedia={post.media.map((media) => ({
                type: media.media_type,
                src:
                  media.media_type !== 'video'
                    ? Resize.size(media.media_url)
                    : media.media_url,
              }))}
              onImageClick={() => {
                // TODO?
              }}
            />
          </Row>
        </Container>
      );
    }
    // if (post.media && post.media.length) {
    //   return (
    //     <MediaGrid
    //       media={post.media.map((media) => ({ src: media.media_url }))}
    //       thumbnailMedia={post.media.map((media) => ({
    //         src:
    //           media.media_url &&
    //           media.media_url
    //             .replace('/posts/', '/posts/500x500/')
    //             .replace('/businesses/', '/businesses/500x500/'),
    //       }))}
    //     />
    //   );
    // }
    return null;
  };

  const renderMedia = () => (
    <div className="image">
      {renderHeader(selectedProfile, selectedProfileType)}
      {renderInnerMedia()}
    </div>
  );

  return (
    <div className="feed-card-item">
      {renderMedia()}
      <div className="content" style={{ marginBottom: 0 }}>
        <div className="content-right" style={{ width: '100%', paddingTop: 1 }}>
          {loadingMeta && !meta && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: 10,
              }}
            >
              <Spinner color="secondary" type="grow" />
              <span className="text-secondary">&nbsp;Loading...</span>
            </div>
          )}
          <RichCard
            compact
            hideMeta={post.media && post.media.length > 0}
            meta={
              post.meta && {
                ogTitle: post.meta.title,
                ogDescription: post.meta.description,
                ogUrl: post.meta.domain,
                requestUrl: post.meta.url,
                ogImage: {
                  url: post.meta.image_url,
                },
                ogVideo: {
                  url: post.meta.video_url,
                },
              }
            }
          >
            {post.content || ''}
          </RichCard>
        </div>
        {preview && (
          <FeedCardActions
            commentCount={98}
            commentOnClick={() => {}}
            likeCount={247}
            onLikedToggle={() => {}}
            onPostComment={() => {}}
            onShare={() => {}}
            showComments={false}
            preview
          />
        )}
        {showStats && (
          <div style={{ display: 'flex', padding: 10, float: 'left' }}>
            <span style={{ marginRight: 16 }}>
              <span style={{ fontSize: 14, fontWeight: 700 }}>
                {impressions || '-'}
              </span>
              <p>Impressions</p>
            </span>
            <span>
              <span style={{ fontSize: 14, fontWeight: 700 }}>
                {clicks || '-'}
              </span>
              <p>Clicks</p>
            </span>
          </div>
        )}
        {showPromotions && (
          <div style={{ display: 'flex', padding: 10, float: 'right' }}>
            {!showActions && (
              <Button
                color="text"
                style={{ marginRight: 10 }}
                onClick={() => {
                  ShareIntent.share(
                    new ShareItem({
                      title:
                        post.title &&
                        post.title.rendered.replace(/<[^>]*>?/gm, ''),
                      text: post.intro && post.intro.replace(/<[^>]*>?/gm, ''),
                      // FIXME Should this file handle all business types? If so (I think so), change the file name
                      path: selectedProfileType,
                      route: `/${selectedProfileType}s/${hashids.encode(
                        post.id
                      )}`,
                      // url: selectedProfileType === 'brand' && [424, 989].indexOf(selectedProfile.id) !== -1 ? undefined : post.link,
                      image: getFeaturedImage(),
                    })
                  );
                }}
              >
                {'Share'}
              </Button>
            )}
            <Button
              color="brand"
              onClick={() => {
                PromoteDialog.show({
                  post,
                  postType: selectedProfileType,
                });
              }}
            >
              {'Promote'}
            </Button>
          </div>
        )}
      </div>
      <div className="clearfix" />
      {!preview && (
        <FeedCardActions
          postId={post.id}
          commentType="business_post"
          commentRoute="posts"
          commentCount={commentCount}
          likeCount={likeCount}
          commentOnClick={
            compact
              ? () => {
                  if (typeof onCommentClick === 'function') {
                    onCommentClick();
                  }
                }
              : null
          }
          onLikedToggle={(liked) => {
            console.debug(`The post is ${liked ? 'liked' : 'not liked'}`);
          }}
          onPostComment={(comment, parentId) => {
            console.debug('Comment posted...');
            console.debug(comment);
            axios.post(
              `${config.apiEndPoint}/businesses/posts/${post.id}/comments`,
              {
                business_post_id: post.id,
                // FIXME We don't want any HTML past this point - convert to plain text - use something better than this approach?
                comment: comment.replace('<br>', ''),
                user_id: currentUser.id, // FIXME We need to be able to allow the brand/venue to post as the business
                [`${selectedProfileType.replace('cigar_', '')}_id`]:
                  selectedProfile.id,
                parent_id: parentId,
                comment_timestamp: new Date().toISOString(),
              }
            );
          }}
          onShare={() => setShowShareOptions(true)}
        />
      )}
      {renderShareOptionsSheet()}
    </div>
  );
};

export default BusinessPostCardList;
