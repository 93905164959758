import './style.scss';

import classnames from 'classnames/dedupe';
import csv from 'csvtojson';
import React, { PureComponent } from 'react';
import Dropzone from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';

import Icon from '@/components/Icon';
import { uploadToS3 } from '@/utils/imageUtils';

function fileSizeToHumanReadable(size) {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  return `${(size / 1024 ** i).toFixed(2) * 1} ${
    ['B', 'kB', 'MB', 'GB', 'TB'][i]
  }`;
}

class ComponentDropzone extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
    };

    this.progressBar = React.createRef();

    this.onChange = this.onChange.bind(this);
    this.removeFile = this.removeFile.bind(this);
  }

  onChange() {
    const { onChange = () => {} } = this.props;

    onChange(this.state.files);
  }

  onProgress = (progress) => {
    if (this.progressBar && this.progressBar.current) {
      this.progressBar.current.style.width = `${progress}%`;
    }
  };

  removeFile(file) {
    const newFiles = [...this.state.files];

    newFiles.splice(newFiles.indexOf(file), 1);

    this.setState(
      {
        files: newFiles,
      },
      this.onChange
    );
  }

  render() {
    const { onAdded = () => {} } = this.props;

    return (
      <Dropzone
        accept={
          this.props.accept || {
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png'],
            'image/webp': ['.webp'],
          }
        }
        onDrop={(newFiles) => {
          this.setState(
            {
              files: [...this.state.files, ...newFiles],
            },
            this.onChange
          );

          console.debug('Dropped files:');
          console.debug(newFiles);

          newFiles.forEach((file) => {
            console.log(file);
            if (this.props.uploadToS3) {
              // if (this.props.bucket && this.props.path) {
              if (this.props.path) {
                const uuid = uuidv4();
                const filename = `${uuid}-${file.name}`;
                console.debug('Uploading ', filename);
                uploadToS3(
                  file,
                  this.props.path,
                  filename,
                  uuid,
                  null,
                  this.onProgress
                )
                  .then((res) => {
                    onAdded(res.data);
                    if (this.props.removeOnAdd) {
                      this.removeFile(file);
                    }
                  })
                  .catch((err) => {
                    console.error('Error uploading media to server...');
                    console.error(err);
                    // Sentry.captureException(err);
                  });
              } else {
                // console.error('Dropzone error: Please specify the `bucket` and `path` in props.');
                console.error(
                  'Dropzone error: Please specify the `path` in props.'
                );
              }
            }
            if (file.type === 'text/csv') {
              const reader = new FileReader();

              reader.onload = () => {
                const fileAsBinaryString = reader.result;

                csv({
                  noheader: true,
                  output: 'json',
                })
                  .fromString(fileAsBinaryString)
                  .then((csvRows) => {
                    const toJson = [];
                    csvRows.forEach((aCsvRow, i) => {
                      if (i !== 0) {
                        const builtObject = {};

                        Object.keys(aCsvRow).forEach((aKey) => {
                          const valueToAddInBuiltObject = aCsvRow[aKey];
                          const keyToAddInBuiltObject = csvRows[0][aKey];
                          builtObject[keyToAddInBuiltObject] =
                            valueToAddInBuiltObject;
                        });

                        toJson.push(builtObject);
                      }
                    });

                    const { getJson } = this.props;
                    getJson(toJson);
                  });
              };

              reader.onabort = () => console.log('File reading was aborted');
              reader.onerror = () => console.log('File reading has failed');

              reader.readAsText(file, 'ISO-8859-1');
            }
          });
        }}
        // getJson={this.props.getJson}
      >
        {(data) => {
          const rootProps = data.getRootProps({
            onClick: this.props.onClick
              ? (e) => {
                  e.stopPropagation();
                  this.props.onClick(e);
                }
              : undefined,
          });
          const inputProps = data.getInputProps();

          return (
            <div
              className={classnames(
                'rui-dropzone',
                data.isDragActive ? 'rui-dropzone-is-active' : ''
              )}
              {...rootProps}
            >
              <input {...inputProps} />
              {this.state.files && this.state.files.length ? (
                this.state.files.map((fileData) => {
                  // const fileExt = fileData.name.split('.').pop();
                  console.log('fileData:');
                  console.log(fileData);

                  return (
                    <div key={fileData.name} className="rui-dropzone-preview">
                      <img data-dz-thumbnail="true" />
                      <div className="rui-dropzone-details">
                        <div className="rui-dropzone-size">
                          <span>{fileSizeToHumanReadable(fileData.size)}</span>
                        </div>
                        <div className="rui-dropzone-filename">
                          <span>{fileData.name}</span>
                        </div>
                        <div className="progress">
                          <div
                            ref={this.progressBar}
                            className="progress-bar progress-bar-primary"
                            role="progressbar"
                            data-dz-uploadprogress
                          >
                            <span className="progress-text" />
                          </div>
                        </div>
                      </div>
                      <button
                        className="rui-dropzone-remove"
                        onClick={(e) => {
                          e.stopPropagation();
                          this.removeFile(fileData);
                        }}
                      >
                        <Icon name="x" />
                      </button>
                    </div>
                  );
                })
              ) : (
                <div className="rui-dropzone-message">
                  <span className="rui-dropzone-icon">
                    <Icon name="upload-cloud" />
                  </span>
                  <span className="rui-dropzone-text">
                    {!this.props.hideDropText &&
                      (this.props.dropText ||
                        'Drop file here or click to upload.')}
                  </span>
                </div>
              )}
            </div>
          );
        }}
      </Dropzone>
    );
  }
}

export default ComponentDropzone;
