import './style.scss';

import Avatar from '@mui/material/Avatar';
import MaterialButton from '@mui/material/Button';
import axios from 'axios';
import { parse } from 'date-fns';
import Hashids from 'hashids';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import FeedCardActions from '@/components/FeedCardActions';
import { getProfileImage } from '@/components/ProfileSelect';
import PromoteDialog from '@/components/PromoteDialog';
import ShareOptionsModal from '@/components/ShareOptionsModal';
import { config } from '@/settings';
import { AppStore, SocialStore, UserStore } from '@/stores';
import { renderTimestamp } from '@/utils/formatting';
import { Resize } from '@/utils/imageUtils';

import ShareItem from '../../models/ShareItem';
import VideoSource from '../VideoSource';
import { Button } from 'reactstrap';

const hashids = new Hashids('', 12);

const addOrUpdatePost = (article, profileType, profileId) => {
  console.debug('Adding blog article to feed:');
  console.debug(article);
  SocialStore.update((s) => {
    if (!s.profiles[`${profileType}s`]) {
      s.profiles[`${profileType}s`] = {};
    }
    if (!s.profiles[`${profileType}s`][profileId]) {
      s.profiles[`${profileType}s`][profileId] = {};
    }
    const profile =
      (s.profiles[`${profileType}s`] &&
        s.profiles[`${profileType}s`][profileId]) ||
      {};
    const posts = profile.posts || [];
    const foundPost = false;
    // TODO Iterate existing posts to see if this one exists
    if (foundPost) {
      // TODO Update/replace the post
    } else {
      posts.unshift({
        type: 'blog_article',
        blog_article: article,
      });
    }
    s.profiles[`${profileType}s`][profileId].posts = posts;
  });
};

// FIXME All of these cards should extend from a top level one that handles all the common things like saving items
function BlogArticleCard(props) {
  const selectedProfile = AppStore.useState((s) => s.selectedProfile);
  const selectedProfileType = AppStore.useState((s) => s.selectedProfileType);
  const currentUser = UserStore.useState((s) => s.user);
  const [impressions, setImpressions] = useState(null);
  const [meta, setMeta] = useState(null);
  const [clicks, setClicks] = useState(null);
  const [showShareOptions, setShowShareOptions] = useState(false);

  const post = { ...props.item };

  useEffect(() => {
    if (post && (post.link || post.external_url)) {
      axios
        .get(`${config.mediaEndPoint}/meta`, {
          params: {
            url: post.link || post.external_url,
            removeUserAgent: true, // FIXME This should depend on the URL but typically should be false or omitted
          },
        })
        .then((response) => {
          setMeta(response.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
    if (post && post.id) {
      if (!impressions) {
        axios
          .get(`${config.businessEndPoint}/analytics/impressions`, {
            params: {
              id: post.id,
              type: 'blog_post',
            },
          })
          .then((response) => {
            console.debug('Analytics:');
            console.debug(response.data);
            setImpressions(response.data.total);
          })
          .catch((err) => {
            console.error(err);
          });
      }
      if (!clicks) {
        axios
          .get(`${config.businessEndPoint}/analytics/clicks`, {
            params: {
              id: post.id,
              type: 'blog_post',
            },
          })
          .then((response) => {
            console.debug('Analytics:');
            console.debug(response.data);
            setClicks(response.data.total);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  }, [post && post.id]);

  const getDisplayImage = () => {
    let image;
    const { venue, brand } = post;
    const item = venue || brand;
    if (item) {
      image = item.profile_image_url || item.logo_image_url;
    } else {
      image =
        getProfileImage(selectedProfile.profile_image_url) ||
        getProfileImage(selectedProfile.logo_image_url);
    }
    return image;
  };

  const getFeaturedImage = () => {
    console.debug('Getting media...');
    console.debug(props.item);
    console.debug(props.item.content);
    let imageURL = null; // 'https://cdn.boxpressd.io/placeholder/500x500/no_cigar.png';
    if (
      props.item.yoast_head_json &&
      props.item.yoast_head_json.og_image &&
      props.item.yoast_head_json.og_image.url
    ) {
      imageURL = props.item.yoast_head_json.og_image.url;
    } else if (props.item.jetpack_featured_media_url) {
      imageURL = props.item.jetpack_featured_media_url;
    } else if (props.item.image_url) {
      imageURL = props.item.image_url;
    } else if (props.item.feature_image) {
      imageURL = props.item.feature_image;
    } else if (
      props.item._embedded &&
      props.item._embedded['wp:featuredmedia']
    ) {
      const media = props.item._embedded['wp:featuredmedia'][0];
      console.debug('Medium media:');
      console.debug(media.media_details);
      // FIXME Cigar Dojo's images don't all work for some reason...
      if (media.media_details && media.media_details.sizes) {
        if (media.media_details.sizes.medium_large) {
          imageURL = media.media_details.sizes.medium_large.source_url;
        } else if (media.media_details.sizes.medium) {
          imageURL = media.media_details.sizes.medium.source_url;
        }
      } else if (media.source_url) {
        imageURL = media.source_url;
      }
    } else if (
      props.item['media:thumbnail'] &&
      props.item['media:thumbnail'].$ &&
      props.item['media:thumbnail'].$.url
    ) {
      imageURL = props.item['media:thumbnail'].$.url.replace('s72-', ''); // FIXME Not an ideal solution but temp fix for http://smokinronniesbbq.blogspot.com/
    } else if (props.item.enclosure) {
      console.debug(props.item.enclosure);
      if (props.item.enclosure.type.indexOf('image') !== -1) {
        imageURL = props.item.enclosure.url;
      }
    } else if (
      props.item.content &&
      typeof props.item.content === 'string' &&
      props.item.content.indexOf('<img') !== -1
    ) {
      console.debug('Found image in text!');
      console.debug(props.item.content);
      const re = /<img[^>]+src="(https?:\/\/[^">]+)"/g;
      const results = re.exec(props.item.content);
      if (results && results.length) {
        imageURL = results[1];
      }
    }
    return imageURL;
  };

  const renderHeader = () => {
    if (post.pubDate) {
      if (post.pubDate.indexOf('+') !== -1) {
        // Sun, 07 Aug 2022 16:46:31 +0000
        console.debug('Timestamp:');
        console.debug(post.pubDate.split(', ')[1].split(' +')[0]);
        post.date = parse(
          post.pubDate.split(', ')[1].split(' +')[0],
          'dd MMM yyyy HH:mm:ss',
          new Date()
        ).toISOString();
      } else if (post.pubDate.indexOf(',') !== -1) {
        // FIXME Better way to deal with timezone?
        post.date = parse(
          post.pubDate.split(', ')[1].replace(' GMT', '').trim(),
          'dd MMM yyyy HH:mm:ss',
          new Date()
        ).toISOString();
      }
    }
    const date = post.date || post.timestamp;
    return (
      <div className="feed-card-header">
        <Avatar
          src={getDisplayImage()}
          style={{ height: 28, width: 28, margin: '8px 12px' }}
        >
          {selectedProfile && selectedProfile.name.charAt(0)}
        </Avatar>
        <div style={{ flex: 1 }}>
          <div style={{ fontWeight: 600, color: '#2b2b2b !important' }}>
            {selectedProfile.name}
          </div>
          <div style={{ fontSize: 12 }}>{`Posted ${renderTimestamp(
            date
          )}`}</div>
        </div>
      </div>
    );
  };

  const renderInnerMedia = () => {
    const post = props.item;
    console.debug('Post for media:');
    console.debug(post);
    const image = getFeaturedImage() || (meta && meta.image_url);
    let imageURL;
    if (image) {
      imageURL = `url(${Resize.size(image, {
        width: 500,
        height: 'auto',
        cropType: 'crop',
      })})`;
    }
    console.debug('Medium media:');
    console.debug(imageURL);
    if (props.item.videoId) {
      return (
        <VideoSource
          videoSrc={post.videoUrl}
          // scaleWidth={608}
          scaleHeight={244}
          placeholder={post.image}
          title={post.title}
        />
      );
    }
    if (imageURL) {
      return (
        <a href={post.link || post.external_url} target="_blank">
          <div
            className="img"
            style={{
              backgroundImage: imageURL,
              paddingTop: '70%',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
        </a>
      );
    }
    return null;
  };

  const renderMedia = () => (
    <div className="image">
      {renderHeader()}
      {renderInnerMedia()}
    </div>
  );

  // console.log('Got blog post');
  // console.log(post);

  if (!post.excerpt && post.content) {
    // FIXME This is actually post.excerpt.rendered
    if (typeof post.content === 'string') {
      post.excerpt = `${post.content.substring(0, 200)}...`;
    } else {
      post.excerpt = `${post.content.rendered.substring(0, 200)}...`;
    }
  }

  if (!post.intro && post.content) {
    // FIXME This is actually post.intro.rendered
    if (typeof post.content === 'string') {
      post.intro = `${post.content.substring(0, 320)}...`;
    } else {
      post.intro = `${post.content.rendered.substring(0, 320)}...`;
    }
  }

  if (!post.intro && post.excerpt) {
    post.intro = post.excerpt;
  }

  if (post.excerpt) {
    if (typeof post.excerpt === 'string') {
      post.excerpt = `${post.excerpt.substring(0, 320)}...`;
    } else {
      post.excerpt = `${post.excerpt.rendered.substring(0, 320)}...`;
    }
  }

  if (post.title && post.title.rendered) {
    post.title = post.title.rendered;
  }

  const renderShareOptionsSheet = () => {
    const blogArticle = {
      user_id: currentUser.id,
      external_id: post.id,
      external_url: post.link,
      author: selectedProfile.name,
      author_image_url: getDisplayImage(),
      title: post.title,
      excerpt: post.excerpt,
      image_url: getFeaturedImage(),
      timestamp: post.date || post.timestamp,
      business_type: selectedProfileType.replace('cigar_', ''),
      [`${selectedProfileType.replace('cigar_', '')}_id`]: selectedProfile.id,
    };
    return (
      <ShareOptionsModal
        url={post.link}
        open={showShareOptions}
        onClose={() => setShowShareOptions(false)}
        data={
          new ShareItem({
            title: post.title && post.title.replace(/<[^>]*>?/gm, ''),
            text: post.intro && post.intro.replace(/<[^>]*>?/gm, ''),
            // FIXME I'd assume for these they should all be external links unless they are our own?
            path: 'article',
            route:
              selectedProfileType === 'brand' &&
              [424, 989].indexOf(selectedProfile.id) !== -1
                ? `/articles/${hashids.encode(post.id)}`
                : `/articles/${hashids.encode(post.id)}/comments`,
            url:
              selectedProfileType === 'brand' &&
              [424, 989].indexOf(selectedProfile.id) !== -1
                ? undefined
                : post.link,
            image: getFeaturedImage(),
            promote_endpoint: `${config.businessEndPoint}/posts/blogs`,
            item: blogArticle,
            onPromoteSuccess: () => {
              addOrUpdatePost(
                blogArticle,
                selectedProfileType,
                selectedProfile.id
              );
            },
          })
        }
      />
    );
  };

  return (
    <div className="article-card-item feed-card">
      {renderMedia()}
      <div className="content">
        <div className="content-right" style={{ width: '100%', paddingTop: 1 }}>
          <a href={post.link} target="_blank">
            <h3 dangerouslySetInnerHTML={{ __html: post.title }} />
          </a>
          <div
            className="intro"
            dangerouslySetInnerHTML={{ __html: post.intro }}
          />
        </div>
        {!props.preview && !props.hideStats && (
          <div style={{ display: 'flex', padding: 10, float: 'left' }}>
            <span style={{ marginRight: 16 }}>
              <span style={{ fontSize: 14, fontWeight: 700 }}>
                {impressions || '-'}
              </span>
              <p>Impressions</p>
            </span>
            <span>
              <span style={{ fontSize: 14, fontWeight: 700 }}>
                {clicks || '-'}
              </span>
              <p>Clicks</p>
            </span>
          </div>
        )}
        {!props.preview && (
          <div style={{ display: 'flex', padding: 10, float: 'right' }}>
            {!props.showActions && (
              <Button
                color="text"
                style={{ marginRight: 10 }}
                onClick={() => {
                  setShowShareOptions(true);
                  // TODO I think that's the best way to handle this - check server-side for a feed item matching
                  //  the details of this post type and only add if not already added. This ensures that it doesn't
                  //  get added back to the top. Should definitely respond with 304 or similar so we can say something like
                  //  "this post already exists, would you like to promote it to the top of the feed?" to upsell them
                }}
              >
                {'Share'}
              </Button>
            )}
            <Button
              color="brand"
              onClick={() =>
                PromoteDialog.show({ post, postType: 'blog_article' })
              }
            >
              {'Promote'}
            </Button>
          </div>
        )}
        <div className="clearfix" />
        {props.showActions && (
          <FeedCardActions
            postId={post.id}
            commentType="business_post"
            commentRoute="posts"
            commentCount={props.commentCount}
            likeCount={props.likeCount}
            showComments={props.showComments}
            commentOnClick={
              props.compact
                ? () => {
                    if (typeof props.onCommentClick === 'function') {
                      props.onCommentClick();
                    }
                  }
                : null
            }
            onLikedToggle={(liked) => {
              console.debug(`The post is ${liked ? 'liked' : 'not liked'}`);
            }}
            onPostComment={(comment, parentId) => {
              console.debug('Comment posted...');
              console.debug(comment);
              axios.post(`${config.apiEndPoint}/articles/${post.id}/comments`, {
                article_id: post.id,
                // FIXME We don't want any HTML past this point - convert to plain text - use something better than this approach?
                comment: comment.replace('<br>', ''),
                user_id: props.auth.user.id, // FIXME We need to be able to allow the brand/venue to post as the business
                [`${selectedProfileType.replace('cigar_', '')}_id`]:
                  selectedProfile.id,
                parent_id: parentId,
                comment_timestamp: new Date().toISOString(),
              });
            }}
            onShare={() => setShowShareOptions(true)}
          />
        )}
      </div>
      {renderShareOptionsSheet()}
    </div>
  );
}

BlogArticleCard.propTypes = {
  item: PropTypes.object.isRequired,
  name: PropTypes.string,
  showComments: PropTypes.bool,
};

BlogArticleCard.defaultProps = {
  showComments: true,
};

export default BlogArticleCard;
