import './style.scss';

// import {IonDatetime, IonModal, IonRange} from '@ionic/react';
import { IonRange } from '@ionic/react';
import { Button as MaterialButton, FormHelperText } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { isMobile } from 'mobile-device-detect';
import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  Collapse,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalFooter,
  Spinner,
} from 'reactstrap';

import BlogArticleCard from '@/components/BlogArticleCard';
import CreditCardModal from '@/components/CreditCardModal';
import EventCard from '@/components/EventCard';
import PostCardList from '@/components/FeedBusinessPostCardList';
import FeedProductCard from '@/components/FeedProductCardList';
import Icon from '@/components/Icon';
import ModalView from '@/components/ModalView';
import PodcastEpisodeCard from '@/components/PodcastEpisodeCard';
import Post from '@/models/Post';
import Product from '@/models/Product';
import { config } from '@/settings';
import { AppStore, UserStore } from '@/stores';
import { CACHE_INTERVAL_LONG, useCache } from '@/utils/useCache';

const date = new Date();
let dateMonth = date.getMonth() + 1;
let dateDay = date.getDate();
let dateDayNext = date.getDate() + 1;

if (dateMonth < 10) {
  dateMonth = `0${dateMonth}`;
}
if (dateDay < 10) {
  dateDay = `0${dateDay}`;
}
if (dateDayNext < 10) {
  dateDayNext = `0${dateDayNext}`;
}
const dateToday = String(`${date.getFullYear()}-${dateMonth}-${dateDay}`);

const datePlusDays = (startDate, days) => {
  if (startDate) {
    const result = new Date(startDate);
    result.setDate(result.getDate() + days);
    return result.toISOString();
  }
  return null;
};

function PromoteDialog({ open, post, postType, podcastDetails }) {
  const selectedProfile = AppStore.useState((s) => s.selectedProfile);
  const selectedProfileType = AppStore.useState((s) => s.selectedProfileType);

  const currentUser = UserStore.useState((s) => s.user);
  const [selectedDuration, setSelectedDuration] = useState(5);
  const [selectedStartDate, setSelectedStartDate] = useState(
    new Date().toISOString()
  );
  const [selectedBudget, setSelectedBudget] = useState('30.00');
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  const [showCardForm, setShowCardForm] = useState(false);
  const [paymentProfiles, setPaymentProfiles] = useState([]);
  const [loadingPaymentProfiles, setLoadingPaymentProfiles] = useState(true);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [promoteData, setPromoteData] = useState(null);
  const [basicAdData, setBasicAdData] = useState(null);
  const [selectedPlacements, setSelectedPlacements] = useState({
    socialFeed: false,
    businessFeed: false,
    virtualLounge: false,
  });
  const [missingPlacement, setMissingPlacement] = useState(false);
  const [missingPaymentMethod, setMissingPaymentMethod] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const url = currentUser
    ? `${config.shopEndPoint}/payments/profiles?userId=${currentUser.id}`
    : null;
  const {
    data,
    isLoading: loadingPaymentMethods,
    error,
    invalidate,
  } = useCache(url, CACHE_INTERVAL_LONG);

  useEffect(() => {
    if (selectedDuration) {
      // TODO Update the end date accordingly
    }
  }, [selectedDuration]);

  useEffect(() => {
    // TODO Check their profile to see their active billing plan
    if (data) {
      setPaymentProfiles(data);
      setLoadingPaymentProfiles(false);
      if (Array.isArray(data)) {
        // Iterate to find the default, but it should ideally always be the first
        data.forEach((method) => {
          if (method.is_default) {
            setSelectedPaymentMethod(method.payment_profile_id);
          }
        });
      }
    }
  }, [data]);

  useEffect(() => {
    setLoadingPaymentProfiles(loadingPaymentMethods);
  }, [loadingPaymentMethods]);

  useEffect(() => {
    // Build the direct link with only the required fields - all other fields will be pulled on demand in the feed front-end
    console.log('Post to be promoted:');
    console.log(post);
    let postData = {};
    let basicData = {};
    if (postType === 'brand' || postType === 'venue') {
      // Business post
      postData = {
        type: 'business_post',
        business_post: {
          id: post.id,
          [postType]: {
            id: post[postType].id,
            name: post[postType].name,
            logo_image_url: post[postType].logo_image_url,
            profile_image_url: post[postType].profile_image_url,
          },
          business_type: post.business_type,
          // content: post.content, // FIXME Limit length
          media: post.media?.map((media) => ({
            media_type: media.media_type,
            media_url: media.media_url,
          })),
          cards: post.cards?.map((card) => ({
            media_url: card.media_url,
            headline: card.headline,
            description: card.description,
            url: card.url,
            media_type: card.media_type,
          })),
          timestamp: post.timestamp,
        },
      };
      // TODO How to handle this? Carousel won't work - just use first card?
      // basicData = {
      //   title: 'Vertical Groove Golf',
      //   message: 'VGG Driver - Changing The Face of Golf',
      //   image_url: 'https://verticalgroovegolf.com/cdn/shop/products/vertical-groove-driver-matte.jpg?v=1673369794',
      // };
    }
    if (postType === 'product') {
      postData = {
        type: 'product',
        product: {
          id: post.id,
          name: post.name,
          sale_price: post.sale_price,
          msrp: post.msrp,
          reference: post.reference,
          venue: {
            id: post.venue.id,
            name: post.venue.name,
            profile_image_url: post.venue.profile_image_url,
            website: post.venue.website,
            formatted_address: post.venue.formatted_address,
          },
          packaging: post.packaging,
          buy_url: post.buy_url,
          affiliate_url: post.affiliate_url,
          image_url: Product.getImage(post, false),
        },
      };
      basicData = {
        title: post.name,
        message: 'Tap to learn more',
        image_url: Product.getImage(post, false),
      };
    }
    if (postType === 'blog_article') {
      if (post.link || post.external_url) {
        // It's already in the right format
        postData = {
          type: 'blog_article',
          blog_article: {
            title: post.title,
            // excerpt: post.excerpt, // FIXME Limit length
            link: post.link || post.external_url,
            timestamp: post.timestamp,
            business_type: selectedProfileType,
            image_url: post.image_url,
            [selectedProfileType]: {
              id: selectedProfile.id,
              name: selectedProfile.name,
              profile_image_url: selectedProfile.profile_image_url,
              logo_image_url: selectedProfile.logo_image_url,
            },
          },
        };
        basicData = {
          title: post.title,
          message: 'Tap to learn more',
          image_url: post.image_url,
        };
      } else {
        // It's coming straight from WP and needs normalized
        const article = new Post(post);
        postData = {
          type: 'blog_article',
          blog_article: {
            title: article.title,
            // excerpt: post.excerpt, // FIXME Limit length
            link: article.link,
            date: article.date,
            business_type: selectedProfileType,
            image_url: article.image,
            [selectedProfileType]: {
              id: selectedProfile.id,
              name: selectedProfile.name,
              profile_image_url: selectedProfile.profile_image_url,
              logo_image_url: selectedProfile.logo_image_url,
            },
          },
        };
        basicData = {
          title: post.title,
          message: 'Tap to learn more',
          image_url: article.image,
        };
      }
    }
    if (postType === 'podcast_episode') {
      postData = {
        type: 'podcast_episode',
        podcast_episode: {
          title: post.title,
          // content: post.content, // FIXME Limit length
          timestamp: post.isoDate,
          enclosure: post.enclosure,
          audio_clip_url: post.enclosure?.url,
          image_url: post.itunes?.image,
          url: post.enclosure?.url,
        },
        business_type: selectedProfileType,
        [selectedProfileType]: {
          id: selectedProfile.id,
          name: selectedProfile.name,
          profile_image_url: selectedProfile.profile_image_url,
          logo_image_url: selectedProfile.logo_image_url,
        },
      };
      basicData = {
        title: post.title,
        message: 'Tap to listen',
        image_url: post.itunes?.image, // TODO Add play icon over top?
      };
    }
    console.log(postData);
    console.log(encodeURIComponent(JSON.stringify(postData)));
    setPromoteData(postData);
    setBasicAdData(basicData);
    // TODO If the user selects the Virtual Lounge as an option, also include in that format
  }, [post]);

  const promote = async () => {
    let validated = true;
    if (
      !selectedPlacements.socialFeed &&
      !selectedPlacements.businessFeed &&
      !selectedPlacements.virtualLounge
    ) {
      validated = false;
      setMissingPlacement(true);
    } else {
      setMissingPlacement(false);
    }
    if (!selectedPaymentMethod) {
      validated = false;
      setMissingPaymentMethod(true);
    } else {
      setMissingPaymentMethod(false);
    }

    if (validated) {
      setSubmitting(true);
      try {
        // FIXME Add back
        // const advertiserId = await createAdvertiser(
        //   selectedProfile.id,
        //   selectedProfileType,
        //   currentUser.email
        // );
        // const campaignId = await createCampaign(
        //   advertiserId,
        //   selectedStartDate,
        //   datePlusDays(selectedStartDate, selectedDuration),
        //   selectedBudget,
        //   parseFloat(selectedBudget) / selectedDuration
        // );
        //
        // const promises = [];
        //
        // const promoteAdPromise = createPromotedAd(campaignId, promoteData).then(
        //   (adId) => {
        //     const zones = Object.keys(selectedPlacements)
        //       .filter(
        //         (key) =>
        //           key !== 'virtualLounge' && selectedPlacements[key] === true
        //       )
        //       .map((key) => zoneIds[key]);
        //     return assignAdToZone(adId, zones);
        //   }
        // );
        // promises.push(promoteAdPromise);
        //
        // if (selectedPlacements.virtualLounge) {
        //   const virtualLoungeAdPromise = createPromotedAd(
        //     campaignId,
        //     basicAdData
        //   ).then((adId) => {
        //     return assignAdToZone(adId, [zoneIds.virtualLounge]);
        //   });
        //   promises.push(virtualLoungeAdPromise);
        // }
        //
        // Promise.all(promises).then(() => {
        //   setSubmitting(false);
        //   PromoteDialog.close();
        // });
      } catch (err) {
        console.log(err);
        setSubmitting(false);
        // Sentry.captureException(err);
        // FIXME We can probably add .show back but hooks seem to work just fine
        // ModalDialog.show({
        //   title: 'Unable to Promote Post',
        //   message:
        //     'There was an error promoting your post. Please try again later.',
        // });
      }
    }
  };

  const calculateValue = (value) => {
    console.debug('Calculating value:');
    console.debug(value);
    if (value < 11) {
      return value;
    }
    if (value < 15) {
      return value + (value % 2);
    }
    if (value < 30) {
      return value + (value % 5);
    }
    if (value < 100) {
      return value + (value % 10);
    }
    return value + (value % 100);
  };

  const renderContent = () => (
    <div style={{ padding: 16, marginBottom: isMobile ? 130 : 0 }}>
      <div className="row">
        <div className="col-md-7">
          <div
            className="card"
            style={{ margin: isMobile ? '0 -16px' : 'inherit', padding: 16 }}
          >
            <p style={{ fontWeight: 700 }}>Schedule & duration</p>
            <div
              style={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
              }}
            >
              <div
                style={{
                  flex: 1,
                  marginRight: 5,
                }}
              >
                <p style={{ fontWeight: 700, fontSize: 12 }}>Days</p>
                <input
                  className="form-control"
                  type="number"
                  value={selectedDuration}
                  onChange={(e) =>
                    setSelectedDuration(parseInt(e.target.value))
                  }
                />
              </div>
              <div
                style={{
                  flex: 1,
                  marginLeft: 5,
                }}
              >
                <p style={{ fontWeight: 700, fontSize: 12 }}>Start date</p>
                <input
                  className="form-control"
                  type="date"
                  value={selectedStartDate || `${dateToday}T12:00:00`}
                  onChange={(e) => {
                    setSelectedStartDate(e.target.value);
                  }}
                />
                {/* FIXME Use this instead? See NextJS - it's not pinning to the bottom or full width like it should be */}
                {/* <IonModal keepContentsMounted={true} isOpen> */}
                {/*  <IonDatetime id="datetime"></IonDatetime> */}
                {/* </IonModal> */}
              </div>
            </div>
            <div style={{ marginTop: 20 }}>
              <p style={{ fontWeight: 700 }}>Total budget</p>
              {/* TODO Add toggle to switch to daily budget? */}
              {/* TODO Estimated reach */}
              <InputGroup
                style={{
                  maxWidth: 200,
                  margin: 'auto',
                }}
              >
                {/* <InputGroupAddon addonType="prepend" className="input-group-amount">$</InputGroupAddon> */}
                <Input
                  // id="blog"
                  className="form-control"
                  value={`$${parseInt(selectedBudget).toFixed(0)}`}
                  onChange={(e) => {
                    // FIXME This doesn't work if trying to adjust the decimal
                    if (e.target.value && parseInt(e.target.value) > 0) {
                      setSelectedBudget(parseFloat(e.target.value).toFixed(2));
                    } else {
                      setSelectedBudget('0');
                    }
                  }}
                  autocorrect="off"
                  autocapitalize="none"
                  style={{
                    fontSize: '34px',
                    color: '#d3a966',
                    background: 'none',
                    border: 'none',
                    textAlign: 'center',
                  }}
                />
              </InputGroup>
              <IonRange
                min={1}
                max={1000}
                step={1}
                defaultValue={6}
                value={parseInt(selectedBudget)}
                onIonInput={(e) => {
                  setSelectedBudget(`${e.target.value}`);
                }}
                aria-labelledby="budget-range-slider"
              />
            </div>
            <div style={{ marginTop: 20 }}>
              {/* FIXME Maybe a toggle for business vs consumer? Ads will be better if they are segmented that way - only show placements if "audience" is not business */}
              <p style={{ fontWeight: 700 }}>Placements</p>
              <FormControl
                required
                error={missingPlacement}
                component="fieldset"
              >
                <FormLabel component="legend">
                  Select where this post will be promoted to
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedPlacements.socialFeed}
                        onChange={(e) => {
                          console.log(e);
                          setSelectedPlacements({
                            ...selectedPlacements,
                            socialFeed: e.target.checked,
                          });
                        }}
                        name="social"
                      />
                    }
                    label="Social Feed"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedPlacements.businessFeed}
                        onChange={(e) => {
                          setSelectedPlacements({
                            ...selectedPlacements,
                            businessFeed: e.target.checked,
                          });
                        }}
                        name="business"
                      />
                    }
                    label="Business Feed (B2B)"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedPlacements.virtualLounge}
                        onChange={(e) => {
                          setSelectedPlacements({
                            ...selectedPlacements,
                            virtualLounge: e.target.checked,
                          });
                        }}
                        name="lounge"
                      />
                    }
                    label="Virtual Lounge"
                  />
                </FormGroup>
                <FormHelperText>
                  Please select at least 1 placement
                </FormHelperText>
              </FormControl>
            </div>
          </div>
          <div
            className="card"
            style={{ margin: isMobile ? '10px -16px' : '10px 0' }}
          >
            <List>
              <ListItem
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  // TODO Show the credit card prompt popup from the main app
                }}
              >
                <ListItemAvatar>
                  <Avatar>
                    <div>
                      <Icon name="credit-card" />
                    </div>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={<p style={{ fontWeight: 700 }}>Payment Method</p>}
                />
              </ListItem>
              <div style={{ padding: '0 20px' }}>
                <FormControl
                  required
                  error={missingPaymentMethod}
                  component="fieldset"
                >
                  <FormLabel component="legend">
                    Choose payment method:
                  </FormLabel>
                  <RadioGroup
                    aria-label="status"
                    name="status"
                    value={selectedPaymentMethod}
                    onChange={(event) => {
                      setSelectedPaymentMethod(event.target.value);
                    }}
                  >
                    {paymentProfiles &&
                      paymentProfiles.map((method) => (
                        <FormControlLabel
                          value={method.card.token}
                          control={<Radio />}
                          label={
                            <div>
                              <Icon
                                name={[
                                  'fab',
                                  `cc-${method.card.type.toLowerCase()}`,
                                ]}
                                vendor="fa"
                                style={{ marginRight: 12 }}
                              />
                              <span>{method.card.number}</span>
                            </div>
                          }
                        />
                      ))}
                    <FormControlLabel
                      value="new"
                      control={<Radio />}
                      label="Add new credit card..."
                      onClick={() => setShowCardForm(true)}
                    />
                  </RadioGroup>
                  <FormHelperText>
                    Please select a valid payment method
                  </FormHelperText>
                </FormControl>
              </div>
              {isMobile && (
                <Collapse
                  isOpen={showCardForm}
                  className="navbar-collapse rui-navbar-collapse"
                >
                  <CreditCardModal />
                </Collapse>
              )}
              {!isMobile && (
                <CreditCardModal
                  show={showCardForm}
                  onClose={() => setShowCardForm(false)}
                />
              )}
            </List>
          </div>
          <div
            className="card"
            style={{
              margin: isMobile ? '0 -16px' : 'inherit',
              marginTop: 20,
              padding: 16,
            }}
          >
            {/* Summary */}
            <p style={{ fontWeight: 700 }}>Summary</p>
            <p>{`Your promotion will run for ${selectedDuration} days`}</p>
            <Alert color="success">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <div style={{ flex: 1 }}>Total Budget</div>
                <div style={{ textAlign: 'right' }}>{`$${parseFloat(
                  selectedBudget
                ).toFixed(2)}`}</div>
              </div>
            </Alert>
          </div>
        </div>
        <div className="col-md-5">
          {post && (
            <Card
              style={{
                margin: isMobile ? '10px -16px' : '10px 0',
                padding: 20,
                boxShadow: '0 2px 12px 1px hsla(0,0%,67.8%,.2)',
              }}
            >
              <p style={{ fontWeight: 700 }}>Promotion Preview</p>
              {(postType === 'venue' || postType === 'brand') && (
                <PostCardList
                  preview
                  post={post}
                  showComments={false}
                  promoted
                />
              )}
              {postType === 'product' && (
                <FeedProductCard
                  preview
                  item={post}
                  showComments={false}
                  promoted
                />
              )}
              {postType === 'podcast_episode' && (
                <PodcastEpisodeCard
                  preview
                  episode={post}
                  showComments={false}
                  details={podcastDetails}
                  promoted
                />
              )}
              {postType === 'blog_article' && (
                <BlogArticleCard
                  preview
                  item={post}
                  showComments={false}
                  promoted
                  hideStats
                />
              )}
              {postType === 'event' && (
                <EventCard
                  preview
                  event={post}
                  showComments={false}
                  showActions
                  promoted
                />
              )}
            </Card>
          )}
        </div>
      </div>
      <div
        style={{
          bottom: 0,
          left: 0,
          position: isMobile ? 'fixed' : 'relative',
          backgroundColor: isMobile ? '#f7f8fb' : 'transparent',
          padding: 12,
          borderTop: isMobile ? '1px solid #dfdfdf' : 'none',
        }}
      >
        {isMobile && (
          <Button color="success" style={{ width: '100%' }}>
            Promote Now
          </Button>
        )}
        <div
          style={{
            textAlign: 'center',
            marginTop: 20,
            fontSize: 12,
          }}
        >
          {"By clicking Promote Now, you agree to Boxpressd's "}
          <a href="https://boxpressd.com/legal/terms" target="_blank">
            Terms of Service
          </a>
          {' | '}
          <a href="https://help.boxpressd.com" target="_blank">
            Help Center
          </a>
        </div>
      </div>
    </div>
  );

  if (isMobile) {
    return (
      <ModalView
        open={open}
        showFrom="bottom"
        title="Promote Post"
        onClose={PromoteDialog.close}
        rightButtons={[
          {
            component: <Icon name="help-circle" />,
            onClick: () => {
              // TODO Link to an article on Boxpressd's help center for promoting posts
            },
          },
        ]}
      >
        {renderContent()}
      </ModalView>
    );
  }
  return (
    <Modal
      isOpen={open}
      // toggle={props.toggleOpen}
      style={{ maxWidth: 1024 }}
      fade
    >
      <div className="modal-header">
        <Button className="close" color="" onClick={PromoteDialog.close}>
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody>{renderContent()}</ModalBody>
      <ModalFooter>
        <MaterialButton variant="text" onClick={PromoteDialog.close}>
          Cancel
        </MaterialButton>
        <Button color="success" onClick={promote} disabled={submitting}>
          {submitting && (
            <Spinner
              color="dark"
              style={{ marginRight: 8, height: 16, width: 16 }}
            />
          )}
          {submitting ? 'Submitting...' : 'Promote Now'}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

PromoteDialog.show = (props) => {
  AppStore.update((s) => {
    s.showPromoteModal = true;
    s.promoteModalProps = props;
  });
};

PromoteDialog.close = () => {
  AppStore.update((s) => {
    s.showPromoteModal = false;
    s.promoteModalProps = {};
  });
};

export default PromoteDialog;
