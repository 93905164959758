import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import axios from 'axios';
import { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';

import { config } from '@/settings';
import { AppStore, NotificationStore, Storage } from '@/stores';
import { renderTimestamp } from '@/utils/formatting';

export const notificationLink = (notification) => {
  console.log(notification.path);
  // TODO Base the link off of the attached object? Or include a type field in the notification?
  return notification.path;
};

export const updateReadReport = (notification) => {
  if (!notification.read) {
    const read = true;
    // FIXME Implement this - see boxpressd-nextjs
    // NotificationsStore.update((s) => {
    //   for (let i = 0; i < s.notifications.length; i++) {
    //     if (s.notifications[i].id === notification.id) {
    //       s.notifications[i] = {
    //         ...notification,
    //         read,
    //       };
    //       break;
    //     }
    //   }
    //   s.notificationCount =
    //     s.notificationCount > 0 ? s.notificationCount - 1 : 0;
    //   // Just take the values from above and update the persisted state - may want to do away with the handling above
    //   // and just find a way to get below working by itself.
    //   updatePersistedState('alert-status', {
    //     // FIXME Why is it that at random times this is an integer??? Should we clone the array above rather than using it straight from the store?
    //     notifications: Array.isArray(s.notifications) ? s.notifications : [],
    //     notificationCount: s.notificationCount,
    //   });
    // });

    axios.put(`${config.apiEndPoint}/users/notifications/${notification.id}`, {
      ...notification,
      read,
    });
  }
};

export const getNotifications = (selectedProfile, selectedProfileType) => {
  axios
    .get(
      `${config.businessEndPoint}/${selectedProfileType}s/${selectedProfile.id}/notifications`
    )
    .then((res) => {
      console.debug('Got notifications:');
      console.debug(res.data);
      let notificationCount = 0;
      for (const notification of res.data) {
        if (!notification.read) {
          notificationCount += 1;
        }
      }
      Storage.setPreference('alert-status', {
        notifications: res.data,
        notificationCount,
      });
      NotificationStore.update((s) => {
        s.notifications = res.data;
        s.notificationCount = notificationCount;
        s.loading = false;
      });
    })
    .catch((err) => {
      console.error(err);
      NotificationStore.update((s) => {
        s.loading = false;
      });
    });
};

const Content = () => {
  const selectedProfile = AppStore.useState((s) => s.selectedProfile);
  const selectedProfileType = AppStore.useState((s) => s.selectedProfileType);
  const notifications = NotificationStore.useState((s) => s.notifications);
  const loading = NotificationStore.useState((s) => s.loading);

  useEffect(() => {
    NotificationStore.update((s) => {
      s.loading = true;
    });
    getNotifications(selectedProfile, selectedProfileType);
  }, [selectedProfile, selectedProfileType]);

  return (
    <>
      <div style={{ padding: 16, textAlign: 'center' }}>
        {loading && (
          <div style={{ margin: 10, display: 'flex', flexDirection: 'column' }}>
            {[1,2,3,4].map(() => (
              <Skeleton
                height={70}
                width="calc(100vw - 50px)"
                style={{ marginBottom: 12 }}
              />
            ))}
          </div>
        )}
        {!loading && (!notifications || notifications.length === 0) && (
          <div style={{ margin: 10, textAlign: 'center' }}>All caught up!</div>
        )}
        <List>
          {!loading &&
            Array.isArray(notifications) &&
            notifications.map((notification) => (
              <Link to={notificationLink(notification)}>
                <ListItem
                  key={`notification-${notification.id}`}
                  onClick={() => updateReadReport(notification)}
                >
                  {/* FIXME These need to be dynamic - they won't always be user avatars - see BXPR-589 */}
                  <Avatar
                    src={
                      notification.image_url &&
                      notification.image_url.replace(
                        '/users/profile/',
                        '/100x100/users/profile/'
                      )
                    }
                    style={{ height: 64, width: 64, marginRight: 22 }}
                  />
                  <ListItemText
                    primary={
                      <span
                        style={{
                          fontSize: '1.1rem',
                          fontWeight: notification.read ? 400 : 700,
                        }}
                      >
                        {notification.content}
                      </span>
                    }
                    secondary={
                      <span
                        style={{
                          color: notification.read ? 'inherit' : '#d6c290',
                          fontWeight: notification.read ? 400 : 700,
                        }}
                      >
                        {renderTimestamp(notification.timestamp)}
                      </span>
                    }
                  />
                  {!notification.read && (
                    <ListItemSecondaryAction style={{ right: 30 }}>
                      <Badge badgeContent=" " color="primary" variant="dot" />
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              </Link>
            ))}
        </List>
      </div>
    </>
  );
};

export default Content;
