import './style.scss';

import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { isMobile } from 'mobile-device-detect';
import type { ReactNode } from 'react';
import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

import Icon from '@/components/Icon';

interface RightButton {
  title?: string;
  component?: ReactNode;
  color?: string;
  onClick: () => void;
}

interface ModalViewProps {
  children: ReactNode;
  footer?: ReactNode;
  additionalHeaderItems?: ReactNode;
  title?: string;
  style?: {};
  open: boolean;
  onClose: () => void;
  rightButtons?: RightButton[];
}

const ModalView: React.FC<ModalViewProps> = ({
  children,
  title,
  open,
  onClose,
  style,
  rightButtons = [],
  additionalHeaderItems = null,
  footer = null,
  ...props
}) => {
  if (isMobile) {
    return (
      <IonModal isOpen={open} {...props}>
        <IonHeader>
          <IonToolbar mode="ios" color="dark">
            <IonButtons slot="start">
              <IonButton onClick={onClose}>{'Cancel'}</IonButton>
            </IonButtons>
            <IonTitle>{title || ''}</IonTitle>
            <IonButtons slot="end">
              {rightButtons.map((button, index) => (
                <IonButton key={index} strong={true} onClick={button.onClick}>
                  {button.title || button.component}
                </IonButton>
              ))}
            </IonButtons>
          </IonToolbar>
          {additionalHeaderItems}
        </IonHeader>
        <IonContent className="ion-padding">{children}</IonContent>
      </IonModal>
    );
  }
  return (
    <Modal fade isOpen={open} toggle={onClose} style={style}>
      <div className="modal-header" style={{ padding: 12 }}>
        <h3>{title || ''}</h3>
        <Button className="close" color="" onClick={onClose}>
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody style={{ padding: 4 }}>{children}</ModalBody>
      {footer || (
        <ModalFooter style={{ padding: 12 }}>
          {rightButtons.map((button, index) => (
            <Button
              key={index}
              color={button.color || 'secondary'}
              onClick={button.onClick}
            >
              {button.title || button.component}
            </Button>
          ))}
        </ModalFooter>
      )}
    </Modal>
  );
};

export default ModalView;
