import './style.scss'

import Avatar from '@mui/material/Avatar'
import PropTypes from 'prop-types'
import React from 'react'
import { Card, Container, Row } from 'reactstrap'

import MediaGrid from '@/components/MediaGrid'
import { getProfileImage } from '@/components/ProfileSelect'
import VideoSource from '@/components/VideoSource'
import { AppStore, SettingsStore } from '@/stores'
import { Resize } from '@/utils/imageUtils'

const TwitterSocialCard = ({ post }) => {
  const selectedProfile = AppStore.useState((s) => s.selectedProfile);
  // const selectedProfileType = AppStore.useState((s) => s.selectedProfileType);
  const darkMode = SettingsStore.useState((s) => s.darkMode);

  const renderHeader = () => {
    return (
      <div style={{ display: 'flex', padding: 5 }}>
        <div style={{ flex: 1 }}>
          <div style={{ fontWeight: 700, color: '#2b2b2b !important' }}>
            {/* TODO Use the linked account name instead */}
            {selectedProfile && selectedProfile.name}
            <span style={{ marginLeft: 6, fontWeight: 400, opacity: 0.8 }}>
              {`@${'username'} · Just Now`}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderInnerMedia = () => {
    if (post.media && post.media.length) {
      if (
        post.media[0].media_type === 'video' ||
        post.media[0].media_url.indexOf('.mp4') !== -1
      ) {
        return (
          <div style={{ padding: 12, borderRadius: 12, overflow: 'hidden' }}>
            <VideoSource
              videoSrc={post.media[0].media_url}
              scaleWidth="100%"
              scaleHeight="100%"
              // placeholder={post.image}
              title={post.title}
              thumbnailTime={post.media[0].video_timestamp_start || 0.1}
            />
          </div>
        );
      }
      return (
        <Container
          style={{ padding: 12 }}
          className="horizontal-media-container"
        >
          <Row>
            {/* FIXME Twitter allows a max of 4 images - we won't need to worry about this right now since we can only upload a link */}
            {post.media.length > 1 && (
              <MediaGrid
                media={post.media.map((media) => ({
                  type: media.media_type,
                  src: media.media_url,
                }))}
                direction="mixed"
                lazyImages={post.media.map((media) => ({
                  type: media.media_type,
                  src:
                    media.media_type !== 'video'
                      ? Resize.size(media.media_url, {
                          height: 100,
                          width: 100,
                          cropType: 'crop',
                        })
                      : media.media_url,
                }))}
                thumbnailMedia={post.media.map((media) => ({
                  type: media.media_type,
                  src:
                    media.media_type !== 'video'
                      ? Resize.size(media.media_url)
                      : media.media_url,
                }))}
              />
            )}
            {post.media.length === 1 && (
              <img
                src={post.media[0].media_url}
                style={{ width: '100%', objectFit: 'cover', borderRadius: 12 }}
              />
            )}
          </Row>
        </Container>
      );
    }
    // if (post.media && post.media.length) {
    //   return (
    //     <MediaGrid
    //       media={post.media.map((media) => ({ src: media.media_url }))}
    //       thumbnailMedia={post.media.map((media) => ({ src: media.media_url && media.media_url.replace('/posts/', '/posts/500x500/').replace('/businesses/', '/businesses/500x500/') }))}
    //     />
    //   );
    // }
    return null;
  };

  return (
    <Card>
      <div
        className="feed-card-item twitter-card"
        style={{
          background: darkMode ? 'black' : 'white',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Avatar
          src={
            selectedProfile &&
            (getProfileImage(selectedProfile.profile_image_url) ||
              getProfileImage(selectedProfile.logo_image_url))
          }
          style={{ height: 40, width: 40, margin: '8px 12px' }}
        >
          {selectedProfile && selectedProfile.name.charAt(0)}
        </Avatar>
        <div style={{ flex: 1 }}>
          {renderHeader()}
          <div className="content" style={{ marginBottom: 0 }}>
            <div
              className="content-right"
              style={{ width: '100%', padding: 6 }}
            >
              {(post.content || post.text || '').substring(0, 486) +
                ((post.content || post.text || '').length > 486
                  ? ' ... See more'
                  : '')}
            </div>
            <div className="clearfix" />
          </div>
          {renderInnerMedia()}
          <div style={{ display: 'flex', flexDirection: 'row', padding: 10 }}>
            <div style={{ flex: 1, textAlign: 'center' }}>
              <svg
                style={{ height: 20, width: 20 }}
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <g>
                  <path d="M1.751 10c0-4.42 3.584-8 8.005-8h4.366c4.49 0 8.129 3.64 8.129 8.13 0 2.96-1.607 5.68-4.196 7.11l-8.054 4.46v-3.69h-.067c-4.49.1-8.183-3.51-8.183-8.01zm8.005-6c-3.317 0-6.005 2.69-6.005 6 0 3.37 2.77 6.08 6.138 6.01l.351-.01h1.761v2.3l5.087-2.81c1.951-1.08 3.163-3.13 3.163-5.36 0-3.39-2.744-6.13-6.129-6.13H9.756z"></path>
                </g>
              </svg>
            </div>
            <div style={{ flex: 1, textAlign: 'center' }}>
              <svg
                style={{ height: 20, width: 20 }}
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <g>
                  <path d="M4.5 3.88l4.432 4.14-1.364 1.46L5.5 7.55V16c0 1.1.896 2 2 2H13v2H7.5c-2.209 0-4-1.79-4-4V7.55L1.432 9.48.068 8.02 4.5 3.88zM16.5 6H11V4h5.5c2.209 0 4 1.79 4 4v8.45l2.068-1.93 1.364 1.46-4.432 4.14-4.432-4.14 1.364-1.46 2.068 1.93V8c0-1.1-.896-2-2-2z"></path>
                </g>
              </svg>
            </div>
            <div style={{ flex: 1, textAlign: 'center' }}>
              <svg
                style={{ height: 20, width: 20 }}
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <g>
                  <path d="M16.697 5.5c-1.222-.06-2.679.51-3.89 2.16l-.805 1.09-.806-1.09C9.984 6.01 8.526 5.44 7.304 5.5c-1.243.07-2.349.78-2.91 1.91-.552 1.12-.633 2.78.479 4.82 1.074 1.97 3.257 4.27 7.129 6.61 3.87-2.34 6.052-4.64 7.126-6.61 1.111-2.04 1.03-3.7.477-4.82-.561-1.13-1.666-1.84-2.908-1.91zm4.187 7.69c-1.351 2.48-4.001 5.12-8.379 7.67l-.503.3-.504-.3c-4.379-2.55-7.029-5.19-8.382-7.67-1.36-2.5-1.41-4.86-.514-6.67.887-1.79 2.647-2.91 4.601-3.01 1.651-.09 3.368.56 4.798 2.01 1.429-1.45 3.146-2.1 4.796-2.01 1.954.1 3.714 1.22 4.601 3.01.896 1.81.846 4.17-.514 6.67z"></path>
                </g>
              </svg>
            </div>
            <div style={{ flex: 1, textAlign: 'center' }}>
              <svg
                style={{ height: 20, width: 20 }}
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <g>
                  <path d="M8.75 21V3h2v18h-2zM18 21V8.5h2V21h-2zM4 21l.004-10h2L6 21H4zm9.248 0v-7h2v7h-2z"></path>
                </g>
              </svg>
            </div>
            <div style={{ flex: 1, textAlign: 'center' }}>
              <svg
                style={{ height: 20, width: 20 }}
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <g>
                  <path d="M12 2.59l5.7 5.7-1.41 1.42L13 6.41V16h-2V6.41l-3.3 3.3-1.41-1.42L12 2.59zM21 15l-.02 3.51c0 1.38-1.12 2.49-2.5 2.49H5.5C4.11 21 3 19.88 3 18.5V15h2v3.5c0 .28.22.5.5.5h12.98c.28 0 .5-.22.5-.5L19 15h2z"></path>
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

TwitterSocialCard.propTypes = {
  post: PropTypes.object.isRequired,
};

export default TwitterSocialCard;
