import CreateAdModal from '@/components/CreateAdModal';
import CreateEventModal from '@/components/CreateEventModal';

const env = process.env.NODE_ENV || 'development';
export const config = require('@/config/config.json')[env];
export const apiKeys = require('@/config/apiKeys.json')[env];

const settings = {
  night_mode: false,
  spotlight_mode: false,
  show_section_lines: true,
  sidebar_dark: false,
  sidebar_static: false,
  sidebar_small: false,
  sidebar_effect: 'shrink',
  nav: true,
  nav_dark: false,
  nav_logo_path: require('@/assets/images/logo.png'),
  nav_logo_white_path: require('@/assets/images/logo.png'),
  nav_logo_width: '45px',
  nav_logo_url: '/',
  nav_align: 'left',
  nav_expand: 'lg',
  nav_sticky: true,
  nav_autohide: true,
  nav_container_fluid: true,
  home_url: '/',
  navigation: {
    '#actions': {
      name: 'Quick Actions',
      icon: 'plus-circle',
      sub: {
        '#create-post': {
          name: 'Create Post',
          icon: 'edit',
          onClick: (e) => {
            e.preventDefault();
            console.log('Showing ad editor');
            CreateAdModal.show({
              onClose: () => CreateAdModal.close(),
            });
          },
        },
        // '/push': {
        //   name: 'Create New Push',
        //   icon: 'bell',
        // },
        '#create-event': {
          name: 'Create Event',
          icon: 'calendar',
          onClick: (e) => {
            e.preventDefault();
            console.log('Showing event editor');
            CreateEventModal.show({
              // onSave: saveEvent,
              // onEventSaved: onSavedEvent,
              onClose: () => CreateEventModal.close(),
            });
          },
        },
        '#claim-location': {
          name: 'Claim A Location',
          icon: 'map-pin',
          onClick: (e) => {
            e.preventDefault();
            console.log('Showing claim process');
            window.location.href = `${config.appEndPoint}/venues/claim`;
          },
        },
      },
    },
  },
  mobile_navigation: {
    chips: [
      {
        name: /* messages[language].create_post || */ 'Create Post',
        icon: 'edit',
        // route: 'cigars/:uid',
        onClick: (e) => {
          e.preventDefault();
          console.log('Showing ad editor');
          CreateAdModal.show({
            onClose: () => CreateAdModal.close(),
          });
        },
        action: (callback) => {},
      },
      {
        name: /* messages[language].create_event || */ 'Create Event',
        icon: 'calendar',
        onClick: (e) => {
          e.preventDefault();
          console.log('Showing event editor');
          // CreateEventModal.show({
          //   onClose: () => CreateEventModal.close(),
          // });
        },
        action: (callback) => {},
      },
      // {
      //   name: /* messages[language].create_ad || */'Create Ad',
      //   icon: 'briefcase',
      //   onClick: (e) => {
      //     e.preventDefault();
      //     console.log('Showing ad editor');
      //     CreateAdModal.show({
      //       onClose: () => CreateAdModal.close(),
      //     });
      //   },
      //   action: (callback) => {
      //
      //   },
      // },
      {
        name: /* messages[language].create_ad || */ 'Claim Location',
        icon: 'map-pin',
        onClick: (e) => {
          e.preventDefault();
          window.location.href = `${config.appEndPoint}/venues/claim`;
        },
        action: (callback) => {},
      },
    ],
  },
  venue_navigation_sidebar: {
    '/': {
      name: 'Public Profile',
      icon: 'user',
    },
    '/insights': {
      name: 'Insights',
      icon: 'pie-chart',
    },
    '/planner': {
      name: 'Planner',
      icon: 'clock',
    },
    // '/notifications': {
    //   name: 'Notifications',
    //   icon: 'bell',
    // },
    // '/inbox': {
    //   name: 'Inbox',
    //   icon: 'mail',
    // },
    // '/messenger': {
    //     name: 'Messenger',
    //     icon: 'message-circle',
    // },
    '/events': {
      name: 'Events',
      icon: 'calendar',
    },
    // '/ads': {
    //   name: 'Ad Manager',
    //   icon: 'bar-chart',
    // },
    '/inventory': {
      name: 'Inventory',
      icon: 'package',
    },
    '/fulfillments': {
      name: 'Fulfillments',
      icon: 'download',
    },
    '/press': {
      name: 'Press Releases',
      icon: 'book-open',
    },
    '/accounts': {
      name: 'Users & Social',
      icon: 'users',
    },
    '/integrations': {
      name: 'Integrations',
      icon: 'grid',
    },
    '/billing': {
      name: 'Billing & Account',
      icon: 'credit-card',
    },
  },
  cigar_brand_navigation_sidebar: {
    '/': {
      name: 'Public Profile',
      icon: 'user',
    },
    '/insights': {
      name: 'Insights',
      icon: 'pie-chart',
    },
    '/planner': {
      name: 'Planner',
      icon: 'clock',
    },
    // '/notifications': {
    //   name: 'Notifications',
    //   icon: 'bell',
    // },
    // '/inbox': {
    //   name: 'Inbox',
    //   icon: 'mail',
    // },
    '/events': {
      name: 'Events',
      icon: 'calendar',
    },
    // '/ad-manager': {
    //   name: 'Ad Manager',
    //   icon: 'bar-chart',
    // },
    '/cigars': {
      name: 'Cigars',
      icon: 'edit2',
    },
    '/press': {
      name: 'Press Releases',
      icon: 'book-open',
    },
    '/accounts': {
      name: 'Users & Social',
      icon: 'users',
    },
    '/billing': {
      name: 'Billing & Account',
      icon: 'credit-card',
    },
  },
  brand_navigation_sidebar: {
    '/': {
      name: 'Public Profile',
      icon: 'user',
    },
    '/insights': {
      name: 'Insights',
      icon: 'pie-chart',
    },
    '/planner': {
      name: 'Planner',
      icon: 'clock',
    },
    // '/notifications': {
    //   name: 'Notifications',
    //   icon: 'bell',
    // },
    // '/inbox': {
    //   name: 'Inbox',
    //   icon: 'mail',
    // },
    '/events': {
      name: 'Events',
      icon: 'calendar',
    },
    // '/ad-manager': {
    //   name: 'Ad Manager',
    //   icon: 'bar-chart',
    // },
    '/press': {
      name: 'Press Releases',
      icon: 'book-open',
    },
    '/accounts': {
      name: 'Users & Social',
      icon: 'users',
    },
    '/billing': {
      name: 'Billing & Account',
      icon: 'credit-card',
    },
  },
  main_navigation_sidebar: {
    '/': {
      name: 'Public Profile',
      icon: 'user',
    },
    '/insights': {
      name: 'Insights',
      icon: 'pie-chart',
    },
    '/planner': {
      name: 'Planner',
      icon: 'clock',
    },
    // '/notifications': {
    //   name: 'Notifications',
    //   icon: 'bell',
    // },
    // '/inbox': {
    //   name: 'Inbox',
    //   icon: 'mail',
    // },
    '/events': {
      name: 'Events',
      icon: 'calendar',
    },
    // '/ad-manager': {
    //   name: 'Ad Manager',
    //   icon: 'bar-chart',
    // },
    '/press': {
      name: 'Press Releases',
      icon: 'book-open',
    },
    '/accounts': {
      name: 'Users & Social',
      icon: 'users',
    },
    '/billing': {
      name: 'Billing & Account',
      icon: 'credit-card',
    },
  },
  img_country: {
    usa: require('@/assets/images/flags/united-states-of-america.svg'),
    china: require('@/assets/images/flags/china.svg'),
    germany: require('@/assets/images/flags/germany.svg'),
    japan: require('@/assets/images/flags/japan.svg'),
    spain: require('@/assets/images/flags/spain.svg'),
    france: require('@/assets/images/flags/france.svg'),
    canada: require('@/assets/images/flags/canada.svg'),
    netherlands: require('@/assets/images/flags/netherlands.svg'),
    italy: require('@/assets/images/flags/italy.svg'),
    russia: require('@/assets/images/flags/russia.svg'),
    czech_republic: require('@/assets/images/flags/czech-republic.svg'),
  },
};

/* Parse GET variables to change initial settings */
const $_GET = {};
window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (a, name, value) => {
  $_GET[name] = value;
});

Object.keys($_GET).forEach((name) => {
  const isTrue = $_GET[name] === '1';

  switch (name) {
    case 'setting-night-mode':
      settings.night_mode = isTrue;
      break;
    case 'setting-show-section-lines':
      settings.show_section_lines = isTrue;
      break;
    case 'setting-sidebar-small':
      settings.sidebar_small = isTrue;
      break;
    case 'setting-sidebar-dark':
      settings.sidebar_dark = isTrue;
      break;
    case 'setting-nav-dark':
      settings.nav_dark = isTrue;
      break;
    // no default
  }
});

export default settings;
