import axios from 'axios';
import ls from 'local-storage';

import { updateSelectedProfile } from '@/components/ProfileSelect';
import { config } from '@/settings';
import { AppStore } from '@/stores';

export const getBusinessClaims = async (userId: string | number) => {
  const venueResult = await axios.get(
    `${config.apiEndPoint}/users/${userId}/venues?claimed=true`
  );
  const brandResult = await axios.get(
    `${config.apiEndPoint}/users/${userId}/brands?claimed=true`
  );

  // TODO Only consider it to fail if all fail? Or change this to have separate `success` bools for each of the 2?
  const success = !(venueResult.status !== 200 && brandResult.status !== 200);
  let venues = [];
  let brands = [];

  if (venueResult.status === 200) {
    console.log('Received venue claims:');
    console.log(venueResult.data);
    venues = venueResult.data;
  }

  if (brandResult.status === 200) {
    console.log('Received brand claims:');
    console.log(brandResult.data);
    brands = brandResult.data;
  }

  if (!localStorage.getItem('selectedProfile')) {
    if (venues.length > 0) {
      updateSelectedProfile(venues[0], 'venue');
    } else if (brands.length > 0) {
      updateSelectedProfile(brands[0], 'brand');
    }
  }
  ls('boxpressdBusinessVenues', venues || []);
  ls('boxpressdBusinessBrands', brands || []);
  AppStore.update((s) => {
    s.profiles.venues = venues;
    s.profiles.brands = brands;
    s.loading = false;
  });

  return {
    success,
    venues,
    brands,
  };
};
