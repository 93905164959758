import './style.scss';

import { Icon } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { isIOS } from 'mobile-device-detect';
import React, { useCallback, useEffect, useState } from 'react';

import { config } from '@/settings';
import { UserStore } from '@/stores';

// Convert the class component to a functional component
const ErrorBoundary = ({ children, onClose = () => {} }) => {
  const user = UserStore.useState((s) => s.user);
  const [state, setState] = useState({
    hasError: false,
    error: null,
    errorInfo: null,
  });

  const { hasError, error, errorInfo } = state;

  const goBack = useCallback(() => {
    window.history.back();
    setTimeout(() => {
      setState({ hasError: false, error: null, errorInfo: null });
    }, 100);
  }, []);

  const backArrow = useCallback(() => {
    if (!isIOS) {
      return (
        <IconButton
          edge="start"
          color="inherit"
          aria-label="Go Back"
          onClick={() => window.history.back()}
        >
          {/* @ts-ignore */}
          <Icon
            name="arrow-left"
            size={22}
            style={{ marginLeft: 12, height: 22, width: 22, color: '#efefef' }}
          />
        </IconButton>
      );
    }
    return (
      <IconButton
        edge="start"
        color="inherit"
        aria-label="Go Back"
        onClick={() => window.history.back()}
      >
        {/* @ts-ignore */}
        <Icon
          name="chevron-left"
          size={28}
          style={{ height: 28, width: 28, color: '#efefef' }}
        />
        <span style={{ fontSize: 16 }}>Back</span>
      </IconButton>
    );
  }, [onClose, goBack]);

  useEffect(() => {
    const handleError = (error, errorInfo) => {
      try {
        Sentry.setExtra('error_boundary', true);
        Sentry.captureException(error);
      } catch (err) {
        // TODO Anything extra here?
      }
      setState({ hasError: true, error, errorInfo });
    };

    // Example usage, need to integrate with actual error catching mechanism
    window.addEventListener('error', (event) => handleError(event.error, null));
    return () => {
      window.removeEventListener('error', (event) =>
        handleError(event.error, null)
      );
    };
  }, []);

  if (hasError) {
    const styles = {};
    return (
      <div style={{ textAlign: 'center', margin: 16 }}>
        <AppBar style={styles}>
          <Toolbar>{backArrow()}</Toolbar>
        </AppBar>
        <h1 style={{ marginBottom: 10, paddingTop: 70 }}>
          Something went wrong
        </h1>
        <p>
          {
            'The problem has been reported to our team and they are working on a fix. Visit '
          }
          <a href="http://status.boxpressd.com">our status page</a>
          {' for any active incident reports.'}
        </p>
        {/* @ts-ignore */}
        {user && (
          <div>
            <p>
              Would you like to be notified as soon as an update is available?
            </p>
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: 20 }}
              onClick={() => {
                axios
                  .post(`${config.apiEndPoint}/support/create`, {
                    user_id: user.id,
                    email: user.email,
                    subject: `Crash report resolution requested by ${user.full_name}`,
                    description:
                      'Please let me know as soon as this issue is fixed.',
                    custom_fields: {
                      error_message: error ? error.toString() : 'Unknown error',
                      stack_trace: errorInfo.componentStack,
                      // TODO Include the body of last Axios request if available?
                    },
                  })
                  .then(() => {
                    // TODO Alert?
                    goBack(); // FIXME What if the error is on the home page?
                  })
                  .catch((err) => {
                    console.log(err);
                    Sentry.captureException(error);
                    goBack();
                  });
              }}
            >
              {'Notify Me When Fixed'}
            </Button>
          </div>
        )}
      </div>
    );
  }

  return children;
};

export default ErrorBoundary;
