import '@ionic/react/css/core.css';

import { IonContent, IonPage } from '@ionic/react';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/styles';
import { isMobile } from 'mobile-device-detect';
import React from 'react';

import Routes from '@/Routes';
import ErrorBoundary from '@/templates/error-boundary';
import PageDrawer from '@/templates/page-drawer';
import PageNavbar from '@/templates/page-navbar';
import PageTabs from '@/templates/page-tabs';

const PageWrap = () => {
  const darkMode = false;

  const mainTheme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: '#efefef',
      },
      secondary: {
        main: '#D3A966',
      },
    },
  });

  if (window.location.pathname === '/onboarding') {
    // INFO User has a Boxpressd account, but hasn't upgraded it to a business account
    return (
      <IonPage>
        <Routes />
      </IonPage>
    );
  }

  return (
    <ThemeProvider theme={mainTheme}>
      <IonPage id="main-content">
        <PageNavbar />
        <PageDrawer />
        <IonContent className="ion-padding">
          <Routes />
        </IonContent>
        {/*  <ShareIntent /> */}
        {/*  <PageToasts /> */}
        {isMobile && <PageTabs />}
      </IonPage>
    </ThemeProvider>
  );
};

export default PageWrap;
