import { IonModal } from '@ionic/react';
import { isIOS, isMobile } from 'mobile-device-detect';
import OneSignal from 'react-onesignal';
import { useLocation } from 'react-router-dom';
import { Button } from 'reactstrap';

import { Storage } from '@/stores';

const iosScreen =
  'https://aouikjkrpo.cloudimg.io/v7/https://cdn.boxpressd.io/assets/img/virtual-humidor-s8.png?force_format=webp';
const androidScreen =
  'https://aouikjkrpo.cloudimg.io/v7/https://cdn.boxpressd.io/assets/img/virtual-humidor-s8.png?force_format=webp';

const PushNotificationPrompt = ({ open, onClose }) => {
  const location = useLocation();
  const { pathname } = location;
  if (isMobile) {
    return (
      <IonModal
        isOpen={
          open
          && pathname !== '/install'
          // && !Storage.getPreference('hide-notification-prompt', false)
        }
      >
        {/* TODO CNM / Map pins style background with Boxpressd for Business pins? All text should be white */}
        <div
          style={{ background: '', textAlign: 'center', padding: '40px 16px' }}
        >
          <h3>Enable Notifications</h3>
          {/* TODO Screenshot of the prompt on top of the app */}
          <img
            src={isIOS ? iosScreen : androidScreen}
            alt="Enable push notifications prompt"
            style={{ width: '60%' }}
          />
          <p style={{ fontSize: 12, marginTop: 20 }}>
            Select "Allow" from the next prompt to ensure you never miss an
            interaction with followers, supporters, or potential customers.
          </p>
          <Button
            color="brand"
            style={{ width: '80%' }}
            onClick={() => {
              OneSignal.Notifications.requestPermission().then(() => {
                if (OneSignal.Notifications.permissionNative !== 'granted') {
                  OneSignal.User.PushSubscription.optIn().then(() => {
                    // localStorage.setItem('disable_push_request_prompt', 'true');
                    onClose();
                  });
                }
              });
            }}
          >
            {'Next'}
          </Button>
          <p
            style={{ fontSize: 12, marginTop: 20 }}
            onClick={() => {
              Storage.setPreference('hide-notification-prompt', true);
              if (typeof onClose === 'function') {
                onClose();
              }
            }}
          >
            {'Not now'}
          </p>
        </div>
      </IonModal>
    );
  }
  // TODO Desktop version
  return null;
};

export default PushNotificationPrompt;
