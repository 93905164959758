import axios from 'axios';
import useSWR, { mutate } from 'swr';

export const CACHE_INTERVAL_DEFAULT = 15 * 60 * 1000;
export const CACHE_INTERVAL_MEDIUM = 60 * 60 * 1000;
export const CACHE_INTERVAL_LONG = 24 * 60 * 60 * 1000;
export const CACHE_INTERVAL_NEVER = 100 * 365 * 24 * 60 * 60 * 1000;

const fetcher = (url) => axios.get(url).then((res) => res.data);

export const useCache = (
  url,
  cacheInterval = CACHE_INTERVAL_DEFAULT,
  options = {}
) => {
  const { data, error } = useSWR(url, fetcher, {
    dedupingInterval: cacheInterval,
    ...options,
  });
  return {
    data,
    isLoading: !error && !data,
    error,
    // Force updates the cache
    invalidate: () => mutate(url),
  };
};
