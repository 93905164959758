import messagesGerman from '@/translations/de.json';
import messagesEnglish from '@/translations/en.json';
import messagesSpanish from '@/translations/es.json';
import messagesFrench from '@/translations/fr.json';
import messagesItalian from '@/translations/it.json';
import messagesPortuguese from '@/translations/pt.json';

export const locale =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage ||
  'en-US';

// console.log('Locale:');
// console.log(locale);

export const language = locale.split(/[-_]/)[0]

export const messages = {
  en: messagesEnglish,
  es: messagesSpanish,
  it: messagesItalian,
  de: messagesGerman,
  fr: messagesFrench,
  pt: messagesPortuguese,
};
