import './style.scss'

import Avatar from '@mui/material/Avatar'
import PropTypes from 'prop-types'
import React from 'react'
import { Card, Container, Row } from 'reactstrap'

import MediaGrid from '@/components/MediaGrid'
import { getProfileImage } from '@/components/ProfileSelect'
import VideoSource from '@/components/VideoSource'
import { AppStore, SettingsStore } from '@/stores'
import { Resize } from '@/utils/imageUtils'

const FacebookSocialCard = ({ post }) => {
  const selectedProfile = AppStore.useState((s) => s.selectedProfile);
  // const selectedProfileType = AppStore.useState((s) => s.selectedProfileType);
  const darkMode = SettingsStore.useState((s) => s.darkMode);

  const renderHeader = () => {
    return (
      <div style={{ display: 'flex', padding: 5 }}>
        <Avatar
          src={
            selectedProfile &&
            (getProfileImage(selectedProfile.profile_image_url) ||
              getProfileImage(selectedProfile.logo_image_url))
          }
          style={{ height: 40, width: 40, margin: '8px 12px' }}
        >
          {selectedProfile && selectedProfile.name.charAt(0)}
        </Avatar>
        <div style={{ flex: 1 }}>
          <div style={{ fontWeight: 700, color: '#2b2b2b !important' }}>
            {/* TODO Use the linked account name instead */}
            {selectedProfile && selectedProfile.name}
            <div>
              {'Just Now'}
              <svg
                style={{ marginLeft: 6 }}
                fill="currentColor"
                viewBox="0 0 16 16"
                width="1em"
                height="1em"
                title="Shared with Public"
              >
                <title>Shared with Public</title>
                <g fill-rule="evenodd" transform="translate(-448 -544)">
                  <g>
                    <path
                      d="M109.5 408.5c0 3.23-2.04 5.983-4.903 7.036l.07-.036c1.167-1 1.814-2.967 2-3.834.214-1 .303-1.3-.5-1.96-.31-.253-.677-.196-1.04-.476-.246-.19-.356-.59-.606-.73-.594-.337-1.107.11-1.954.223a2.666 2.666 0 0 1-1.15-.123c-.007 0-.007 0-.013-.004l-.083-.03c-.164-.082-.077-.206.006-.36h-.006c.086-.17.086-.376-.05-.529-.19-.214-.54-.214-.804-.224-.106-.003-.21 0-.313.004l-.003-.004c-.04 0-.084.004-.124.004h-.037c-.323.007-.666-.034-.893-.314-.263-.353-.29-.733.097-1.09.28-.26.863-.8 1.807-.22.603.37 1.166.667 1.666.5.33-.11.48-.303.094-.87a1.128 1.128 0 0 1-.214-.73c.067-.776.687-.84 1.164-1.2.466-.356.68-.943.546-1.457-.106-.413-.51-.873-1.28-1.01a7.49 7.49 0 0 1 6.524 7.434"
                      transform="translate(354 143.5)"
                    ></path>
                    <path
                      d="M104.107 415.696A7.498 7.498 0 0 1 94.5 408.5a7.48 7.48 0 0 1 3.407-6.283 5.474 5.474 0 0 0-1.653 2.334c-.753 2.217-.217 4.075 2.29 4.075.833 0 1.4.561 1.333 2.375-.013.403.52 1.78 2.45 1.89.7.04 1.184 1.053 1.33 1.74.06.29.127.65.257.97a.174.174 0 0 0 .193.096"
                      transform="translate(354 143.5)"
                    ></path>
                    <path
                      fill-rule="nonzero"
                      d="M110 408.5a8 8 0 1 1-16 0 8 8 0 0 1 16 0zm-1 0a7 7 0 1 0-14 0 7 7 0 0 0 14 0z"
                      transform="translate(354 143.5)"
                    ></path>
                  </g>
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderInnerMedia = () => {
    if (post.media && post.media.length) {
      if (
        post.media[0].media_type === 'video' ||
        post.media[0].media_url.indexOf('.mp4') !== -1
      ) {
        return (
          <div style={{ height: 400 }}>
            <VideoSource
              videoSrc={post.media[0].media_url}
              scaleWidth="100%"
              scaleHeight="100%"
              // placeholder={post.image}
              title={post.title}
              thumbnailTime={post.media[0].video_timestamp_start || 0.1}
            />
          </div>
        );
      }
      return (
        <Container>
          <Row>
            {post.media.length > 1 && (
              <MediaGrid
                media={post.media.map((media) => ({
                  type: media.media_type,
                  src: media.media_url,
                }))}
                direction="vertical"
                lazyImages={post.media.map((media) => ({
                  type: media.media_type,
                  src:
                    media.media_type !== 'video'
                      ? Resize.size(media.media_url, {
                          height: 100,
                          width: 100,
                          cropType: 'crop',
                        })
                      : media.media_url,
                }))}
                thumbnailMedia={post.media.map((media) => ({
                  type: media.media_type,
                  src:
                    media.media_type !== 'video'
                      ? Resize.size(media.media_url)
                      : media.media_url,
                }))}
              />
            )}
            {post.media.length === 1 && (
              <img
                src={post.media[0].media_url}
                style={{ width: '100%', objectFit: 'cover' }}
              />
            )}
          </Row>
        </Container>
      );
    }
    return null;
  };

  return (
    <Card>
      <div
        className="feed-card-item facebook-card"
        style={{ background: darkMode ? '#242526' : 'white' }}
      >
        {renderHeader()}
        <div className="content" style={{ marginBottom: 0 }}>
          <div className="content-right" style={{ width: '100%', padding: 10 }}>
            {(post.content || post.text || '').substring(0, 486) +
              ((post.content || post.text || '').length > 486
                ? ' ... See more'
                : '')}
          </div>
          <div className="clearfix" />
        </div>
        {renderInnerMedia()}
        <div style={{ display: 'flex', flexDirection: 'row', padding: 10 }}>
          <div style={{ flex: 1, textAlign: 'center' }}>
            <img
              src={require('./images/like.png')}
              style={{
                height: 20,
                width: 20,
                marginRight: 15,
                filter: `invert(${darkMode ? 1 : 0})`,
              }}
            />
            {'Like'}
          </div>
          <div style={{ flex: 1, textAlign: 'center' }}>
            <img
              src={require('./images/comment.png')}
              style={{
                height: 20,
                width: 20,
                marginRight: 15,
                filter: `invert(${darkMode ? 1 : 0})`,
              }}
            />
            {'Comment'}
          </div>
          <div style={{ flex: 1, textAlign: 'center' }}>
            <img
              src={require('./images/share.png')}
              style={{
                height: 20,
                width: 20,
                marginRight: 15,
                filter: `invert(${darkMode ? 1 : 0})`,
              }}
            />
            {'Share'}
          </div>
        </div>
      </div>
    </Card>
  );
};

FacebookSocialCard.propTypes = {
  post: PropTypes.object.isRequired,
};

export default FacebookSocialCard;
