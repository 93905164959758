import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import PrivateRoute from '@/components/PrivateRoute';
import InstallPage from '@/pages/install';

const routes = [
  {
    path: '/',
    component: React.lazy(() => import('./pages')),
  },
  {
    path: '/accounts',
    component: React.lazy(() => import('./pages/accounts')),
  },
  {
    path: '/billing',
    component: React.lazy(() => import('./pages/billing')),
  },
  {
    path: '/cigars',
    component: React.lazy(() => import('./pages/cigars')),
  },
  {
    path: '/cigars/:id',
    component: React.lazy(() => import('./pages/cigars/[id]')),
  },
  {
    path: '/directory',
    component: React.lazy(() => import('./pages/directory')),
  },
  {
    path: '/events',
    component: React.lazy(() => import('./pages/events')),
  },
  {
    path: '/fulfillments',
    component: React.lazy(() => import('./pages/fulfillments')),
  },
  {
    path: '/insights',
    component: React.lazy(() => import('./pages/insights')),
  },
  {
    path: '/insights/cigar-report',
    component: React.lazy(() => import('./pages/insights/cigar-report')),
  },
  {
    path: '/insights/manufacturer-consumer-report',
    component: React.lazy(() => import('./pages/insights/manufacturer-consumer-report')),
  },
  {
    path: '/insights/top-cigars',
    component: React.lazy(() => import('./pages/insights/top-cigars')),
  },
  {
    path: '/insights/top-retailers',
    component: React.lazy(() => import('./pages/insights/top-retailers')),
  },
  {
    path: '/install',
    component: React.lazy(() => import('./pages/install')),
  },
  {
    path: '/integrations',
    component: React.lazy(() => import('./pages/integrations')),
  },
  {
    path: '/inventory',
    component: React.lazy(() => import('./pages/inventory')),
  },
  {
    path: '/inventory/:id',
    component: React.lazy(() => import('./pages/inventory/[id]')),
  },
  {
    path: '/media',
    component: React.lazy(() => import('./pages/media')),
  },
  {
    path: '/notifications',
    component: React.lazy(() => import('./pages/notifications')),
  },
  {
    path: '/planner',
    component: React.lazy(() => import('./pages/planner')),
  },
  {
    path: '/press',
    component: React.lazy(() => import('./pages/press')),
  },
  {
    path: '/settings',
    component: React.lazy(() => import('./pages/settings')),
  },
  {
    path: '/tools',
    component: React.lazy(() => import('./pages/tools')),
  },
  {
    path: '/upgrade',
    component: React.lazy(() => import('./pages/upgrade')),
  },
];

const AppRoutes = () => (
  <Suspense fallback={<div></div>}>
    <Routes>
      <Route path="/install" element={<InstallPage />} />
      {routes.map(({ path, component: Component }) => (
        <Route
          key={path}
          path={path}
          element={
            <PrivateRoute>
              <Component />
            </PrivateRoute>
          }
        />
      ))}
    </Routes>
  </Suspense>
);

export default AppRoutes;
