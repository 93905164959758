import { IonIcon } from '@ionic/react';
import { sparkles } from 'ionicons/icons';
import React from 'react';

export default function showPremiumPrompt({
  showDialogAlert,
  navigate,
  onClick = () => {},
}) {
  showDialogAlert({
    title: (
      <>
        <IonIcon icon={sparkles} style={{ marginLeft: 10 }} /> This is a premium
        feature
      </>
    ),
    message: 'Would you like to upgrade your Boxpressd for Business plan?',
    buttons: [
      {
        label: 'No, cancel',
        color: 'text',
      },
      {
        label: 'Yes, upgrade',
        color: 'success',
        variant: 'contained',
        onClick: () => {
          onClick();
          navigate('/upgrade');
        },
      },
    ],
  });
}
