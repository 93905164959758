import './VideoDecode.css';

import { BarcodeScanner } from 'dynamsoft-javascript-barcode';
import React from 'react';

class VideoDecode extends React.Component {
  pScanner: Promise<BarcodeScanner> | null = null;

  elRef: React.RefObject<HTMLDivElement> = React.createRef();

  async componentDidMount() {
    try {
      const scanner = await (this.pScanner = BarcodeScanner.createInstance());
      // Should judge if scanner is destroyed after 'await', as in development React runs setup and cleanup one extra time before the actual setup in Strict Mode.
      if (scanner.isContextDestroyed()) return;
      await scanner.setUIElement(this.elRef.current!);
      // Should judge if scanner is destroyed after 'await', as in development React runs setup and cleanup one extra time before the actual setup in Strict Mode.
      if (scanner.isContextDestroyed()) return;
      scanner.onFrameRead = (results) => {
        for (const result of results) {
          console.log(result.barcodeText);
        }
      };
      scanner.onUniqueRead = (txt, result) => {
        if (typeof this.props.onResult === 'function') {
          this.props.onResult(txt, result);
        }
      };
      await scanner.open();
    } catch (ex: any) {
      if (ex.message.indexOf('network connection error')) {
        const customMsg =
          'Failed to connect to Dynamsoft License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.';
        console.log(customMsg);
      }
      throw ex;
    }
  }

  async componentWillUnmount() {
    if (this.pScanner) {
      (await this.pScanner).destroyContext();
      console.log('BarcodeScanner Component Unmount');
    }
  }

  shouldComponentUpdate() {
    // Never update UI after mount, dbrjs sdk use native way to bind event, update will remove it.
    return false;
  }

  render() {
    return (
      <div
        ref={this.elRef}
        className="component-barcode-scanner"
        style={{ backgroundColor: 'black' }}
      >
        <svg className="dce-bg-camera" viewBox="0 0 2048 1792">
          <path d="M1024 672q119 0 203.5 84.5t84.5 203.5-84.5 203.5-203.5 84.5-203.5-84.5-84.5-203.5 84.5-203.5 203.5-84.5zm704-416q106 0 181 75t75 181v896q0 106-75 181t-181 75h-1408q-106 0-181-75t-75-181v-896q0-106 75-181t181-75h224l51-136q19-49 69.5-84.5t103.5-35.5h512q53 0 103.5 35.5t69.5 84.5l51 136h224zm-704 1152q185 0 316.5-131.5t131.5-316.5-131.5-316.5-316.5-131.5-316.5 131.5-131.5 316.5 131.5 316.5 316.5 131.5z"></path>
        </svg>
        <div className="dce-video-container"></div>
        <div className="dce-scanarea"></div>
      </div>
    );
  }
}

export default VideoDecode;
