import './style.scss';

import {
  IonButton,
  IonButtons,
  IonFooter,
  IonIcon,
  IonSearchbar,
  IonToolbar,
} from '@ionic/react';
import {
  CodeOutlined,
  EmailOutlined,
  FacebookOutlined,
  Instagram,
  IosShare,
  LinkOutlined,
  Pinterest,
  Reddit,
  ShareOutlined,
  WhatsApp,
} from '@mui/icons-material';
import { Avatar, Radio } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import axios from 'axios';
import { listOutline, qrCodeOutline } from 'ionicons/icons';
import { isIOS, isMobile } from 'mobile-device-detect';
import QRCode from 'qrcode.react';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

import BottomSheet from '@/components/BottomSheet';
import Carousel from '@/components/Carousel';
import Icon from '@/components/Icon';
import BoxpressdLogoIcon from '@/components/Icon/custom/BoxpressdLogoIcon';
import useToast from '@/components/Toast';
import Placeholder from '@/config/placeholder.config';
import type ShareItem from '@/models/ShareItem';
import { config } from '@/settings';
import { SettingsStore, UserStore } from '@/stores';
import usePromoteUtils from '@/utils/promoteUtils';

const ShareOptionsModal = ({
  url,
  open,
  onClose,
  data,
  feed = true,
}: {
  url: string;
  open: boolean;
  onClose: () => void;
  data: ShareItem;
}) => {
  const user = {};
  const { showToast } = useToast();
  const { showPromoteUtils } = usePromoteUtils();
  const [showQrCode, setShowQrCode] = useState(false);
  const [users, setUsers] = useState([]);

  const darkMode = SettingsStore.useState((s) => s.darkMode);

  const modal = useRef<HTMLIonModalElement>(null);

  useEffect(() => {
    if (user && user.id) {
      axios
        .get(`${config.apiEndPoint}/users/${user.id}/friends`)
        .then((res) => {
          setUsers(res.data);
          UserStore.update((s) => {
            s.friends = res.data;
          });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [user]);

  const shareTo = (service) => {
    // TODO Generate Branch Link from url
    const link = url;
    // createLink(service).then((link) => {
    let shareUrl = '';
    if (service === 'email') {
      window.open(
        `mailto:?body=${
          data.text
            ? encodeURIComponent(data.text)
            : `Check%20out%20${encodeURIComponent(data.title)}%20on%20Boxpressd`
        }.%20Learn%20more%20at%20${link}&subject=Check%20this%20out%20on%20Boxpressd`
      );
      return false;
    }
    if (service === 'facebook') {
      shareUrl = `https://www.facebook.com/sharer.php?u=${link}`;
    } else if (service === 'whatsapp') {
      shareUrl = `whatsapp://send?text=${encodeURIComponent(
        data.text ? data.text : `Check out ${data.title} on Boxpressd`
      )}`;
    } else if (service === 'twitter') {
      shareUrl = `https://twitter.com/share?url=${link}&text=${data.title}`;
    } else if (service === 'reddit') {
      shareUrl = `https://reddit.com/submit?url=${link}&title=${data.title}`;
    } else if (service === 'pinterest') {
      shareUrl = `https://pinterest.com/pin/create/button/?url=${link}&media=${data.image}&description=${data.title}`;
    } else if (service === 'embed') {
      window.open(
        `${config.embedEndPoint}?query=${
          data.url ||
          (data.route && `${config.appUrl}${data.route}`) ||
          window.location.href
        }`,
        '_blank'
      );
      return false;
    }

    const shareWindow = window.open(
      shareUrl,
      'share-popup',
      'height=350,width=600'
    );
    if (shareWindow.focus) {
      shareWindow.focus();
    }
    return false;
    // });
  };

  const mobileOptions = [
    {
      label: 'Boxpressd Feed',
      icon: <BoxpressdLogoIcon />,
      onClick: () => {
        showPromoteUtils(
          data.promote_endpoint,
          data.item,
          () => {
            // addOrUpdatePost(blogArticle, selectedProfileType, selectedProfile.id);
            if (typeof data.onPromoteSuccess === 'function') {
              data.onPromoteSuccess();
            }
            showToast({
              content: 'Successfully added to Boxpressd Feed',
              duration: 6000,
              position: 'bottom',
              color: 'success',
            });
          },
          () =>
            showToast({
              content: 'Unable to add to Boxpressd Feed',
              duration: 6000,
              position: 'bottom',
              color: 'danger',
            })
        );
      },
    },
    {
      label: 'Copy link',
      icon: (
        <LinkOutlined style={{ color: darkMode ? '#dfdfdf' : 'inherit' }} />
      ),
      onClick: () => {
        navigator.clipboard.writeText(url);
        showToast({
          content: 'Link copied to clipboard',
        });
      },
    },
    {
      // FIXME Only include this option if navigator.share is available
      label: 'Share to...',
      icon: isIOS ? (
        <IosShare style={{ color: darkMode ? '#dfdfdf' : 'inherit' }} />
      ) : (
        <ShareOutlined style={{ color: darkMode ? '#dfdfdf' : 'inherit' }} />
      ),
      onClick: () => {
        // TODO Add createLink back
        const link = url;
        if (window.navigator.share) {
          // createLink('navigator').then((link) => {
          console.log('Found navigator.share...');
          window.navigator
            .share({
              title: data.title,
              text: data.text,
              // FIXME Add this back
              // files: imageData
              //   ? [
              //     new File(
              //       [imageData],
              //       `${data.title}.${imageData.type.split('/')[1]}`,
              //       {
              //         type: imageData.type,
              //       }
              //     ),
              //   ]
              //   : undefined,
              url: link,
            })
            .then(() => console.log('Successful share'))
            .catch((error) => console.error('Error sharing', error));
          // });
        }
      },
    },
    {
      label: 'Embed',
      icon: (
        <CodeOutlined style={{ color: darkMode ? '#dfdfdf' : 'inherit' }} />
      ),
      onClick: () => shareTo('embed'),
    },
    {
      label: 'Email',
      icon: (
        <EmailOutlined style={{ color: darkMode ? '#dfdfdf' : 'inherit' }} />
      ),
      onClick: () => shareTo('email'),
    },
    {
      label: 'Facebook',
      icon: (
        <FacebookOutlined style={{ color: darkMode ? '#dfdfdf' : 'inherit' }} />
      ),
      onClick: () => shareTo('facebook'),
    },
    {
      label: 'X',
      icon: (
        <Icon
          vendor="fa"
          name={['fab', 'x-twitter']}
          style={{ color: darkMode ? '#dfdfdf' : 'inherit' }}
        />
      ),
      onClick: () => shareTo('twitter'),
    },
    {
      label: 'Instagram',
      icon: <Instagram style={{ color: darkMode ? '#dfdfdf' : 'inherit' }} />,
      onClick: () => shareTo('instagram'),
    },
    {
      label: 'WhatsApp',
      icon: <WhatsApp style={{ color: darkMode ? '#dfdfdf' : 'inherit' }} />,
      onClick: () => shareTo('whatsapp'),
    },
    {
      label: 'Reddit',
      icon: <Reddit style={{ color: darkMode ? '#dfdfdf' : 'inherit' }} />,
      onClick: () => shareTo('reddit'),
    },
    {
      label: 'Pinterest',
      icon: <Pinterest style={{ color: darkMode ? '#dfdfdf' : 'inherit' }} />,
      onClick: () => shareTo('pinterest'),
    },
  ];

  const desktopOptions = [
    // TODO Remove this when feed = false
    {
      label: 'Boxpressd Feed',
      icon: <BoxpressdLogoIcon />,
      onClick: () => {
        showPromoteUtils(
          data.promote_endpoint,
          data.item,
          () => {
            // addOrUpdatePost(blogArticle, selectedProfileType, selectedProfile.id);
            if (typeof data.onPromoteSuccess === 'function') {
              data.onPromoteSuccess();
            }
            showToast({
              content: 'Successfully added to Boxpressd Feed',
              duration: 6000,
              position: 'bottom',
              color: 'success',
            });
          },
          () =>
            showToast({
              content: 'Unable to add to Boxpressd Feed',
              duration: 6000,
              position: 'bottom',
              color: 'danger',
            })
        );
      },
    },
    {
      label: 'Copy link',
      icon: (
        <LinkOutlined style={{ color: darkMode ? '#dfdfdf' : 'inherit' }} />
      ),
      onClick: () => {
        navigator.clipboard.writeText(url);
        showToast({
          content: 'Link copied to clipboard',
        });
      },
    },
    {
      label: 'Embed',
      icon: (
        <CodeOutlined style={{ color: darkMode ? '#dfdfdf' : 'inherit' }} />
      ),
      onClick: () => shareTo('embed'),
    },
    {
      label: 'Email',
      icon: (
        <EmailOutlined style={{ color: darkMode ? '#dfdfdf' : 'inherit' }} />
      ),
      onClick: () => shareTo('email'),
    },
    {
      label: 'Facebook',
      icon: (
        <FacebookOutlined style={{ color: darkMode ? '#dfdfdf' : 'inherit' }} />
      ),
      onClick: () => shareTo('facebook'),
    },
    {
      label: 'X',
      icon: (
        <Icon
          vendor="fa"
          name={['fab', 'x-twitter']}
          style={{ color: darkMode ? '#dfdfdf' : 'inherit' }}
        />
      ),
      onClick: () => shareTo('twitter'),
    },
    {
      label: 'Instagram',
      icon: <Instagram style={{ color: darkMode ? '#dfdfdf' : 'inherit' }} />,
      onClick: () => shareTo('instagram'),
    },
    {
      label: 'Reddit',
      icon: <Reddit style={{ color: darkMode ? '#dfdfdf' : 'inherit' }} />,
      onClick: () => shareTo('reddit'),
    },
    {
      label: 'Pinterest',
      icon: <Pinterest style={{ color: darkMode ? '#dfdfdf' : 'inherit' }} />,
      onClick: () => shareTo('pinterest'),
    },
  ];

  const options = isMobile? mobileOptions : desktopOptions;

  const searchUsers = async (query) => {
    try {
      const response = await axios.get(
        `${config.apiEndPoint}/users/search?q=${query}`
      );
      setUsers(response.data);
    } catch (err) {
      console.error(err);
    }
  };

  const renderContent = () => (
    <>
      {isMobile && showQrCode && (
        <div style={{ margin: '30px 0', textAlign: 'center' }}>
          <IonIcon
            color="dark"
            icon={listOutline}
            onClick={() => setShowQrCode(false)}
            style={{
              height: 28,
              width: 28,
              cursor: 'pointer',
              position: 'absolute',
              top: 16,
              right: 16,
            }}
          />
          <QRCode
            value={url}
            fgColor={'#17191d'}
            bgColor="transparent"
            size={200}
          />
        </div>
      )}
      {isMobile && !showQrCode && (
        <>
          <IonToolbar mode="ios" className="transparent-toolbar">
            <IonSearchbar
              style={{ marginTop: 15 }}
              onIonInput={(e) => searchUsers(e.target.value)}
              // onClick={() => modal.current?.setCurrentBreakpoint(1)}
            />
            <IonButtons slot="primary">
              <IonButton
                style={{ marginTop: 18 }}
                onClick={() => setShowQrCode(true)}
              >
                <IonIcon icon={qrCodeOutline} color="dark"></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
          <List
            id="options-list"
            style={{
              maxHeight: 300,
              overflowY: 'scroll',
            }}
          >
            {users.map((u, idx) => (
              <ListItem
                key={idx}
                secondaryAction={
                  <Radio
                    edge="end"
                    onChange={() => {}}
                    checked={false}
                    // inputProps={{ 'aria-labelledby': labelId }}
                  />
                }
                onClick={() => {
                  alert(
                    'Coming soon! Share to your friends in Boxpressd Virtual Lounge!'
                  );
                }}
              >
                <Avatar
                  src={u.image_url}
                  style={{ marginRight: 10, height: 30, width: 30 }}
                  alt={u.full_name}
                >
                  <img
                    alt={u.full_name}
                    src={Placeholder.avatar}
                    style={{ height: '100%', width: '100%' }}
                  />
                </Avatar>
                <ListItemText
                  primary={
                    <span
                      className="padded-multiline"
                      style={{ marginLeft: 10 }}
                    >
                      {u.full_name}
                    </span>
                  }
                />
              </ListItem>
            ))}
          </List>
        </>
      )}
      {isMobile && (
        <IonFooter id="options-footer">
          <div className="simple-carousel">
            <div style={{ display: 'flex' }}>
              {options.slice(feed ? 0 : 1).map((option, idx) => (
                <span
                  key={idx}
                  onClick={option.onClick}
                  style={{ padding: 10, minWidth: 80, textAlign: 'center' }}
                >
                  <Avatar
                    style={{
                      margin: 'auto',
                      backgroundColor: darkMode ? '#212529' : '#efefef',
                      color: darkMode ? '#efefef' : '#2b2b2b',
                    }}
                  >
                    {option.icon}
                  </Avatar>
                  <p style={{ fontSize: 12, marginTop: 4 }}>{option.label}</p>
                </span>
              ))}
            </div>
          </div>
        </IonFooter>
      )}
      {!isMobile && (
        <Carousel slidesToScroll={5}>
          {/* @ts-ignore */}
          {options.slice(feed ? 0 : 1).map((option, idx) => (
            <div key={idx}>
              <span onClick={option.onClick} style={{ cursor: 'pointer' }}>
                <Avatar
                  style={{
                    height: 60,
                    width: 60,
                    marginLeft: 10,
                    marginRight: 10,
                    backgroundColor: darkMode ? '#212529' : '#efefef',
                    color: darkMode ? '#efefef' : '#2b2b2b',
                  }}
                >
                  {option.icon}
                </Avatar>
                <p style={{ fontSize: 12, marginTop: 4, textAlign: 'center' }}>
                  {option.label}
                </p>
              </span>
            </div>
          ))}
        </Carousel>
      )}
    </>
  );

  if (isMobile) {
    return (
      <BottomSheet
        forwardRef={modal}
        open={open}
        onClose={onClose}
        className="auto-height-sheet"
        initialBreakpoint={1}
        breakpoints={[0, 1]}
      >
        {renderContent()}
      </BottomSheet>
    );
  }
  return (
    <Modal isOpen={open} toggle={onClose} style={{ width: 480 }}>
      <div className="modal-header" style={{ padding: 12 }}>
        <Button className="close" color="" onClick={onClose}>
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody style={{ padding: 4 }}>{renderContent()}</ModalBody>
      <ModalFooter style={{ padding: 12 }}>
        <Button color="text" onClick={onClose}>
          {'Cancel'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ShareOptionsModal;
