import { AppStore } from '@/stores';

export const openLightbox = (media, index, type) => {
  AppStore.update((s) => {
    s.lightbox = {
      media,
      slide: index,
      type,
    };
    s.toggleLightbox = !s.toggleLightbox;
  });
};
