import axios from 'axios';
import Hashids from 'hashids';
import React, { useEffect, useState } from 'react';
import Linkify from 'react-linkify';
import sanitizeHtml from 'sanitize-html';

import { customEmojiForShortname } from '@/components/MentionsTextArea';
import UrlPreview from '@/components/UrlPreview';
import VideoSource from '@/components/VideoSource';
import { config } from '@/settings';

const hashids = new Hashids('', 12);

const ReactDOMServer = require('react-dom/server');

const linkify = (text) =>
  ReactDOMServer.renderToStaticMarkup(<Linkify>{text}</Linkify>);

export const sanitizeText = (text) => {
  const sanitizeConf = {
    allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p', 'h1', 'img'],
    allowedClasses: {
      img: ['emoji'],
    },
    allowedAttributes: { a: ['href', 'data-*'], img: ['src', 'style'] },
    // allowedAttributes: { a: ['href'] }, // FIXME Can we only allow our own custom emojis? - https://www.npmjs.com/package/sanitize-html
    // allowedIframeHostnames: [
    //   'www.youtube.com',
    //   'www.vimeo.com',
    //   'www.spotify.com',
    //   'open.spotify.com',
    //   'www.giphy.com',
    //   'embed.giphy.com',
    // ],
  };
  // console.debug(sanitizeHtml(text, sanitizeConf));
  // return sanitizeHtml(linkify(text), sanitizeConf);
  // FIXME See main code - this should be applied to linkify as options (switch to linkify-react instead of react-linkify)
  if (/\.(?:png|jpg|jpeg|gif|svg)/.test(text)) {
    return sanitizeHtml(text, sanitizeConf);
  }
  return linkify(sanitizeHtml(text, sanitizeConf));
};

const getGalleryHtml = (url) =>
  `<a class="rui-gallery-item" data-keyboard="true" data-touch="true" data-close-existing="true" data-auto-focus="true" href="${url}" data-fancybox="comment-gallery"><img src="${url}" style="width: 100%" /></a>`;

function RichCard(props) {
  const [meta, setMeta] = useState(props.meta);
  const [iframe, setIframe] = useState(null);
  const [video, setVideo] = useState(null);
  const [videoThumbnail, setVideoThumbnail] = useState(null);
  const [content, setContent] = useState('');

  // https://stackoverflow.com/questions/48025373/dangerouslysetinnerhtml-and-link
  // FIXME tagName does not exist on the empty target object - why is target empty?
  const navigate = (e) => {
    // e.preventDefault();
    e.persist();
    console.debug('Testing for anchor element...');
    const target = e.target || e.srcElement;
    // console.debug(JSON.stringify(target));
    if (target.tagName === 'A') {
      console.debug('Found anchor element!');
      const href = target.getAttribute('href');
      if (href) {
        console.debug('Found href!');
        event.preventDefault();
        // FIXME Getting error about using withRouter outside of route when opening CreateAdModal - won't these be external links anyway tho?
        // props.history.push(href);
        window.location.href = href;
      }
    }
  };

  const parseContent = (c) => {
    let parsedContent = c;
    // FIXME This all can probably be simplified
    const regex = parsedContent.match(
      /(\[((@|~)[a-zA-Z0-9&+\s\(\)\"\.]+)\|([a-zA-Z0-9\s]+:\d+)\])/g
    );
    const emojiRegex = parsedContent.match(/:(\D+):/g);

    if (regex) {
      console.debug('regex:');
      console.debug(regex);
      for (const fullMatch of regex) {
        console.debug('fullMatch:');
        console.debug(fullMatch);
        if (fullMatch.indexOf('userId') !== -1) {
          const userRegex = fullMatch.match(
            /(@[a-zA-Z0-9&+\s\.]+)|(userId:\d+)/g
          );
          if (userRegex.length) {
            console.debug('userRegex:');
            console.debug(userRegex);
            // FIXME There has to be a way to use react-router instead of anchor tags for these - I don't like that it reloads the page when clicking these
            parsedContent = parsedContent.replace(
              fullMatch,
              `<a href="${config.appEndPoint}/users/${hashids.encode(
                parseInt(userRegex[1].replace('userId:', ''))
              )}"><strong>${userRegex[0].replace('@', '')}</strong></a>`
            );
          }
        }

        if (fullMatch.indexOf('venueId') !== -1) {
          const venueRegex = fullMatch.match(
            /(@[a-zA-Z0-9&+\s\.]+)|(venueId:\d+)/g
          );
          if (venueRegex.length) {
            parsedContent = parsedContent.replace(
              fullMatch,
              `<a href="${config.appEndPoint}/venues/${hashids.encode(
                parseInt(venueRegex[1].replace('venueId:', ''))
              )}"><strong>${venueRegex[0].replace('@', '')}</strong></a>`
            );
          }
        }

        if (fullMatch.indexOf('brandId') !== -1) {
          const brandRegex = fullMatch.match(
            /(@[a-zA-Z0-9&+\s\.]+)|(brandId:\d+)/g
          );
          if (brandRegex.length) {
            parsedContent = parsedContent.replace(
              fullMatch,
              `<a href="${config.appEndPoint}/brands/${hashids.encode(
                parseInt(brandRegex[1].replace('brandId:', ''))
              )}"><strong>${brandRegex[0].replace('@', '')}</strong></a>`
            );
          }
        }

        if (fullMatch.indexOf('cigarId') !== -1) {
          const cigarRegex = fullMatch.match(
            /((@|~)[a-zA-Z0-9&+\s\.]+)|(cigarId:\d+)/g
          );
          if (cigarRegex.length) {
            console.debug('cigarRegex:');
            console.debug(cigarRegex);
            parsedContent = parsedContent.replace(
              fullMatch,
              `<a href="${config.appEndPoint}/cigars/${hashids.encode(
                parseInt(cigarRegex[1].replace('cigarId:', ''))
              )}"><strong>${cigarRegex[0].replace('@', '')}</strong></a>`
            );
          }
        }

        if (fullMatch.indexOf('productId') !== -1) {
          const productRegex = fullMatch.match(
            /(@[a-zA-Z0-9&+\s\.\(\)\"\.]+)|(productId:\d+)/g
          );
          if (productRegex.length) {
            console.debug('productRegex:');
            console.debug(productRegex);
            parsedContent = parsedContent.replace(
              fullMatch,
              `<a href="${config.appEndPoint}/products/${hashids.encode(
                parseInt(productRegex[1].replace('productId:', ''))
              )}"><strong>${productRegex[0].replace('@', '')}</strong></a>`
            );
          }
        }
      }
    }

    if (emojiRegex && emojiRegex.length) {
      console.debug('emojiRegex:');
      console.debug(emojiRegex);
      const emoji = customEmojiForShortname(emojiRegex[0].replace(/:/g, ''));
      console.debug('Selected Emoji:');
      console.debug(emoji);
      if (emoji && emoji.imageUrl) {
        parsedContent = parsedContent.replace(
          emojiRegex[0],
          `<img src="${emoji.imageUrl}" class="emoji" data-shortname="${emoji.colons}" />`
        );
      }
    }
    return parsedContent;
  };

  useEffect(() => {
    const { children } = props;
    const innerText = children.toString(); // FIXME Do we have to stringify it?
    const urlMatcher =
      '([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?';
    const urlPattern = new RegExp(urlMatcher);
    const imgPattern = new RegExp('<img([\\w\\W]+?)/>');
    console.debug('Comment content:');
    console.debug(JSON.stringify(innerText));
    if (props.meta) {
      console.log('Meta:', props.meta);
      if (!imgPattern.test(innerText) && urlPattern.test(innerText)) {
        const url = innerText.match(urlMatcher)[0];
        setContent(innerText.replace(url, ''));
      } else {
        setContent(innerText);
      }
      if (props.meta.ogVideo && props.meta.ogVideo.url) {
        console.log('Got meta video!');
        setVideo(props.meta.ogVideo);
        setVideoThumbnail(props.meta.ogImage);
      }
    } else if (!props.hideMeta && !props.meta) {
      setMeta(null);
      if (!imgPattern.test(innerText) && urlPattern.test(innerText)) {
        const url = innerText.match(urlMatcher)[0];
        console.debug('Text has URL...');
        console.debug(innerText.match(urlMatcher));
        if (url.indexOf('spotify') !== -1) {
          setIframe(
            <iframe
              src={url}
              width="100%"
              height="80"
              frameBorder="0"
              allowTransparency="true"
              allow="encrypted-media"
            />
          );
          setContent(innerText.replace(url, ''));
        } else {
          axios
            .get(`${config.mediaEndPoint}/meta`, {
              params: { url },
            })
            .then((response) => {
              console.debug(response);
              if (response.data.ogVideo) {
                setVideo(response.data.ogVideo);
                setVideoThumbnail(response.data.ogImage);
              }
              // FIXME Set these on the store so they can be reused instead of pulled again - probably just map them to the URL
              setMeta(response.data);
              setContent(innerText.replace(url, ''));
            })
            .catch((err) => {
              console.debug(`Failed to get meta data for ${url}`);
              console.debug(err); // Do anything?
              // FIXME This should really get checked first - no need for the meta call, it just slows things down
              if (
                url.indexOf('.png') !== -1 ||
                url.indexOf('.jpg') !== -1 ||
                url.indexOf('.jpeg') !== -1 ||
                url.indexOf('.gif') !== -1
              ) {
                setContent(innerText.replace(url, getGalleryHtml(url)));
              } else {
                setContent(innerText);
              }
            });
        }
      } else if (imgPattern.test(innerText)) {
        const original = innerText.match('<img([\\w\\W]+?)/>')[0];
        let url = innerText.match('<img([\\w\\W]+?)/>')[1];
        url = url
          .replace('src="', '')
          .replace('"', '')
          .replace('{boxpressd_cdn}', config.cdnUrl)
          .trim();
        setContent(innerText.replace(original, getGalleryHtml(url)));
      } else {
        setContent(innerText.replace('{boxpressd_cdn}', config.cdnUrl));
      }
    } else {
      setContent(innerText.replace('{boxpressd_cdn}', config.cdnUrl));
    }
  }, [props.meta, props.children]);

  return (
    <div className="rich-content" onClick={navigate}>
      {video && (
        <VideoSource
          videoSrc={video.url}
          scaleWidth="100%"
          scaleHeight={244}
          placeholder={videoThumbnail && videoThumbnail.url}
          title={meta && meta.ogTitle}
        />
      )}
      <div
        dangerouslySetInnerHTML={{
          __html: parseContent(sanitizeText(content)),
        }}
        style={{ whiteSpace: 'pre-wrap', fontSize: '14px' }}
      />
      {meta && !video ? <UrlPreview {...meta} compact={props.compact} /> : null}
      {iframe}
    </div>
  );
}

export default RichCard;
