import { useIonAlert } from '@ionic/react';
import { isMobile } from 'mobile-device-detect';
import { Button } from 'reactstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const colorForRole = (role) => {
  if (role === 'destructive') {
    return 'danger';
  }
  if (role === 'confirm') {
    return 'brand';
  }
  return 'text';
};

const useDialogAlert = () => {
  const [presentAlert] = useIonAlert();
  const MySwal = withReactContent(Swal);

  const showDialogAlert = (options) => {
    if (isMobile) {
      // For mobile devices
      presentAlert({
        header: options.title,
        subHeader: options.subtitle,
        message: options.message,
        buttons: (options.buttons || []).map((button) => ({
          text: button.label,
          role: button.role || 'confirm', // cancel, confirm, destructive
          handler: () => {
            if (button.onClick) {
              button.onClick();
            }
          },
        })),
        onDidDismiss: options.onClose,
      });
    } else {
      MySwal.fire({
        title: options.title,
        html: options.message ? `<p>${options.message}</p>` : undefined,
        showCancelButton: !options.buttons || options.buttons.length === 0,
        cancelButtonText: 'Ok',
        showConfirmButton: false,
        footer: (
          <div>
            {options.buttons &&
              options.buttons.map((button) => (
                <Button
                  key={button.label}
                  color={button.color || colorForRole(button.role)}
                  onClick={() => {
                    if (button.onClick) {
                      button.onClick();
                    }
                    MySwal.close();
                  }}
                >
                  {button.label}
                </Button>
              ))}
          </div>
        ),
      });
    }
  };

  return { showDialogAlert };
};

// FIXME Can we merge this logic?
// ModalDialog.show = (props) => {
//   let divTarget = document.getElementById('react-modal-dialog');
//   if (divTarget) {
//     // Rerender - the mounted ModalDialog
//     ReactDOM.render(<ModalDialog show {...props} />, divTarget);
//   } else {
//     // Mount the ModalDialog component
//     // FIXME Something to add to prevent the back divs from scrolling?
//     // document.body.children[0].classList.add('react-confirm-alert-blur');
//     divTarget = document.createElement('div');
//     divTarget.id = 'react-modal-dialog';
//     document.body.appendChild(divTarget);
//     ReactDOM.render(<ModalDialog show {...props} />, divTarget);
//   }
// };
//
// ModalDialog.close = () => {
//   const target = document.getElementById('react-modal-dialog');
//   if (target) {
//     ReactDOM.unmountComponentAtNode(target);
//     target.parentNode.removeChild(target);
//   }
// };

export default useDialogAlert;
