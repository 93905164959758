import Avatar from '@mui/material/Avatar';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import axios from 'axios';
import Hashids from 'hashids';
import { isMobile } from 'mobile-device-detect';
import React, { useEffect, useState } from 'react';
import GoogleAutocomplete from 'react-google-autocomplete';
import {
  Button,
  Col,
  Collapse,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Spinner,
} from 'reactstrap';

import Dropzone from '@/components/Dropzone';
import Icon from '@/components/Icon';
import ModalView from '@/components/ModalView';
import { getProfileImage } from '@/components/ProfileSelect';
import { apiKeys, config } from '@/settings';
import { AppStore } from '@/stores';

const hashids = new Hashids('', 12);

const date = new Date();
let dateMonth = date.getMonth() + 1;
let dateDay = date.getDate();
let dateDayNext = date.getDate() + 1;

if (dateMonth < 10) {
  dateMonth = `0${dateMonth}`;
}
if (dateDay < 10) {
  dateDay = `0${dateDay}`;
}
if (dateDayNext < 10) {
  dateDayNext = `0${dateDayNext}`;
}
const dateTomorrow = String(
  `${date.getFullYear()}-${dateMonth}-${dateDayNext}`
);
export const dateToday = String(
  `${date.getFullYear()}-${dateMonth}-${dateDay}`
);

const addressFromComponents = (comp) => {
  const address = {};

  for (let i = 0; i < comp.length; i++) {
    const obj = comp[i];
    // console.log(obj.types);
    if (obj.types.indexOf('street_number') !== -1) {
      address.street_number = obj.long_name;
    } else if (obj.types.indexOf('route') !== -1) {
      address.street = `${address.street_number} ${obj.long_name}`;
    } else if (
      obj.types.indexOf('locality') !== -1 ||
      obj.types.indexOf('postal_town') !== -1
    ) {
      address.city = obj.long_name;
    } else if (obj.types.indexOf('administrative_area_level_1') !== -1) {
      address.state = obj.short_name;
    } else if (obj.types.indexOf('postal_code') !== -1) {
      address.zip = obj.short_name;
    } else if (obj.types.indexOf('country') !== -1) {
      address.country = obj.long_name;
    }
  }
  return address;
};

function CreateEventModal(props) {
  const selectedProfile = AppStore.useState((s) => s.selectedProfile);
  const selectedProfileType = AppStore.useState((s) => s.selectedProfileType);
  const [event, setEvent] = useState({
    coordinator_type: selectedProfileType,
    [`${selectedProfileType}_id`]: selectedProfile.id,
  });
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedLocationType, setSelectedLocationType] = useState('other');
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedUrl, setSelectedUrl] = useState(null);
  const [selectedTickets, setSelectedTickets] = useState([]);
  const [showTicketsModal, setShowTicketsModal] = useState(false);

  useEffect(() => {
    if (props.selectedEvent) {
      setEvent(props.selectedEvent);
      if (props.selectedEvent.is_online) {
        setSelectedLocationType('virtual');
      } else if (selectedProfileType === 'venue' && selectedProfile && selectedProfile.location) {
        setSelectedLocationType('venue');
      }
      if (props.selectedEvent.url) {
        setSelectedUrl(props.selectedEvent.url);
      }
      if (props.selectedEvent.admission_options) {
        setSelectedTickets(props.selectedEvent.admission_options);
      }
    } else if (selectedProfileType === 'venue' && selectedProfile && selectedProfile.location) {
      setSelectedLocationType('venue');
    }
  }, [props.selectedEvent, selectedProfileType]);

  const updateInput = (key) => (e) => {
    const updatedEvent = { ...event };
    updatedEvent[key] = e.target.value;
    setEvent(updatedEvent);
  };

  const handleSelectedTicketChange = (index, type) => (e) => {
    console.debug('Updating option for type: ', type);
    console.debug(e);
    const tickets = [...selectedTickets];
    const ticket = tickets[index] || {};
    if (e.target && e.target.value) {
      ticket[type] = e.target.value;
    } else {
      ticket[type] = '';
    }
    tickets[index] = ticket;
    setSelectedTickets(tickets);
  };

  const saveTickets = (eventId) => {
    console.log('Saving tickets...');
    console.log(selectedTickets);
    axios
      .post(
        `${config.apiEndPoint}/events/${eventId}/tickets`,
        selectedTickets.map((ticket) => ({
          ...ticket,
          event_id: eventId,
        }))
      )
      .then((res) => {
        console.log(res.data);
      })
      .catch(() => {
        alert('There was a problem saving the tickets for this event.');
      });
  };

  const onSave = () => {
    event.is_online = false;
    event.url = undefined;
    event.formatted_address = undefined;
    event.city = undefined;
    event.state = undefined;
    event.location_id = undefined;
    // FIXME This needs to validate that the required fields are included
    event.start_timestamp =
      selectedStartDate || event.start_timestamp || `${dateToday}T12:00:00`;
    event.end_timestamp =
      selectedEndDate || event.end_timestamp || `${dateToday}T12:00:00`;
    if (selectedLocationType === 'venue' && selectedProfileType === 'venue') {
      event.location_id = selectedProfile.id;
    }
    if (selectedLocationType === 'other' && selectedAddress) {
      event.formatted_address = selectedAddress.formatted_address;
      event.city = selectedAddress.city;
      event.state = selectedAddress.state;
    }
    if (selectedLocationType === 'virtual' && selectedUrl) {
      event.is_online = true;
      event.url = selectedUrl;
    }
    // console.log('Saving event:');
    // console.log(event);
    props.onSave && props.onSave(event);
    if (event.id) {
      axios
        .put(`${config.apiEndPoint}/events/${event.id}`, event)
        .then((res) => {
          props.onEventSaved && props.onEventSaved(res.data);
          if (selectedTickets) {
            saveTickets(event.id);
          }
          CreateEventModal.close();
        })
        .catch((err) => {
          console.error(err);
          props.onEventSaved && props.onEventSaved();
          // ModalDialog.show({
          //   title: 'Unable to update',
          //   message: 'There was a problem saving the event',
          // });
        });
    } else {
      axios
        .post(`${config.apiEndPoint}/events`, event)
        .then((res) => {
          props.onEventSaved && props.onEventSaved(res.data);
          if (selectedTickets) {
            saveTickets(res.data.id);
          }
          CreateEventModal.close();
        })
        .catch((err) => {
          console.error(err);
          props.onEventSaved && props.onEventSaved();
          // ModalDialog.show({
          //   title: 'Unable to save',
          //   message: 'There was a problem saving the event',
          // });
        });
    }
  };

  const renderHeader = () => (
    <div style={{ display: 'flex' }}>
      <Avatar
        src={
          selectedProfile &&
          (getProfileImage(selectedProfile.profile_image_url) ||
            getProfileImage(selectedProfile.logo_image_url))
        }
        style={{ marginRight: 10 }}
      >
        {selectedProfile?.name?.charAt(0)}
      </Avatar>
      <span style={{ fontWeight: 700 }}>{selectedProfile.name}</span>
      <span></span>
    </div>
  );

  const renderTicketRow = (index) => {
    const ticket = selectedTickets[index] || {};
    console.debug('Selected ticket:');
    console.debug(ticket);
    return (
      <div
        className="row"
        style={{
          marginLeft: 10,
          marginRight: 10,
        }}
      >
        <div className="col-md-7">
          <span>Ticket Name</span>
          <input
            className="form-control"
            value={ticket.name || ''}
            onChange={handleSelectedTicketChange(index, 'name')}
          />
        </div>
        <div className="col-md-4">
          <span>Ticket Price (Enter 0 for Free)</span>
          <input
            className="form-control"
            value={ticket.price || ''}
            onChange={handleSelectedTicketChange(index, 'price')}
          />
        </div>
        {ticket.id && (
          <div
            style={{
              lineHeight: '60px',
              marginTop: 8,
            }}
          >
            <Icon
              name="trash"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                const tickets = [...selectedTickets];
                for (let i = tickets.length - 1; i > -1; i--) {
                  if (tickets[i].id === ticket.id) {
                    tickets.splice(i, 1);
                  }
                }
                setSelectedTickets(tickets);
              }}
            />
          </div>
        )}
        <div className="col-md-11" style={{ marginTop: 10 }}>
          <span>Purchase URL (Optional)</span>
          <input
            className="form-control"
            value={ticket.url}
            onChange={handleSelectedTicketChange(index, 'url')}
          />
        </div>
      </div>
    );
  };

  const renderTickets = () => {
    const tickets = [...selectedTickets];
    tickets.push({}); // Adds an extra empty object to the end
    return (
      <div className="linked-cigar-block">
        {tickets.map((ticket, index) => renderTicketRow(index))}
      </div>
    );
  };

  const renderTicketsModal = () => (
    <Modal
      isOpen={showTicketsModal}
      onClosed={() => setShowTicketsModal(false)}
      toggle={() => setShowTicketsModal(false)}
      style={{ maxWidth: 715 }}
    >
      <div className="modal-header">
        <h5 className="modal-title">Edit Tickets / Admission Options</h5>
      </div>
      <div className="modal-body md">{renderTickets()}</div>
      <div className="modal-footer">
        <Button onClick={() => setShowTicketsModal(false)}>Cancel</Button>
        <Button
          onClick={() => setShowTicketsModal(false)}
          variant="contained"
          color="secondary"
        >
          Save
        </Button>
      </div>
    </Modal>
  );

  const renderContent = () => (
    <>
      <FormGroup>
        <Label for="dropzone">Images</Label>
        {event.image_url ? (
          <div style={{ textAlign: 'center' }}>
            <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
              <img
                src={event.image_url}
                alt=""
                style={{ width: isMobile ? '100%' : 300, float: 'left' }}
              />
            </div>
            <div
              style={{ marginLeft: 20, cursor: 'pointer' }}
              onClick={() => {
                setEvent({
                  ...event,
                  image_url: null,
                });
              }}
            >
              {'Remove image'}
            </div>
          </div>
        ) : (
          <Dropzone
            id="dropzone"
            uploadToS3
            multi={false}
            path={`${selectedProfileType}s/${hashids.encode(
              selectedProfile.id
            )}/events`}
            onAdded={async (file) => {
              console.debug(file);
              setEvent({
                ...event,
                image_url: file.media_url,
              });
            }}
            onChange={(files) => {
              // eslint-disable-next-line no-console
              console.debug(files);
            }}
            style={{ minHeight: 'auto' }}
          />
        )}
        <span style={{ color: '#999999' }}>
          Your image needs to be at least 500 x 500 pixels.
        </span>
      </FormGroup>
      <FormGroup>
        <Label for="event_name">Event Name</Label>
        <Input
          id="event_name"
          value={event.name}
          onChange={updateInput('name')}
        />
      </FormGroup>
      <Collapse isOpen={true}>
        <FormGroup>
          <Label for="event-location">Event Location</Label>
          <RadioGroup
            row
            id="event-location"
            aria-label="location"
            name="event-location"
            value={selectedLocationType}
            onChange={(e) => setSelectedLocationType(e.target.value)}
          >
            {selectedProfileType === 'venue' &&
              selectedProfile.location &&
              selectedProfile.location.formatted_address && (
                <FormControlLabel
                  value="venue"
                  control={<Radio />}
                  label="At this location"
                />
              )}
            <FormControlLabel
              value="other"
              control={<Radio />}
              label={
                selectedProfileType === 'venue' &&
                selectedProfile.location &&
                selectedProfile.location.formatted_address
                  ? 'At other location'
                  : 'In-Person (at address)'
              }
            />
            <FormControlLabel
              value="virtual"
              control={<Radio />}
              label="Virtual / online"
            />
          </RadioGroup>
        </FormGroup>
        {selectedLocationType === 'venue' && (
          <div style={{ marginBottom: 24 }}>
            {selectedProfile.location && selectedProfile.location.formatted_address}
          </div>
        )}
        {selectedLocationType === 'other' && (
          <FormGroup>
            <Label for="event-address">Event Address</Label>
            <GoogleAutocomplete
              apiKey={apiKeys.google}
              style={{ width: '100%' }}
              className="form-control"
              onPlaceSelected={(place) => {
                console.log(place);

                const lat = place.geometry.location.lat();
                const lng = place.geometry.location.lng();

                const comp = place.address_components;
                console.log(comp);

                const address = addressFromComponents(comp);
                address.latitude = lat;
                address.longitude = lng;
                address.formatted_address = place.formatted_address;

                setSelectedAddress(address);
              }}
              types={[]}
              fields={[
                'address_components',
                'geometry.location',
                'place_id',
                'formatted_address',
                'photos',
              ]}
              // componentRestrictions={{country: "us"}}
            />
          </FormGroup>
        )}
        {selectedLocationType === 'virtual' && (
          <FormGroup>
            <Label for="event-link">Event Link</Label>
            <Input
              id="event-link"
              value={selectedUrl}
              onChange={(e) => setSelectedUrl(e.target.value)}
              placeholder="http://"
            />
          </FormGroup>
        )}
      </Collapse>
      <FormGroup>
        <Label for="description">Description</Label>
        <textarea
          className="form-control"
          id="description"
          style={{ height: 125 }}
          value={event.description}
          onChange={updateInput('description')}
        />
      </FormGroup>
      <Row>
        <Col>
          <FormGroup>
            <Label for="description">Start Time</Label>
            <div>
              {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
              {/*  <DateTimePicker */}
              {/*    ampm */}
              {/*    // disableToolbar */}
              {/*    // variant="inline" */}
              {/*    variant="outlined" */}
              {/*    // format="MM/dd/yyyy" */}
              {/*    // margin="normal" */}
              {/*    // id="date-picker-inline" */}
              {/*    // label="Date picker inline" */}
              {/*    // className="form-control" */}
              {/*    value={selectedStartDate || event.start_timestamp || `${dateToday}T12:00:00`} */}
              {/*    onChange={(value) => { */}
              {/*      setSelectedStartDate(value); */}
              {/*    }} */}
              {/*    KeyboardButtonProps={{ */}
              {/*      'aria-label': 'change date', */}
              {/*    }} */}
              {/*  /> */}
              {/* </MuiPickersUtilsProvider> */}
            </div>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="description">End Time</Label>
            <div>
              {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
              {/*  <DateTimePicker */}
              {/*    ampm */}
              {/*    // disableToolbar */}
              {/*    // variant="inline" */}
              {/*    variant="outlined" */}
              {/*    // format="MM/dd/yyyy" */}
              {/*    // margin="normal" */}
              {/*    // id="date-picker-inline" */}
              {/*    // label="Date picker inline" */}
              {/*    // className="form-control" */}
              {/*    value={selectedEndDate || event.end_timestamp || `${dateToday}T12:00:00`} */}
              {/*    onChange={(value) => { */}
              {/*      setSelectedEndDate(value); */}
              {/*    }} */}
              {/*    KeyboardButtonProps={{ */}
              {/*      'aria-label': 'change date', */}
              {/*    }} */}
              {/*  /> */}
              {/* </MuiPickersUtilsProvider> */}
            </div>
          </FormGroup>
        </Col>
      </Row>
      {isMobile && (
        <div style={{ marginLeft: -20, marginRight: -20 }}>
          {renderTickets()}
        </div>
      )}
      {renderTicketsModal()}
    </>
  );

  if (isMobile) {
    return (
      <ModalView
        open={props.open}
        onClick={() => {}}
        onClose={props.onClose}
        zIndex={1200}
        rightButtons={[
          {
            title: 'Publish',
            onClick: onSave,
          },
        ]}
      >
        <div style={{ padding: 20 }}>
          {selectedProfile && renderHeader()}
          {selectedProfile && renderContent()}
        </div>
      </ModalView>
    );
  }
  return (
    <Modal
      isOpen={props.open}
      toggle={props.close}
      style={{ maxWidth: 600 }}
      fade
    >
      <div className="modal-header">
        {/* FIXME Should we allow the venues to host it some place other than their venue? */}
        <h5 className="modal-title h2">{`New Event at ${selectedProfile.name}`}</h5>
        <Button className="close" color="" onClick={props.onClose}>
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody>{selectedProfile && renderContent()}</ModalBody>
      <ModalFooter>
        <Button
          onClick={() => setShowTicketsModal(true)}
          style={{ position: 'absolute', left: 30 }}
        >
          Manage Tickets
        </Button>
        <Button color="text" onClick={props.onClose}>
          Cancel
        </Button>
        <Button disabled={props.loading} color="brand" onClick={onSave}>
          {props.loading && (
            <Spinner
              type="border"
              color="light"
              size="sm"
              style={{ marginRight: 10 }}
            />
          )}
          {props.loading ? 'Publishing' : 'Publish Event'}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

CreateEventModal.show = (props) => {
  AppStore.update((s) => {
    s.showEventModal = true;
    s.eventModalProps = props;
  })
};

CreateEventModal.close = () => {
  AppStore.update((s) => {
    s.showEventModal = false;
    s.eventModalProps = {};
  })
};

export default CreateEventModal;
