const getCommentById = (commentId, comments) => {
  for (let j = 0; j < comments.length; j++) {
    if (parseInt(comments[j].id) === parseInt(commentId)) {
      return comments[j];
    }
  }
  return null;
};

const getCommentDepth = (originalComment, comments) => {
  let comment = { ...originalComment };
  console.log('Comment:');
  console.log(comment);
  let depthLevel = 0;
  while (comment.parent_id > 0) {
    comment = getCommentById(comment.parent_id, comments);
    depthLevel++;
  }
  return depthLevel;
};

export function threadComments(originalComments) {
  const comments = [...originalComments];
  let maxDepth = 0;
  for (let i = 0; i < comments.length; i += 1) {
    comments[i].children = [];
    comments[i].depth = getCommentDepth(comments[i], comments);
    if (comments[i].depth > maxDepth) {
      maxDepth = getCommentDepth(comments[i], comments);
    }
  }
  for (let i = maxDepth; i > 0; i--) {
    for (let j = 0; j < comments.length; j++) {
      if (comments[j].depth === i) {
        for (let k = 0; k < comments.length; k++) {
          if (parseInt(comments[j].parent_id) === parseInt(comments[k].id)) {
            comments[k].children.push(comments[j]);
          }
        }
      }
    }
  }
  for (let i = comments.length - 1; i >= 0; i--) {
    if (parseInt(comments[i].parent_id) > 0) {
      comments.splice(i, 1);
    }
  }
  return comments;
}
