import { Store } from 'pullstate';

export const Storage = {
  setPreference: (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  },
  getPreference: (key, defaultValue) => {
    if (localStorage.getItem(key)) {
      console.log('Getting prefs for key:', key);
      try {
        return JSON.parse(localStorage.getItem(key) || '');
      } catch (e) {
        return localStorage.getItem(key);
      }
    }
    return defaultValue || null;
  },
  removePreference: (key) => {
    localStorage.removeItem(key);
  },
  clearPreferences: () => {
    localStorage.clear();
  },
};

const mq = window.matchMedia('(prefers-color-scheme: dark)');
const darkMode = Storage.getPreference('dark_mode', false);

export const UserStore = new Store({
  user: Storage.getPreference('user', null),
  firebaseUser: null,
  token: Storage.getPreference('boxpressd_token_id', null),
});

export const AppStore = new Store({
  loading: true,
  selectedProfile: Storage.getPreference('selectedProfile', {}), // The active profile selection (venue, brand, or similar object)
  selectedProfileType: Storage.getPreference('selectedProfileType', 'venue'),
  premium: Storage.getPreference('boxpressdPremium', false),
  profiles: {
    venues: Storage.getPreference('boxpressdBusinessVenues', []),
    brands: Storage.getPreference('boxpressdBusinessBrands', []),
  },
  lightbox: {
    slide: 0,
    media: [],
    type: 'image',
  },
  toggleLightbox: false,
  showProductEditModal: false,
  selectedProduct: {},
  showProductDataSources: false,
  showCigarEditModal: false,
  selectedCigar: {},
  showAdModal: false,
  adModalProps: {},
  showEventModal: false,
  eventModalProps: {},
  showPromoteModal: false,
  promoteModalProps: {},
  showInventoryScannerModal: false,
});

export const SettingsStore = new Store({
  darkMode:
    darkMode !== null ? darkMode === 'true' || darkMode === true : mq.matches,
  darkNavbar: false, // FIXME This is honestly just a desktop feature...
  showNavDrawer: false,
  theme: Storage.getPreference('theme', 'System'),
});

export const setDarkMode = (isDarkMode: boolean) => {
  SettingsStore.update((s) => {
    Storage.setPreference('dark_mode', isDarkMode);
    // toggleDarkCSS(isDarkMode);
    s.darkMode = isDarkMode;
  });
};

export const toggleDarkMode = () => {
  SettingsStore.update((s) => {
    setDarkMode(!s.darkMode);
  });
};

export const setGlobalTheme = (theme: string) => {
  SettingsStore.update((s) => {
    Storage.setPreference('theme', theme);
    s.theme = !s.theme;
  });
};

export const MediaStore = new Store({
  mediaMap: {
    // INFO These are external URLs mapped to local media files to avoid network calls when possible
  },
});

export const SocialStore = new Store({
  profiles: {
    // INFO These are a map of profile IDs for each type
    venues: {},
    brands: {},
    // These are external social accounts linked to a given profile
    accounts: {
      // brand_989: {
      //   connected_facebook: '110600281617592', // Dev,
      //   // connected_facebook: '109367180427495', // Prod
      //   facebook_page: {
      //     id: '110600281617592',
      //     name: 'Boxpressd Devs',
      //     username: 'boxpressddevs',
      //     image_url: 'https://pcore-customer-media.s3.amazonaws.com/0eddfdf2-5908-4190-961d-2acabe3ccc4d/cabd3d92-8844-fc20-cfc8-4625f55aedc5.jpeg',
      //   },
      //   facebook_groups: [], // Coming soon
      //   autopost_facebook: true,
      //   connected_instagram: '17841452755851607', // Dev,
      //   // connected_instagram: '17841403586747882', // Prod
      //   instagram_account: {
      //     id: 2,
      //     name: 'Boxpressd Devs',
      //     username: 'boxpressddevs',
      //     image_url: 'https://pcore-customer-media.s3.amazonaws.com/0eddfdf2-5908-4190-961d-2acabe3ccc4d/cabd3d92-8844-fc20-cfc8-4625f55aedc5.jpeg',
      //   },
      //   autopost_instagram: true,
      //   connected_twitter: '1451232796974977030', // Dev,
      //   // connected_twitter: '743641229020307456', // Prod
      //   twitter_account: {
      //     id: 2,
      //     name: 'Boxpressd App',
      //     username: 'boxpressddevs',
      //     image_url: 'https://pcore-customer-media.s3.amazonaws.com/0eddfdf2-5908-4190-961d-2acabe3ccc4d/cabd3d92-8844-fc20-cfc8-4625f55aedc5.jpeg',
      //   },
      //   autopost_twitter: true,
      // },
    },
  },
});

let alertStatus;
try {
  alertStatus = Storage.getPreference('alert-status', {});
} catch (e) {
  alertStatus = {};
}

export const NotificationStore = new Store({
  notifications: Array.isArray(alertStatus.notifications)
    ? alertStatus.notifications
    : [],
  notificationCount: alertStatus.notificationCount || 0,
  loading: false,
});
