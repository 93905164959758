import './ImgDecode.css';

import Fab from '@mui/material/Fab';
import { BarcodeReader } from 'dynamsoft-javascript-barcode';
import type { ChangeEvent } from 'react';
import React, { Component } from 'react';

import Icon from '@/components/Icon';

export default class ImgDecode extends Component {
  pReader: Promise<BarcodeReader> = BarcodeReader.createInstance();

  elRef: React.RefObject<HTMLDivElement> = React.createRef();

  decodeImg = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const reader = await this.pReader;
      const results = await reader.decode(e.target.files![0]);
      for (const result of results) {
        alert(result.barcodeText);
      }
      if (!results.length) {
        alert('No barcode found');
      }
    } catch (ex: any) {
      if (ex.message.indexOf('network connection error')) {
        const customMsg =
          'Failed to connect to Dynamsoft License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.';
        console.log(customMsg);
      }
      throw ex;
    }
    e.target.value = '';
  };

  async componentWillUnmount() {
    if (this.pReader) {
      (await this.pReader).destroyContext();
      console.log('ImgDecode Component Unmount');
    }
  }

  render() {
    return (
      <Fab
        color="primary"
        aria-label="add"
        className="fab dark"
        style={{
          position: 'fixed',
          bottom: 20,
          left: 40,
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          border: '1px white solid',
        }}
        onClick={() => this.elRef.current!.click()}
      >
        <Icon name="image" style={{ height: 20, width: 20, color: 'white' }} />
        <input
          ref={this.elRef}
          style={{ display: 'none' }}
          type="file"
          accept="image/*"
          onChange={this.decodeImg}
        />
      </Fab>
    );
  }
}
