import * as Sentry from '@sentry/react';

function ErrorLogger() {}

ErrorLogger.captureException = (e) => {
  console.error(e);
  // TODO Can include other details here if necessary
  Sentry.captureException(e);
};

export default ErrorLogger;
