import './style.scss';

import { IonIcon, IonLabel, IonSegment, IonSegmentButton } from '@ionic/react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Badge, ClickAwayListener, Grow, MenuList, Paper } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import ButtonGroup from '@mui/material/ButtonGroup';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popper from '@mui/material/Popper';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { makeStyles, withStyles } from '@mui/styles';
import axios from 'axios';
import Hashids from 'hashids';
import { sparkles } from 'ionicons/icons';
import { isMobile } from 'mobile-device-detect';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  LoginSocialFacebook,
  LoginSocialTiktok,
  LoginSocialTwitter,
} from 'reactjs-social-login';
import {
  Button,
  Card,
  Col,
  Collapse,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Spinner,
} from 'reactstrap';

import MediaCropper from '@/components/Cropper';
import Dropzone from '@/components/Dropzone';
// import SearchModal from '@/components/SearchModal';
import DropzoneModal from '@/components/DropzoneModal';
import useEditorDiscardConfirmation from '@/components/EditorDiscardConfirmation';
import PostCardList from '@/components/FeedBusinessPostCardList';
import Icon from '@/components/Icon';
import MentionsTextArea from '@/components/MentionsTextArea';
import useDialogAlert from '@/components/ModalDialog';
import ModalView from '@/components/ModalView';
import { getProfileImage } from '@/components/ProfileSelect';
import ReelsCard from '@/components/ReelsCard';
import FacebookSocialCard from '@/components/SocialMediaCards/facebook';
import InstagramSocialCard from '@/components/SocialMediaCards/instagram';
import TwitterSocialCard from '@/components/SocialMediaCards/twitter';
import { apiKeys, config } from '@/settings';
import { AppStore, SocialStore, UserStore } from '@/stores';
import { adFormats } from '@/utils/adFormats';
import showPremiumPrompt from '@/utils/usePremiumPrompt';

const hashids = new Hashids('', 12);

const date = new Date();
let dateMonth = date.getMonth() + 1;
let dateDay = date.getDate();
let dateDayNext = date.getDate() + 1;

if (dateMonth < 10) {
  dateMonth = `0${dateMonth}`;
}
if (dateDay < 10) {
  dateDay = `0${dateDay}`;
}
if (dateDayNext < 10) {
  dateDayNext = `0${dateDayNext}`;
}
const dateTomorrow = String(
  `${date.getFullYear()}-${dateMonth}-${dateDayNext}`
);
const dateToday = String(`${date.getFullYear()}-${dateMonth}-${dateDay}`);

const styles = {
  chip: {
    margin: 4,
  },
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
};

const useStyles = makeStyles((theme) => ({
  dark: {
    // color: theme.palette.getContrastText(pink[500]),
    backgroundColor: '#3e4044',
  },
}));

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 22,
    height: 22,
    // border: `2px solid ${theme.palette.background.paper}`,
    marginRight: 0,
  },
}))(Avatar);

const CustomStyle = {
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected ? 'red' : 'transparent',
    ':active': {
      backgroundColor: state.isSelected ? 'white' : 'white',
    },
  }),
};

const postOptions = ['Publish Post', 'Schedule Post'];

// const connectedAccounts = [{
//   id: 1,
//   name: 'Boxpressd Cigar App',
//   image_url: 'https://pcore-customer-media.s3.amazonaws.com/0eddfdf2-5908-4190-961d-2acabe3ccc4d/cabd3d92-8844-fc20-cfc8-4625f55aedc5.jpeg',
//   type: 'Facebook Page',
// }, {
//   id: 2,
//   name: 'boxpressd',
//   image_url: 'https://pcore-customer-media.s3.amazonaws.com/0eddfdf2-5908-4190-961d-2acabe3ccc4d/cabd3d92-8844-fc20-cfc8-4625f55aedc5.jpeg',
//   type: 'Instagram Account',
// }, {
//   id: 2,
//   name: 'Boxpressd App',
//   image_url: 'https://pcore-customer-media.s3.amazonaws.com/0eddfdf2-5908-4190-961d-2acabe3ccc4d/cabd3d92-8844-fc20-cfc8-4625f55aedc5.jpeg',
//   type: 'Twitter (X) Account',
// }];

const logoForType = (type) => {
  if (type.indexOf('Facebook') !== -1) {
    return 'https://freelogopng.com/images/all_img/1657548084facebook-logo-png.png';
  }
  if (type.indexOf('Instagram') !== -1) {
    return 'https://freelogopng.com/images/all_img/1658587303instagram-png.png';
  }
  if (type.indexOf('Twitter') !== -1) {
    return 'https://seeklogo.com/images/T/twitter-x-logo-8FD8E6C4FD-seeklogo.com.png';
  }
};

const generateFeatureImage = async (post) => {
  console.debug(post);
  if (post && post.media && post.media.length === 1) {
    if (post.media[0].media_type === 'image') {
      console.debug(`Single image: ${post.media[0].media_url}`);
      return post.media[0].media_url;
    }
    if (post.media[0].media_type === 'video') {
      console.debug(
        `Single video: ${post.media[0].media_url.replace('.mp4', '.jpg')}`
      );
      return post.media[0].media_url.replace('.mp4', '.jpg');
    }
  }
  if (post && post.media && post.media.length > 1) {
    // TODO Generate the media grid with transparent bg (as png) as base64? Or upload it somewhere first?
    // const dataUrl = await toPng(twitterMediaGrid.current, { cacheBust: true });
    if (post.media[0].media_type === 'image') {
      console.debug(`Single image: ${post.media[0].media_url}`);
      return post.media[0].media_url;
    }
  }
  return null;
};

const addOrUpdatePost = (post, profileType, profileId) => {
  console.debug('Adding post to feed:');
  console.debug(post);
  SocialStore.update((s) => {
    if (!s.profiles[`${profileType}s`]) {
      s.profiles[`${profileType}s`] = {};
    }
    if (!s.profiles[`${profileType}s`][profileId]) {
      s.profiles[`${profileType}s`][profileId] = {};
    }
    const profile =
      (s.profiles[`${profileType}s`] &&
        s.profiles[`${profileType}s`][profileId]) ||
      {};
    const posts = [...(profile.posts || [])];
    let foundPost = false;
    for (let i = 0; i < posts.length; i++) {
      console.debug(
        `Testing ${posts[i].business_post_id} against ${parseInt(post.id)}`
      );
      console.debug(posts[i]);
      console.debug(post);
      if (parseInt(posts[i].business_post_id) === parseInt(post.id)) {
        foundPost = true;
        posts[i].business_post = post;
        break;
      }
    }
    if (!foundPost) {
      posts.unshift({
        type: 'business_post',
        business_post: post,
      });
    }
    s.profiles[`${profileType}s`][profileId].posts = posts;
  });
};

function CreateAdModal(props) {
  const selectedProfile = AppStore.useState((s) => s.selectedProfile);
  const selectedProfileType = AppStore.useState((s) => s.selectedProfileType);
  const premium = AppStore.useState((s) => s.premium);

  const { showDialogAlert } = useDialogAlert();
  const { showEditorDiscardConfirmation } = useEditorDiscardConfirmation();

  const classes = useStyles();
  const post = props.post || {
    content: '',
    media: [],
    cards: null,
    meta: null,
  };
  const navigate = useNavigate();
  const [media, setMedia] = useState(post.media);
  const [selectedText, setSelectedText] = useState(post.content);
  const [selectedStartDate, setSelectedStartDate] = useState(
    `${dateToday}T12:00:00`
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    `${dateToday}T12:00:00`
  );
  const [selectedFormat, setSelectedFormat] = useState(
    post.cards && post.cards.length ? adFormats[1] : adFormats[0]
  );
  const [showMediaModal, setShowMediaModal] = useState(false);
  const [showMediaCropper, setShowMediaCropper] = useState(false);
  const [meta, setMeta] = useState(post.meta);
  const [customizeText, setCustomizeText] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedMedia, setSelectedMedia] = useState(post.media || []);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showAIModal, setShowAIModal] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [prompt, setPrompt] = useState(
    `Write me a 180 character social media post about ${selectedProfile.name}${
      selectedProfileType === 'cigar_brand' ? ' cigars' : ''
    }`
  );
  const [carouselPosts, setCarouselPosts] = useState(
    post.cards && post.cards.length
      ? post.cards.map((card, index) => ({
          selected: index === 0,
          mediaType: card.media_type,
          mediaUrl: card.media_url,
          ...card,
        }))
      : [
          {
            selected: true,
          },
          {
            selected: false,
          },
          {
            selected: false,
          },
        ]
  );
  const [nubText, setNubText] = useState('');
  const [nubLink, setNubLink] = useState('');
  const [nubMediaUrl, setNubMediaUrl] = useState(null);
  const [tags, setTags] = useState([]);
  const currentUser = UserStore.useState((s) => s.user);
  const [loading, setLoading] = useState(false);
  const anchorRef = React.useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [connectedAccounts, setConnectedAccounts] = useState([]);
  const [selectedTagType, setSelectedTagType] = useState(null);
  const [showSearchModal, setShowSearchModal] = useState(false);

  // FIXME These all need to use the selected profile instead of a user
  const id = `${selectedProfileType}_${selectedProfile.id}`;
  const connectedFacebook = SocialStore.useState(
    (s) =>
      s.profiles.accounts[id] && !!s.profiles.accounts[id].connected_facebook
  );
  const enabledFacebook = SocialStore.useState(
    (s) =>
      s.profiles.accounts[id] && !!s.profiles.accounts[id].autopost_facebook
  );
  const connectedTwitter = SocialStore.useState(
    (s) =>
      s.profiles.accounts[id] && !!s.profiles.accounts[id].connected_twitter
  );
  const enabledTwitter = SocialStore.useState(
    (s) => s.profiles.accounts[id] && !!s.profiles.accounts[id].autopost_twitter
  );
  const connectedInstagram = SocialStore.useState(
    (s) =>
      s.profiles.accounts[id] && !!s.profiles.accounts[id].connected_instagram
  );
  const enabledInstagram = SocialStore.useState(
    (s) =>
      s.profiles.accounts[id] && !!s.profiles.accounts[id].autopost_instagram
  );
  const connectedTikTok = SocialStore.useState(
    (s) => s.profiles.accounts[id] && !!s.profiles.accounts[id].connected_tiktok
  );
  const enabledTikTok = SocialStore.useState(
    (s) => s.profiles.accounts[id] && !!s.profiles.accounts[id].autopost_tiktok
  );
  const connectedLinkedIn = SocialStore.useState(
    (s) =>
      s.profiles.accounts[id] && !!s.profiles.accounts[id].connected_linkedin
  );
  const enabledLinkedIn = SocialStore.useState(
    (s) =>
      s.profiles.accounts[id] && !!s.profiles.accounts[id].autopost_linkedin
  );
  const connectedPinterest = SocialStore.useState(
    (s) =>
      s.profiles.accounts[id] && !!s.profiles.accounts[id].connected_pinterest
  );
  const enabledPinterest = SocialStore.useState(
    (s) =>
      s.profiles.accounts[id] && !!s.profiles.accounts[id].autopost_pinterest
  );

  const facebookPage = SocialStore.useState(
    (s) => s.profiles.accounts[id] && s.profiles.accounts[id].facebook_page
  );
  const instagramAccount = SocialStore.useState(
    (s) => s.profiles.accounts[id] && s.profiles.accounts[id].instagram_account
  );
  const twitterAccount = SocialStore.useState(
    (s) => s.profiles.accounts[id] && s.profiles.accounts[id].twitter_account
  );

  const [autoPostFacebook, setAutoPostFacebook] = useState(enabledFacebook);
  const [autoPostTwitter, setAutoPostTwitter] = useState(enabledTwitter);
  const [autoPostInstagram, setAutoPostInstagram] = useState(enabledInstagram);
  const [autoPostTikTok, setAutoPostTikTok] = useState(enabledTikTok);
  const [autoPostLinkedIn, setAutoPostLinkedIn] = useState(enabledLinkedIn);
  const [autoPostPinterest, setAutoPostPinterest] = useState(enabledPinterest);

  const [selectedTextField, setSelectedTextField] = useState(0);
  const [selectedTextMap, setSelectedTextMap] = useState({});
  const [scheduledTimesMap, setScheduledTimesMap] = useState({});

  useEffect(() => {
    console.debug('Selected post:');
    console.debug(post);
    axios
      .get(`${config.businessEndPoint}/media`, {
        params: { id: selectedProfile.id, type: selectedProfileType },
      })
      .then((res) => {
        console.debug('Got existing media:');
        console.debug(res.data);
        setMedia(
          res.data.map((m) => ({
            ...m,
            src: m.media_url,
            isSelected: false,
          }))
        );
      });

    axios
      .get(`${config.businessEndPoint}/social/accounts`, {
        params: {
          id: selectedProfile.id,
          type: selectedProfileType,
        },
      })
      .then((response) => {
        console.log('Got social accounts:');
        console.log(response.data);
        // TODO Store in local storage?
        SocialStore.update((s) => {
          if (!s.profiles.accounts[id]) {
            s.profiles.accounts[id] = {};
          }
          response.data.forEach((account) => {
            if (account.type.indexOf('Facebook Page') !== -1) {
              s.profiles.accounts[id].facebook_page = account;
              s.profiles.accounts[id].connected_facebook = account.id;
              if (s.profiles.accounts[id].autopost_facebook === undefined) {
                s.profiles.accounts[id].autopost_facebook = true;
              }
            } else if (account.type.indexOf('Instagram') !== -1) {
              s.profiles.accounts[id].instagram_account = account;
              s.profiles.accounts[id].connected_instagram = account.id;
              if (s.profiles.accounts[id].autopost_instagram === undefined) {
                s.profiles.accounts[id].autopost_instagram = true;
              }
            } else if (account.type.indexOf('Twitter') !== -1) {
              s.profiles.accounts[id].twitter_account = account;
              s.profiles.accounts[id].connected_twitter = account.id;
              if (s.profiles.accounts[id].autopost_twitter === undefined) {
                s.profiles.accounts[id].autopost_twitter = true;
              }
            }
          });
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    const profiles = [];
    if (facebookPage) {
      profiles.push({
        ...facebookPage,
        type: 'Facebook Page',
      });
    }
    if (instagramAccount) {
      profiles.push({
        ...instagramAccount,
        type: 'Instagram Account',
      });
    }
    if (twitterAccount) {
      profiles.push({
        ...twitterAccount,
        type: 'Twitter (X) Account',
      });
    }
    setConnectedAccounts(profiles);
  }, [
    facebookPage,
    instagramAccount,
    twitterAccount /* , linkedInAccount, pinterestAccount, tikTokAccount */,
  ]);

  useEffect(() => {
    if (props.tab) {
      setSelectedFormat(props.tab);
      if (
        (isMobile && props.tab === adFormats[0]) ||
        props.tab === adFormats[2]
      ) {
        setShowMediaModal(true);
      }
    }
  }, [props.tab]);

  const onClose = (force) => {
    if (!force && selectedText && selectedText.length > 0) {
      showEditorDiscardConfirmation({
        title: 'Discard Post',
        message:
          'Are you sure you want to discard this post? Your changes will be lost.',
        onClick: () => {
          if (typeof props.onClose === 'function') {
            props.onClose();
          }
        },
      });
    } else if (typeof props.onClose === 'function') {
      props.onClose();
    }
  };

  const onSave = () => {
    const businessIdString =
      selectedProfileType === 'venue' ? 'venue_id' : 'brand_id';
    const businessId = selectedProfile.id;
    let post;
    let hasErrors;
    let errorMessage = 'Please check your form fields.';
    if (selectedFormat === adFormats[0]) {
      if (selectedMedia && selectedMedia.length) {
        selectedMedia.forEach((media) => {
          if (media.id) {
            delete media.id;
          }
          delete media.src;
        });
      }
      // Simple post
      post = {
        user_id: currentUser.id,
        content: selectedText, // FIXME if customizeText is true, pull from the map
        business_type: selectedProfileType,
        [businessIdString]: businessId,
        media: selectedMedia,
        // FIXME It might make more sense to cache meta when ${config.mediaEndPoint}/meta is called but this works for now
        meta: !selectedMedia.length &&
          meta && {
            id: props.post && props.post.meta && props.post.meta.id,
            title: meta.ogTitle || (props.post && props.post.meta.title),
            description:
              meta.ogDescription || (props.post && props.post.meta.description),
            domain: meta.ogUrl || (props.post && props.post.meta.domain),
            url: meta.requestUrl || (props.post && props.post.meta.url),
            image_url:
              (meta.ogImage && meta.ogImage.url) ||
              (props.post && props.post.meta.image_url),
            video_url:
              (meta.ogVideo && meta.ogVideo.url) ||
              (props.post && props.post.meta.video_url),
          },
      };
    } else {
      // FIXME For now, I don't think we can support posting these to external networks. It might work if we convert them to images
      // Carousel post
      console.debug(carouselPosts);
      carouselPosts.forEach((post) => {
        // FIXME Highlight the fields instead of an alert
        if (!post.headline || !post.description || !post.url) {
          hasErrors = true;
          errorMessage = 'Please fill out all fields for all 3 carousel cards.';
          return;
        }
        if (!post.mediaUrl) {
          hasErrors = true;
          errorMessage =
            'Please add 1 image or video for all 3 carousel cards.';
        }
      });
      if (!hasErrors) {
        post = {
          user_id: currentUser.id,
          business_type: selectedProfileType,
          [businessIdString]: businessId,
          cards: carouselPosts.map((card) => ({
            headline: card.headline,
            description: card.description,
            url: card.url,
            media_type: card.mediaType,
            media_uri: new URL(card.mediaUrl).pathname,
            media_url: card.src || card.mediaUrl,
          })),
        };
      }
    }
    if (hasErrors) {
      alert(errorMessage);
    } else {
      setLoading(true);
      const updatedPost = {
        ...(props.post || {}),
        ...post,
        // timestamp: new Date().toISOString(), // INFO Let the server handle it
        [selectedProfileType]: selectedProfile,
      };
      addOrUpdatePost(updatedPost, selectedProfileType, selectedProfile.id);
      console.debug('Submitting post:');
      console.debug(post);
      if (updatedPost.id) {
        axios
          .put(`${config.businessEndPoint}/posts/${updatedPost.id}`, post)
          .then((response) => {
            setLoading(false);
            console.debug('Post response:');
            console.debug(response);
            console.debug(response.data);
            // addOrUpdatePost(response.data, selectedProfileType, selectedProfile.id);
            if (typeof props.onClose === 'function') {
              props.onClose();
            }
          })
          .catch((err) => {
            console.error(err);
            setLoading(false);
            // TODO Alert the user with a dialog
            alert('There was a problem submitting your post.');
          });
      } else {
        (async () => {
          const promises = [];
          const additionalPosts = [];

          const postRes = await axios.post(`${config.businessEndPoint}/posts`, {
            ...post,
            // On Boxpressd, posts with a timestamp in the future are considered "scheduled" - they will show when that date rolls around
            timestamp:
              selectedIndex === 1 ? scheduledTimesMap.Boxpressd : undefined,
          });

          console.debug('Post response:');
          console.debug(postRes);

          additionalPosts.push(postRes.data);

          if (premium) {
            let postMedia = [];
            if (post.media) {
              postMedia = post.media.map((m) => ({
                media_type: m.media_type || m.type,
                media_uri: new URL(m.media_url).pathname,
                media_url: m.media_url,
              }));
            }
            const linkData = {
              path: selectedProfileType.replace('cigar_', ''),
              route: `posts/${hashids.encode(postRes.data.id)}/comments`,
              // title: '',
              text: post.content,
              image: generateFeatureImage(post),
            };
            // const link = await generateLink(linkData);
            // if (autoPostFacebook) {
            //   promises.push(
            //     axios.post(
            //       `${config.businessEndPoint}/social/facebook/posts`,
            //       {
            //         ...post,
            //         media: postMedia,
            //         link_url: link,
            //         content: customizeText
            //           ? selectedTextMap.Facebook
            //           : post.content,
            //         scheduled_publish_time:
            //           selectedIndex === 1
            //             ? scheduledTimesMap.Facebook
            //             : undefined, // FIXME Need to ensure they put a date in - check in validation step
            //       },
            //       {
            //         params: {
            //           id: selectedProfile.id,
            //           type: selectedProfileType,
            //           account_id: '110600281617592', // TODO Need to pull from somewhere
            //           account_type: 'page', // TODO Can also be a group at some point
            //         },
            //       }
            //     )
            //   );
            // }
            // if (autoPostInstagram) {
            //   promises.push(
            //     axios.post(
            //       `${config.businessEndPoint}/social/instagram/posts`,
            //       {
            //         ...post,
            //         media: postMedia,
            //         link_url: link,
            //         content: customizeText
            //           ? selectedTextMap.Instagram
            //           : post.content,
            //         scheduled_publish_time:
            //           selectedIndex === 1
            //             ? scheduledTimesMap.Instagram
            //             : undefined,
            //       },
            //       {
            //         params: {
            //           id: selectedProfile.id,
            //           type: selectedProfileType,
            //           account_id: '17841452755851607', // TODO Need to pull from somewhere
            //         },
            //       }
            //     )
            //   );
            // }
            // if (autoPostTwitter) {
            //   promises.push(
            //     axios.post(
            //       `${config.businessEndPoint}/social/twitter/posts`,
            //       {
            //         ...post,
            //         media: postMedia,
            //         link_url: link,
            //         content: customizeText
            //           ? selectedTextMap.Twitter
            //           : post.content, // FIXME This + link length needs to be less than 280 chars
            //         scheduled_publish_time:
            //           selectedIndex === 1
            //             ? scheduledTimesMap.Twitter
            //             : undefined,
            //       },
            //       {
            //         params: {
            //           id: selectedProfile.id,
            //           type: selectedProfileType,
            //           account_id: '1451232796974977030', // TODO Need to pull from somewhere
            //         },
            //       }
            //     )
            //   );
            // }
          }

          Promise.all(promises)
            .then((responses) => {
              setLoading(false);
              console.debug('Post responses:');
              console.debug(responses);
              responses.forEach((response) => {
                console.debug(response.data);
                additionalPosts.push(response.data);
              });
              // addOrUpdatePost(response.data, selectedProfileType, selectedProfile.id);
              if (typeof props.onClose === 'function') {
                props.onClose(additionalPosts);
              }
            })
            .catch((err) => {
              console.error(err);
              setLoading(false);
              // TODO Alert the user with a dialog - if premium, see which one may have failed instead of a catch all message
              //  Maybe list the ones that it didn't post to
              alert('There was a problem submitting your post.');
            });
        })();
      }
    }
  };

  const linkMediaToAccount = (m) => {
    const mediaObj = { ...m };
    mediaObj.business_type = selectedProfileType;
    mediaObj[`${selectedProfileType}_id`] = selectedProfile.id;
    if (mediaObj.type === 'video') {
      mediaObj.media_type = 'video';
      mediaObj.media_uri = mediaObj.media_url
        .replace(config.cdnUrl, '')
        .replace('https://', '')
        .replace('http://', '');
    } else {
      mediaObj.media_uri = `/${mediaObj.key}`;
    }
    console.debug('Linking media to account');
    console.debug(mediaObj);
    axios
      .post(`${config.businessEndPoint}/media`, [mediaObj])
      .then(() => {
        console.debug('Linked media to account');
      })
      .catch((err) => {
        console.error('Unable to link media to account');
        console.error(err);
      });
  };

  const renderMediaCropper = () => (
    <MediaCropper
      src={selectedImage}
      open={showMediaCropper}
      aspectRatio={10 / 8}
      toggle={() => {
        setShowMediaCropper((prev) => !prev);
      }}
      onClose={() => {
        setShowMediaCropper(false);
      }}
      onSave={(croppedCanvas) => {
        // const base64 = croppedCanvas.toDataURL();
        // const currentUser = { ...user };
        // if (cropType === 'profile') {
        //   currentUser.image_url = base64;
        // } else {
        //   currentUser.cover_image_url = base64;
        // }
        // setUser(currentUser);
        // croppedCanvas.toBlob((blob) => {
        //   console.log('Canvas blob');
        //   console.log(blob);
        //   uploadMedia(blob, base64.split(';')[0].split('/')[1], cropType);
        //   setShowMediaCropper(false);
        // }/* , 'image/png' */);
      }}
    />
  );

  const autoPostLabels = useCallback(() => {
    const labels = ['Boxpressd'];
    if (autoPostFacebook) {
      labels.push('Facebook');
    }
    if (autoPostInstagram) {
      labels.push('Instagram');
    }
    if (autoPostTwitter) {
      labels.push('Twitter');
    }
    if (autoPostTikTok) {
      labels.push('TikTok');
    }
    if (autoPostLinkedIn) {
      labels.push('LinkedIn');
    }
    if (autoPostPinterest) {
      labels.push('Pinterest');
    }
    return labels.join(', ');
  }, [autoPostFacebook, autoPostInstagram, autoPostTwitter]);

  const renderContent = () => (
    <Row>
      <Col
        style={{ padding: selectedFormat === adFormats[1] ? 4 : 15 }}
        md={selectedIndex === 1 ? 4 : 6}
      >
        {/* <FormGroup> */}
        {/*  <Label for="url">URL</Label> */}
        {/*  <Input */}
        {/*    id="url" */}
        {/*    value={url} */}
        {/*    onChange={(e) => { */}
        {/*      setUrl(e.target.value); */}
        {/*    }} */}
        {/*  /> */}
        {/* </FormGroup> */}
        {!isMobile && (!props.post || !props.post.id) && (
          <FormGroup
            style={{ padding: selectedFormat === adFormats[1] ? 11 : 0 }}
          >
            <Label for="format">Format</Label>
            <div>
              <IonSegment mode="ios" value={selectedFormat}>
                {adFormats.map((value) => (
                  <IonSegmentButton
                    value={value}
                    onClick={() => setSelectedFormat(value)}
                  >
                    <IonLabel>{value}</IonLabel>
                  </IonSegmentButton>
                ))}
              </IonSegment>
            </div>
            <span style={{ color: '#999999' }}>
              {
                "Choose the type of image or video format you'd like to use in this ad."
              }
            </span>
          </FormGroup>
        )}
        {selectedFormat === adFormats[0] && (
          <FormGroup>
            <Label for="dropzone">{adFormats[0]}</Label>
            {isMobile && (
              <div style={{ textAlign: 'center' }}>
                <Button
                  style={{ maxWidth: 120, margin: 'auto' }}
                  onClick={() => {
                    setShowMediaModal(true);
                  }}
                >
                  {selectedMedia && selectedMedia.length > 0
                    ? 'Edit Media'
                    : 'Add Media'}
                </Button>
              </div>
            )}
            {!isMobile && (
              <Dropzone
                id="postMedia"
                uploadToS3
                removeOnAdd
                path={`businesses/${
                  selectedProfile.id
                }/${new Date().getTime()}`}
                // ref={(ref) => productImages = ref}
                config={{
                  postUrl: 'no-url',
                  // iconFiletypes: ['.jpg', '.png', '.webp'],
                }}
                djsConfig={{
                  dictDefaultMessage: 'Click or Drag & Drop media',
                  // previewTemplate: djsPreviewTemplate,
                }}
                onClick={(e) => {
                  e.preventDefault();
                  // TODO See Publer - I would like to allow users to upload from a URL
                  setShowMediaModal(true);
                }}
                onAdded={async (file) => {
                  console.debug('onAdded file:');
                  console.debug(file);
                  setSelectedMedia([...selectedMedia, file]);
                  linkMediaToAccount(file);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <section className="dropzone">
                    <div style={{ height: 150 }} {...getRootProps()}>
                      <input {...getInputProps()} />
                    </div>
                  </section>
                )}
              </Dropzone>
            )}
          </FormGroup>
        )}
        {selectedFormat === adFormats[2] && (
          <FormGroup>
            {isMobile && (
              <div style={{ textAlign: 'center' }}>
                <Button
                  style={{ maxWidth: 120, margin: 'auto' }}
                  onClick={() => {
                    setShowMediaModal(true);
                  }}
                >
                  {selectedMedia && selectedMedia.length > 0
                    ? 'Edit Media'
                    : 'Add Media'}
                </Button>
              </div>
            )}
            {!isMobile && (
              <Dropzone
                id="nubMedia"
                uploadToS3
                removeOnAdd
                path={`nubs/${selectedProfile.id}/${new Date().getTime()}`}
                // ref={(ref) => productImages = ref}
                config={{
                  postUrl: 'no-url',
                  // iconFiletypes: ['.jpg', '.png', '.webp'],
                }}
                djsConfig={{
                  dictDefaultMessage: 'Click or Drag & Drop media',
                  // previewTemplate: djsPreviewTemplate,
                }}
                onClick={(e) => {
                  e.preventDefault();
                  // TODO See Publer - I would like to allow users to upload from a URL
                  setShowMediaModal(true);
                }}
                onAdded={async (file) => {
                  console.debug('onAdded file:');
                  console.debug(file);
                  setNubMediaUrl(file.media_url);
                  linkMediaToAccount(file);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <section className="dropzone">
                    <div style={{ height: 150 }} {...getRootProps()}>
                      <input {...getInputProps()} />
                    </div>
                  </section>
                )}
              </Dropzone>
            )}
          </FormGroup>
        )}
        {selectedFormat === adFormats[1] && (
          <FormGroup>
            <Label for="dropzone" style={{ marginLeft: 11 }}>
              {adFormats[1]}
            </Label>
            {/* TODO Should allow up to 5? Min of 3 - allow the user to add more, so the count should be set in state */}
            {carouselPosts.map((post, index) => (
              <Card style={{ margin: 10 }}>
                <List>
                  <ListItem
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      for (let i = 0; i < carouselPosts.length; i++) {
                        if (i !== index) {
                          carouselPosts[i].selected = false;
                        }
                      }
                      post.selected = !post.selected;
                      setCarouselPosts([...carouselPosts]);
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        src={
                          carouselPosts[index].mediaUrl &&
                          carouselPosts[index].mediaUrl.replace('.mp4', '.jpg')
                        }
                      >
                        <div>
                          <Icon name="plus" />
                        </div>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={post.headline || 'untitled'}
                      secondary={post.description || 'Enter description'}
                    />
                    <ListItemSecondaryAction
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        for (let i = 0; i < carouselPosts.length; i++) {
                          if (i !== index) {
                            carouselPosts[i].selected = false;
                          }
                        }
                        post.selected = !post.selected;
                        setCarouselPosts([...carouselPosts]);
                      }}
                    >
                      <Icon
                        name={post.selected ? 'chevron-up' : 'chevron-down'}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
                <Collapse isOpen={post.selected} style={{ padding: 20 }}>
                  <div style={{ textAlign: 'center' }}>
                    <Button
                      style={{ maxWidth: 120, margin: 'auto' }}
                      onClick={() => {
                        setSelectedIndex(index);
                        setShowMediaModal(true);
                      }}
                    >
                      {carouselPosts[index].mediaUrl
                        ? 'Edit Media'
                        : 'Add Media'}
                    </Button>
                    {/* FIXME If image, add "remove" button */}
                  </div>
                  {/* TODO Let them add separate links and text for each? */}
                  <FormGroup>
                    <Label for="headline">Headline</Label>
                    <input
                      className="form-control"
                      value={post.headline}
                      onChange={(e) => {
                        post.headline = e.target.value;
                        setCarouselPosts([...carouselPosts]);
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="content">Description</Label>
                    <input
                      className="form-control"
                      value={post.description}
                      onChange={(e) => {
                        post.description = e.target.value;
                        setCarouselPosts([...carouselPosts]);
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="link">Website URL</Label>
                    <input
                      className="form-control"
                      value={post.url}
                      onChange={(e) => {
                        // FIXME Validate for format
                        post.url = e.target.value;
                        setCarouselPosts([...carouselPosts]);
                      }}
                    />
                  </FormGroup>
                </Collapse>
              </Card>
            ))}
          </FormGroup>
        )}
        {selectedFormat === adFormats[0] && (
          <FormGroup>
            {!post.id &&
              (autoPostFacebook ||
                autoPostInstagram ||
                autoPostTwitter ||
                autoPostTikTok ||
                autoPostLinkedIn ||
                autoPostPinterest) && (
                <div>
                  <FormControlLabel
                    value="end"
                    control={
                      <Switch
                        color="brand"
                        checked={customizeText}
                        onChange={(e) => setCustomizeText(e.target.checked)}
                      />
                    }
                    label={
                      <Typography className="font-size-12">{`Customize text for ${autoPostLabels()}`}</Typography>
                    }
                    labelPlacement="end"
                  />
                </div>
              )}
            {(autoPostFacebook ||
              autoPostInstagram ||
              autoPostTwitter ||
              autoPostTikTok ||
              autoPostLinkedIn ||
              autoPostPinterest) &&
              customizeText && (
                <div>
                  <ButtonGroup>
                    {autoPostLabels()
                      .split(', ')
                      .map((label, index) => (
                        <Button
                          key={index}
                          outline
                          onClick={() => setSelectedTextField(index)}
                          active={selectedTextField === index}
                        >
                          {label}
                        </Button>
                      ))}
                  </ButtonGroup>
                </div>
              )}
            <Label for="text">Text</Label>
            <MentionsTextArea
              // className="form-control"
              id="text"
              style={{ height: 125 }}
              value={
                customizeText
                  ? selectedTextMap[
                      autoPostLabels().split(', ')[selectedTextField]
                    ]
                  : selectedText
              }
              onChange={(e) => {
                if (customizeText) {
                  const newTextMap = { ...selectedTextMap };
                  newTextMap[autoPostLabels().split(', ')[selectedTextField]] =
                    e.target.value;
                  setSelectedTextMap(newTextMap);
                } else {
                  setSelectedText(e.target.value);
                }
              }}
            />
            <Chip
              variant="outlined"
              size="small"
              avatar={
                <Avatar className={classes.dark}>
                  <Icon name={['fas', 'hashtag']} vendor="fa" />
                </Avatar>
              }
              label="Hashtags"
              style={{ margin: 6 }}
              onClick={() => {
                if (premium) {
                  alert('Coming soon!');
                } else {
                  showPremiumPrompt({ showDialogAlert, navigate });
                }
              }}
            />
            <Chip
              variant="outlined"
              size="small"
              avatar={
                <Avatar className={classes.dark}>
                  <Icon name={['fas', 'magic']} vendor="fa" />
                </Avatar>
              }
              label="AI Writer"
              style={{ margin: 6 }}
              onClick={() => {
                if (premium) {
                  // TODO If they have Chat GPT API key and premium plan, modal to enter prompt/basic description
                  //  If not, handle accordingly
                  // ...
                  setShowAIModal(true);
                } else {
                  showPremiumPrompt({ showDialogAlert, navigate });
                }
              }}
            />
          </FormGroup>
        )}
        {selectedFormat === adFormats[2] && (
          <>
            <FormGroup>
              <Label for="text">Text</Label>
              <textarea
                className="form-control"
                id="text"
                style={{ height: 125 }}
                value={nubText}
                onChange={(e) => setNubText(e.target.value)}
              />
              <Chip
                variant="outlined"
                size="small"
                avatar={
                  <Avatar className={classes.dark}>
                    <Icon name={['fas', 'magic']} vendor="fa" />
                  </Avatar>
                }
                label="AI Writer"
                style={{ margin: 6 }}
                onClick={() => {
                  if (premium) {
                    setShowAIModal(true);
                  } else {
                    showPremiumPrompt({ showDialogAlert, navigate });
                  }
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label for="text">Link</Label>
              <input
                className="form-control"
                value={nubLink}
                onChange={(e) => setNubLink(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="text">Add Tags</Label>
              <br />
              <Chip
                variant="outlined"
                size="small"
                avatar={
                  <Avatar className={classes.dark}>
                    <Icon name={['fas', 'plus-circle']} vendor="fa" />
                  </Avatar>
                }
                label="Cigars"
                style={{ margin: 6 }}
                onClick={() => {
                  setSelectedTagType('cigars');
                  setShowSearchModal(true);
                }}
              />
              <Chip
                variant="outlined"
                size="small"
                avatar={
                  <Avatar className={classes.dark}>
                    <Icon name={['fas', 'shopping-cart']} vendor="fa" />
                  </Avatar>
                }
                label="Products"
                style={{ margin: 6 }}
                onClick={() => {
                  // TODO I think if they're a retailer with inventory, only search their own. Why would they link someone else?
                  setSelectedTagType('products');
                  setShowSearchModal(true);
                }}
              />
              <Chip
                variant="outlined"
                size="small"
                avatar={
                  <Avatar className={classes.dark}>
                    <Icon name={['fas', 'map-marker-alt']} vendor="fa" />
                  </Avatar>
                }
                label="Venues"
                style={{ margin: 6 }}
                onClick={() => {
                  setSelectedTagType('venues');
                  setShowSearchModal(true);
                }}
              />
              <Chip
                variant="outlined"
                size="small"
                avatar={
                  <Avatar className={classes.dark}>
                    <Icon name={['fas', 'users']} vendor="fa" />
                  </Avatar>
                }
                label="Users"
                style={{ margin: 6 }}
                onClick={() => {
                  setSelectedTagType('users');
                  setShowSearchModal(true);
                }}
              />
            </FormGroup>
          </>
        )}
      </Col>
      <Col md={selectedIndex === 1 ? 4 : 6}>
        <Label for="post-preview">Preview:</Label>
        {customizeText &&
        selectedTextField &&
        autoPostLabels().split(', ')[selectedTextField] === 'Instagram' ? (
          <InstagramSocialCard
            post={{
              ...post,
              text: selectedTextMap[
                autoPostLabels().split(', ')[selectedTextField]
              ],
              media: selectedMedia,
            }}
          />
        ) : null}
        {customizeText &&
        selectedTextField &&
        autoPostLabels().split(', ')[selectedTextField] === 'Facebook' ? (
          <FacebookSocialCard
            post={{
              ...post,
              text: selectedTextMap[
                autoPostLabels().split(', ')[selectedTextField]
              ],
              media: selectedMedia,
            }}
          />
        ) : null}
        {customizeText &&
        selectedTextField &&
        autoPostLabels().split(', ')[selectedTextField] === 'Twitter' ? (
          <TwitterSocialCard
            post={{
              ...post,
              text: selectedTextMap[
                autoPostLabels().split(', ')[selectedTextField]
              ],
              media: selectedMedia,
            }}
          />
        ) : null}
        {selectedFormat !== adFormats[2] &&
        (!customizeText ||
          !selectedTextField ||
          autoPostLabels().split(', ')[selectedTextField] === 'Boxpressd') ? (
          <Card id="post-preview">
            {selectedProfile && (
              <PostCardList
                preview
                type={selectedFormat}
                posts={carouselPosts}
                post={{
                  venue: selectedProfile,
                  timestamp: new Date().toISOString(),
                  media: selectedMedia,
                  video: null, // TODO From state
                  content: customizeText
                    ? selectedTextMap[
                        autoPostLabels().split(', ')[selectedTextField]
                      ]
                    : selectedText,
                }}
                onMeta={(m) => {
                  console.debug(JSON.stringify(m));
                  setMeta(m);
                }}
              />
            )}
          </Card>
        ) : null}
        {selectedFormat === adFormats[2] && (
          <Card style={{ height: 712, width: 400, maxWidth: '100%' }}>
            <ReelsCard
              reels={[
                {
                  posted_by: {
                    name: selectedProfile.name,
                    image_url:
                      getProfileImage(selectedProfile.profile_image_url) ||
                      getProfileImage(selectedProfile.logo_image_url),
                  },
                  description: nubText,
                  media_url:
                    nubMediaUrl ||
                    'https://cdn.boxpressd.io/placeholder/no-nub.png',
                  // TODO Allow .mov and similar files as well? iPhones I think take video in that format
                  media_type:
                    nubMediaUrl && nubMediaUrl.indexOf('.mp4') !== -1
                      ? 'video'
                      : 'image',
                  external_link_url:
                    nubLink && nubLink.length > 0 ? nubLink : null,
                  tags,
                },
              ]}
              initialIndex={0}
              reelMetaInfo={{}}
            />
          </Card>
        )}
      </Col>
      {selectedIndex === 1 && (
        <Col md={4}>
          <Label for="scheduling-options">Scheduling Options</Label>
          <div id="scheduling-options">
            Set a date and time in the future to publish your post.
          </div>
          {/* FIXME Build this with flex - the secondary action isn't big enough for this to work consistently */}
          <List className="social-scheduling-list">
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Badge
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  badgeContent={
                    <SmallAvatar
                      alt="Boxpressd"
                      src="https://cdn.boxpressd.io/logos/launcher/maskable_icon_48.png"
                    />
                  }
                >
                  <Avatar
                    alt={selectedProfile.name}
                    src={
                      selectedProfile.profile_image_url ||
                      selectedProfile.logo_image_url ||
                      'https://cdn.boxpressd.io/placeholder/500x500/no_cigar.png'
                    }
                  >
                    {selectedProfile.name.charAt(0)}
                  </Avatar>
                </Badge>
              </ListItemAvatar>
              <ListItemText
                primary={selectedProfile.name}
                secondary="Boxpressd"
              />
              <ListItemSecondaryAction>
                <input
                  type="datetime-local"
                  className="form-control"
                  style={{ fontSize: 11, padding: 3 }}
                  onChange={(e) => {
                    console.debug(new Date(e.target.value).toISOString());
                    const dateMap = { ...scheduledTimesMap };
                    dateMap.Boxpressd = new Date(e.target.value).toISOString();
                    setScheduledTimesMap(dateMap);
                  }}
                />
              </ListItemSecondaryAction>
            </ListItem>
            {connectedAccounts.map((account) => {
              if (
                (account.type.indexOf('Facebook') !== -1 &&
                  !autoPostFacebook) ||
                (account.type.indexOf('Instagram') !== -1 &&
                  !autoPostInstagram) ||
                (account.type.indexOf('Twitter') !== -1 && !autoPostTwitter)
              ) {
                return null;
              }
              return (
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Badge
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      badgeContent={
                        <SmallAvatar
                          alt={account.type}
                          src={logoForType(account.type)}
                        />
                      }
                    >
                      <Avatar alt={account.name} src={account.image_url}>
                        {account.name.charAt(0)}
                      </Avatar>
                    </Badge>
                  </ListItemAvatar>
                  <ListItemText
                    primary={account.name}
                    secondary={account.username}
                  />
                  <ListItemSecondaryAction>
                    <input
                      type="datetime-local"
                      className="form-control"
                      style={{ fontSize: 11, padding: 3 }}
                      onChange={(e) => {
                        const scheduledDate = new Date(e.target.value);
                        console.debug(scheduledDate.toISOString());
                        let index;
                        let time = scheduledDate.toISOString();
                        if (account.type.indexOf('Facebook') !== -1) {
                          index = 'Facebook';
                          time = Math.floor(scheduledDate.getTime() / 1000);
                        } else if (account.type.indexOf('Instagram') !== -1) {
                          index = 'Instagram';
                          time = Math.floor(scheduledDate.getTime() / 1000);
                        } else if (account.type.indexOf('Twitter') !== -1) {
                          index = 'Twitter';
                          time = Math.floor(scheduledDate.getTime() / 1000);
                        }
                        console.debug(time);
                        // FIXME This doesn't account for groups
                        const dateMap = { ...scheduledTimesMap };
                        dateMap[index] = time;
                        setScheduledTimesMap(dateMap);
                      }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </Col>
      )}
      <DropzoneModal
        // FIXME If selectedFormat === adFormats[1] - we need to pass in the one associated with the card index
        initialMedia={media}
        multiple={selectedFormat === adFormats[0]}
        isOpen={showMediaModal}
        closeModal={() => setShowMediaModal(false)}
        onMediaSelected={(media) => {
          if (selectedFormat === adFormats[0]) {
            console.debug('Selected media:');
            console.debug(media);
            console.debug('Selected media src:');
            console.debug(media[0].src);
            setSelectedMedia(media);
            // setSelectedImage(media[0].src);
            // setShowMediaCropper(true);
          } else if (selectedFormat === adFormats[1]) {
            const posts = [...carouselPosts];
            const selectedPost = posts[selectedIndex];
            console.debug('Setting selected media item');
            console.debug(media);
            selectedPost.src = media[0].src;
            selectedPost.mediaUrl = media[0].media_url; // FIXME Attach the full media object?
            selectedPost.mediaType = media[0].type || media[0].media_type;
            console.debug(posts);
            setCarouselPosts(posts);
          } else if (selectedFormat === adFormats[2]) {
            setNubMediaUrl(media[0].media_url);
          }
        }}
        onMediaUploaded={(media) => {
          console.debug('Uploaded media file:');
          console.debug(media);
          linkMediaToAccount(media);
          if (media.media_type === 'video') {
            // Since we only allow one video, we can continue for them
            setShowMediaModal(false);
            setSelectedMedia([media]);
          }
        }}
      />
    </Row>
  );

  // FIXME This logic doesn't make sense if we plan on allowing multiple pages/groups etc
  //  I think it would mostly be for the groups. It might need an "add account" + button to add multiple. Each
  //  page, group, etc should be an icon here, not the social media logo - they should be able to select
  //  which they want to publish to in this same manner but with their entire list of accounts as options
  const renderPostToOptions = () => (
    <>
      {connectedFacebook && (
        <Icon
          name={['fab', 'facebook']}
          vendor="fa"
          className={`social ${
            autoPostFacebook ? 'filled facebook' : 'outline'
          }`}
          onClick={() => {
            setAutoPostFacebook((toggled) => !toggled);
          }}
        />
      )}
      {!connectedFacebook && (
        <LoginSocialFacebook
          isOnlyGetToken
          appId={apiKeys.facebook.appId}
          configId={apiKeys.facebook.configId}
          scope="pages_show_list,pages_manage_posts,pages_read_engagement,pages_manage_engagement,pages_messaging"
          onResolve={({ provider, data }) => {
            console.debug(provider, data);
            if (window.FB) {
              window.FB.api('/me/accounts', (response) => {
                console.debug(response);
                if (response && !response.error) {
                  const pages = response.data; // Array of page information objects
                  console.debug(pages);
                  pages.forEach((page) => {
                    console.debug(page.id); // Here is the page ID
                  });
                }
              });
            } else {
              console.debug('No FB library found');
            }
          }}
          onReject={(err) => {
            console.error(err);
          }}
        >
          <Icon
            name={['fab', 'facebook']}
            vendor="fa"
            className="social outline"
          />
        </LoginSocialFacebook>
      )}
      {connectedTwitter && (
        <Icon
          name={['fab', 'x-twitter']}
          vendor="fa"
          className={`social ${autoPostTwitter ? 'filled twitter' : 'outline'}`}
          onClick={() => {
            setAutoPostTwitter((toggled) => !toggled);
          }}
        />
      )}
      {!connectedTwitter && (
        <LoginSocialTwitter
          isOnlyGetToken
          client_id={apiKeys.twitter.clientId || ''}
          redirect_uri={config.socialRedirectEndpoint.replace(
            ':platform',
            'twitter'
          )}
          scope={[
            'users.read',
            'tweet.read',
            'tweet.write',
            'like.read',
            'like.write',
          ].join('%20')}
          onResolve={({ provider, data }) => {
            console.debug(provider, data);
          }}
          onReject={(err) => {
            console.error(err);
          }}
        >
          <Icon
            name={['fab', 'x-twitter']}
            vendor="fa"
            className="social outline"
          />
        </LoginSocialTwitter>
      )}
      {connectedInstagram && (
        <Icon
          name={['fab', 'instagram']}
          vendor="fa"
          className={`social ${
            autoPostInstagram ? 'filled instagram' : 'outline'
          }`}
          onClick={() => {
            setAutoPostInstagram((toggled) => !toggled);
          }}
        />
      )}
      {!connectedInstagram && (
        <LoginSocialFacebook
          isOnlyGetToken
          appId={apiKeys.facebook.appId}
          configId={apiKeys.facebook.configId}
          scope="instagram_basic,instagram_content_publish,instagram_manage_comments,instagram_manage_messages"
          onResolve={({ provider, data }) => {
            console.debug(provider, data);
            if (window.FB) {
              window.FB.api('/me/accounts', (response) => {
                console.debug(response);
                if (response && !response.error) {
                  for (let i = 0; i < response.data.length; i++) {
                    const pageId = response.data[i].id;
                    // if (account.capabilities && account.capabilities.includes('INSTAGRAM_BASIC')) {
                    //   // This is an Instagram account, you can get its Instagram Business Account ID
                    //   console.debug(`Instagram Business Account ID: ${account.id}`);
                    // }

                    window.FB.api(
                      `/${pageId}?fields=access_token,instagram_business_account`,
                      (igres) => {
                        console.debug(igres);
                        if (igres && !igres.error) {
                          console.log(
                            `Instagram Business Access Token: ${igres.access_token}`
                          );
                          console.log(
                            `Instagram Business Account ID: ${igres.instagram_business_account.id}`
                          );
                        } else {
                          console.log(igres.error);
                        }
                      }
                    );
                  }
                }
              });
            } else {
              console.debug('No FB library found');
            }
          }}
          onReject={(err) => {
            console.error(err);
          }}
        >
          <Icon
            name={['fab', 'instagram']}
            vendor="fa"
            className="social outline"
          />
        </LoginSocialFacebook>
      )}
      {!connectedTikTok && (
        <LoginSocialTiktok
          client_key={apiKeys.tiktok.clientKey}
          redirect_uri={config.socialRedirectEndpoint.replace(
            ':platform',
            'tiktok'
          )}
          onResolve={({ provider, data }) => {
            console.debug(provider, data);
          }}
          onReject={(err) => {
            console.error(err);
          }}
        >
          <Icon
            name={['fab', 'tiktok']}
            vendor="fa"
            className={`social ${autoPostTikTok ? 'filled tiktok' : 'outline'}`}
          />
        </LoginSocialTiktok>
      )}
      <div>
        <Icon
          name={['fab', 'linkedin']}
          vendor="fa"
          className={`social ${
            autoPostLinkedIn ? 'filled linkedin' : 'outline'
          }`}
          onClick={() => {
            if (!connectedLinkedIn) {
              alert('Coming soon!');
            } else {
              setAutoPostLinkedIn((toggled) => !toggled);
            }
          }}
        />
      </div>
      <div>
        <Icon
          name={['fab', 'pinterest']}
          vendor="fa"
          className={`social ${
            autoPostPinterest ? 'filled pinterest' : 'outline'
          }`}
          onClick={() => {
            if (!connectedPinterest) {
              alert('Coming soon!');
            } else {
              setAutoPostPinterest((toggled) => !toggled);
            }
          }}
        />
      </div>
    </>
  );

  const renderAIModal = () => (
    <Modal
      isOpen={showAIModal}
      toggle={() => setShowAIModal(!showAIModal)}
      style={{ maxWidth: 600 }}
      fade
    >
      <div className="modal-header">
        <h5 className="modal-title h2">{'AI Text Generator'}</h5>
        <Button
          className="close"
          color=""
          onClick={() => setShowAIModal(false)}
        >
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody>
        <FormGroup>
          <textarea
            style={{ fontSize: '16px', overflowY: 'scroll' }}
            className="form-control"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="text" onClick={() => setShowAIModal(false)}>
          Cancel
        </Button>
        <Button
          color="brand"
          variant="contained"
          onClick={() => {
            if (prompt && prompt.length > 0) {
              setGenerating(true);
              axios
                .get(`${config.businessEndPoint}/social/generate/content`, {
                  params: {
                    id: selectedProfile.id,
                    type: selectedProfileType,
                    prompt,
                  },
                })
                .then((response) => {
                  console.debug(JSON.stringify(response.data));
                  if (customizeText) {
                    const newTextMap = { ...selectedTextMap };
                    newTextMap[
                      autoPostLabels().split(', ')[selectedTextField]
                    ] = response.data.text;
                    setSelectedTextMap(newTextMap);
                  } else {
                    if (selectedFormat === adFormats[0]) {
                      setSelectedText(response.data.text);
                    }
                    if (selectedFormat === adFormats[2]) {
                      setNubText(response.data.text);
                    }
                  }
                  setGenerating(false);
                  setShowAIModal(false);
                })
                .catch((error) => {
                  console.error(error);
                  setGenerating(false);
                });
            }
          }}
        >
          {generating && (
            <Spinner type="border" size="sm" style={{ marginRight: 10 }} />
          )}
          {generating ? 'Generating...' : 'Generate'}
        </Button>
      </ModalFooter>
    </Modal>
  );

  if (isMobile) {
    return (
      <ModalView
        open={props.open}
        onClose={() => onClose(false)}
        rightButtons={[
          {
            title: 'Publish',
            onClick: onSave,
          },
        ]}
      >
        <div className="create-post-modal" style={{ padding: 20 }}>
          {renderContent()}
          {renderMediaCropper()}
        </div>
        {renderAIModal()}
      </ModalView>
    );
  }
  return (
    <Modal
      isOpen={props.open}
      toggle={props.close}
      style={{ maxWidth: selectedIndex === 0 ? 900 : 1350 }}
      fade
    >
      <div className="modal-header">
        <h5 className="modal-title h2">{post.id ? 'Edit Post' : 'New Post'}</h5>
        <Button className="close" color="" onClick={onClose}>
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody className="create-post-modal">{renderContent()}</ModalBody>
      <ModalFooter>
        <div style={{ width: '100%', display: 'flex' }}>
          <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
            {!post.id && (
              <>
                <span>Auto Post To:</span>
                {renderPostToOptions()}
              </>
            )}
          </div>
          <div>
            {post.id && (
              <>
                <Button
                  color="text"
                  onClick={() => {
                    showDialogAlert({
                      title: 'Delete Post',
                      message: 'Are you sure you want to delete this post?',
                      buttons: [
                        {
                          label: 'No, cancel',
                          color: 'text',
                        },
                        {
                          label: 'Yes, delete',
                          color: 'danger',
                          variant: 'contained',
                          onClick: async () => {
                            // TODO Remove it from the planner in the store
                            // SocialStore.update((s) => {
                            //   const allPosts = [...s.profiles[`${selectedProfileType}s`][selectedProfile.id].posts];
                            //   for (let i = 0; i < allPosts.length; i++) {
                            //     console.debug(`Testing ${allPosts[i].business_post_id} against ${parseInt(post.id)}`);
                            //     console.debug(allPosts[i]);
                            //     console.debug(post);
                            //     if (parseInt(allPosts[i].business_post_id) === parseInt(post.id)) {
                            //       console.debug('Found! Removing!');
                            //       allPosts.splice(i, 1);
                            //       break;
                            //     }
                            //   }
                            //   s.profiles[`${selectedProfileType}s`][selectedProfile.id].posts = allPosts;
                            // });
                            if (post.platform === 'boxpressd') {
                              axios
                                .delete(
                                  `${config.businessEndPoint}/posts/${post.id}`
                                )
                                .then(() => {
                                  onClose(true);
                                })
                                .catch((err) => {
                                  console.error(err);
                                  showDialogAlert({
                                    message:
                                      'Something went wrong, please try again later',
                                  });
                                });
                            } else {
                              console.log('Post to delete:');
                              console.log(post);
                              axios
                                .delete(
                                  `${config.businessEndPoint}/social/${post.platform}/posts`,
                                  {
                                    params: {
                                      id: selectedProfile.id,
                                      type: selectedProfileType,
                                      account_id: post.account_id,
                                      post_id: post.id,
                                      external_post_id: post.external_post_id,
                                    },
                                  }
                                )
                                .then(() => {
                                  onClose(true);
                                })
                                .catch((err) => {
                                  console.error(err);
                                  showDialogAlert({
                                    message:
                                      'Something went wrong, please try again later',
                                  });
                                });
                            }
                          },
                        },
                      ],
                    });
                  }}
                  style={{ color: '#ff4700' }}
                >
                  <Icon
                    name="trash"
                    style={{ color: '#ff4700', marginRight: 6 }}
                  />
                  {'Delete'}
                </Button>{' '}
              </>
            )}
            <Button color="text" onClick={onClose}>
              {'Cancel'}
            </Button>{' '}
            <>
              <ButtonGroup
                variant="contained"
                ref={anchorRef}
                aria-label="split button"
              >
                <Button
                  disabled={
                    loading ||
                    (post.account_type !== 'facebook' && post.external_post_id)
                  }
                  color="brand"
                  onClick={() => {
                    onSave();
                  }}
                  style={{
                    borderBottomRightRadius: 0,
                    borderTopRightRadius: 0,
                  }}
                >
                  {loading && (
                    <Spinner
                      type="border"
                      color="light"
                      size="sm"
                      style={{ marginRight: 10 }}
                    />
                  )}
                  {/* FIXME If 'facebook' && post.external_post_id, text should be 'Update' or similar */}
                  {loading ? (
                    'Saving...'
                  ) : (
                    <>
                      {postOptions[selectedIndex]}
                      {selectedIndex === 1 && (
                        <IonIcon icon={sparkles} style={{ marginLeft: 4 }} />
                      )}
                    </>
                  )}
                </Button>
                <Button
                  size="small"
                  color="brand"
                  aria-controls={dropdownOpen ? 'split-button-menu' : undefined}
                  aria-expanded={dropdownOpen ? 'true' : undefined}
                  aria-label="select merge strategy"
                  aria-haspopup="menu"
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                  style={{
                    borderBottomLeftRadius: 0,
                    borderTopLeftRadius: 0,
                    borderLeft: '2px solid rgba(256,256,256,0.1)',
                  }}
                >
                  <ArrowDropDownIcon />
                </Button>
              </ButtonGroup>
              {/* SEE https://v4.mui.com/components/button-group/ */}
              <Popper
                open={dropdownOpen}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener
                        onClickAway={() => setDropdownOpen(false)}
                      >
                        <MenuList id="split-button-menu">
                          {postOptions.map((option, index) => (
                            <MenuItem
                              key={option}
                              // disabled={index === 2}
                              selected={index === selectedIndex}
                              onClick={() => {
                                if (index === 1 && !premium) {
                                  showPremiumPrompt({
                                    showDialogAlert,
                                    navigate,
                                    onClick: () => onClose(false),
                                  });
                                } else {
                                  setSelectedIndex(index);
                                }
                                setDropdownOpen(false);
                              }}
                            >
                              {option}
                              {index === 1 && (
                                <IonIcon
                                  icon={sparkles}
                                  style={{ marginLeft: 4 }}
                                />
                              )}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </>
          </div>
        </div>
      </ModalFooter>
      {renderAIModal()}
      {/* <SearchModal */}
      {/*  type={selectedTagType} */}
      {/*  open={showSearchModal} */}
      {/*  onClose={() => setShowSearchModal(false)} */}
      {/*  onClick={(item) => { */}
      {/*    setShowSearchModal(false); */}
      {/*    let typeId = ''; */}
      {/*    let imageUrl = ''; */}
      {/*    if (selectedTagType === 'cigars') { */}
      {/*      typeId = 'cigar_id'; */}
      {/*      imageUrl = item.image_url || Cigar.getBandImage(item); */}
      {/*    } */}
      {/*    if (selectedTagType === 'products') { */}
      {/*      typeId = 'product_id'; */}
      {/*      imageUrl = item.image_url || Product.getImage(item); */}
      {/*    } */}
      {/*    if (selectedTagType === 'venues') { */}
      {/*      typeId = 'venue_id'; */}
      {/*      imageUrl = item.profile_image_url || Venue.getProfileImage(item); */}
      {/*    } */}
      {/*    if (selectedTagType === 'brands') { */}
      {/*      typeId = 'brand_id'; */}
      {/*      imageUrl = item.logo_url || Brand.getProfileImage(item); */}
      {/*    } */}
      {/*    if (selectedTagType === 'users') { */}
      {/*      typeId = 'user_id'; */}
      {/*      imageUrl = item.image_url; */}
      {/*    } */}
      {/*    setTags([ */}
      {/*      ...tags, */}
      {/*      { */}
      {/*        item_type: selectedTagType, */}
      {/*        [typeId]: item.id || item.objectID, */}
      {/*        title: item.full_name || item.name, */}
      {/*        image_url: imageUrl, */}
      {/*      }, */}
      {/*    ]); */}
      {/*  }} */}
      {/* /> */}
    </Modal>
  );
}

CreateAdModal.show = (props) => {
  AppStore.update((s) => {
    s.showAdModal = true;
    s.adModalProps = props;
  });
};

CreateAdModal.close = () => {
  AppStore.update((s) => {
    s.showAdModal = false;
    s.adModalProps = {};
  });
};

export default CreateAdModal;
