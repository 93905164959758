import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { MediaStore } from '@/stores';
import ErrorLogger from '@/utils/errorLogger';
import { toBase64, uploadToS3 } from '@/utils/imageUtils';
import { useUploadStatus } from '@/utils/useUploadStatus';

// FIXME Add an UploadStatusProvider so a UI indicator can show that the user can't close the app yet or the image won't
//  succeed - it should attempt to prevent the user from closing the app if possible.

function FileUploader(props) {
  const { setUploadStatus } = useUploadStatus();
  const [mediaUploadStatus, setMediaUploadStatus] = useState({});

  useEffect(() => {
    console.log('Media Upload Status:');
    console.log(mediaUploadStatus);

    const fileKeys = Object.keys(mediaUploadStatus);
    if (fileKeys && fileKeys.length === props.files.length) {
      console.log('All statuses have been updated');
      let finishedUploading = true;
      fileKeys.forEach((key) => {
        if (
          mediaUploadStatus[key] !== 'uploaded' &&
          mediaUploadStatus[key] !== 'cancelled'
        ) {
          finishedUploading = false;
        }
      });

      if (finishedUploading) {
        console.log('Finished uploading');
        if (typeof props.uploading === 'function') {
          console.log('Calling props.uploading...');
          props.uploading(false);
        }
      }
    }
  }, [mediaUploadStatus]);

  const cancelUpload = (filename) => {
    console.log('Cancelled upload...');
    // TODO Actually cancel it?
    if (mediaUploadStatus[filename] !== 'uploaded') {
      setMediaUploadStatus((prevStatus) => ({
        ...prevStatus,
        [filename]: 'cancelled',
      }));
      Sentry.captureMessage('The file upload took longer than 6 seconds');
    }
  };

  // TODO Still want to make this a helper function - needs used by the media cropper
  const uploadMedia = async (blob, base64, type, id) => {
    // console.log('Existing UUID from uploader:');
    // console.log(id);
    // console.log('Base64:');
    // console.log(base64);
    const extension = base64
      ? base64.split(';')[0].split('/')[1]
      : blob.name.split('.').pop();

    // console.log(blob.type);
    // console.log(blob);

    const uuid = id || uuidv4();
    const filename = `${props
      .filenameTemplate()
      .replace('uuid', uuid)}.${extension}`;
    // console.log(`Filename: ${filename}`);

    // INFO If it takes longer than 5 seconds cancel and console.debug admins - we may need to resize media a little before uploading
    setTimeout(() => cancelUpload(filename), 6000);

    // console.log('Uploading to media server...');
    setTimeout(() => {
      setMediaUploadStatus((prevStatus) => ({
        ...prevStatus,
        [filename]: 'uploading',
      }));
    }, 100);

    console.log('Uploading to S3...');
    await uploadToS3(
      blob,
      props.folder,
      filename,
      uuid,
      (result) => {
        // This allows us to get the media URL as soon as it's ready rather than wait for the final upload
        const file = {
          ...result.data,
          uuid,
          type,
          src: base64,
        };
        for (let i = 0; i < props.files.length; i++) {
          const data = props.files[i];
          if (data.uuid && data.uuid === uuid) {
            props.files[i] = file;
          }
        }
        MediaStore.update((s) => {
          s.mediaMap[result.data.media_url] = URL.createObjectURL(blob);
        });
        // FIXME Different callback for this? onMediaUrl or something?
        // if (typeof props.onMediaUrl === 'function') {
        //   setTimeout(() => {
        //     props.onMediaUrl(file, [...props.files]);
        //   }, 50);
        // }
      },
      setUploadStatus
    )
      .then((res) => {
        console.log('Uploaded to media server!');
        console.log(JSON.stringify(res));
        Sentry.setExtra('uploadMedia_res_data', JSON.stringify(res.data));
        let t = 'image';
        if (blob.type.indexOf('video/') !== -1) {
          t = 'video';
        }
        const file = {
          ...res.data,
          uuid,
          type: t,
          src: base64,
        };
        for (let i = 0; i < props.files.length; i++) {
          const data = props.files[i];
          if (data.uuid && data.uuid === uuid) {
            props.files[i] = file;
          }
        }
        // console.log('Selected files:');
        // console.log(props.files);
        if (typeof props.onUploaded === 'function') {
          // INFO Creating a new array is required to get the state to update in order to show in MediaGrid - better approach?
          props.onUploaded(file, [...props.files]);
        }
        setTimeout(() => {
          setMediaUploadStatus((prevStatus) => ({
            ...prevStatus,
            [filename]: 'uploaded',
          }));
        }, 100);
      })
      .catch((err) => {
        console.log(err);
        if (typeof props.uploading === 'function') {
          props.uploading(false);
        }
        ErrorLogger.captureException(err);
      });
  };

  /**
   * This is used on the web only
   * @param blob
   * @param type
   */
  const convertMediaAndUpload = async (blob, type) => {
    console.log('Blob:');
    console.log(blob);
    console.log('Type:');
    console.log(type);
    await toBase64(blob).then(async (imageFile) => {
      const uuid = uuidv4();
      const file = {
        uuid,
        type,
        src: imageFile,
      };
      props.files.push(file);
      if (typeof props.uploading === 'function') {
        props.uploading(true);
      }
      if (typeof props.onUploading === 'function') {
        // console.log('Triggering onUploading!');
        // INFO Timeout helps ensure that this triggers after each item, not just the first
        setTimeout(() => {
          // INFO Creating a new array is required to get the state to update in order to show in MediaGrid - better approach?
          props.onUploading(file, [...props.files]);
        }, 50);
      }
      await uploadMedia(blob, imageFile, type, uuid);
    });
  };

  const handleImageFiles = async (files) => {
    const promises = [];
    for (let i = 0; i < files.length; i++) {
      promises.push(convertMediaAndUpload(files[i], 'image'));
    }
    await Promise.all(promises);
  };

  const onInputFileAdded = async (e) => {
    console.log('Event:');
    console.log(e);
    e.persist();
    if (typeof props.onChange === 'function') {
      props.onChange(e);
    }
    // console.log('Files:');
    // console.log(e.target.files);
    if (e.target.files[0].type.indexOf('image/') !== -1) {
      // console.log('Got image file!');
      await handleImageFiles(e.target.files);
    } else if (e.target.files[0].type.indexOf('video/') !== -1) {
      const videoFile = e.target.files[0];
      const uuid = uuidv4();
      const file = {
        uuid,
        type: 'video',
        src: URL.createObjectURL(videoFile),
      };
      props.files.push(file);
      if (typeof props.uploading === 'function') {
        props.uploading(true);
      }
      if (typeof props.onUploading === 'function') {
        setTimeout(() => {
          // INFO Creating a new array is required to get the state to update in order to show in MediaGrid - better approach?
          props.onUploading(file, [...props.files]);
        }, 50);
      }
      await uploadMedia(e.target.files[0], null, 'video', uuid);
    } else {
      // TODO? Right now we only handle images and videos on the server - no plans yet to allow other files, but may be useful for CSV and others
    }
  };

  return (
    <input
      id={props.id}
      type="file"
      multiple={props.multiple}
      accept={props.accept}
      ref={props.forwardedRef}
      style={{ display: 'none' }}
      onChange={onInputFileAdded}
    />
  );
}

FileUploader.propTypes = {
  id: PropTypes.string,
  files: PropTypes.array.isRequired,
  folder: PropTypes.string.isRequired,
  filenameTemplate: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  uploading: PropTypes.func,
  accept: PropTypes.string,
  onChange: PropTypes.func,
  onUploading: PropTypes.func,
  onUploaded: PropTypes.func,
};

FileUploader.defaultProps = {
  id: undefined,
  multiple: false,
  uploading: () => {},
  accept: undefined,
  onChange: () => {},
  onUploading: () => {},
  onUploaded: () => {},
};

export default FileUploader;
