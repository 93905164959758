import SvgIcon from '@mui/material/SvgIcon';

function BoxpressdLogoIcon(props) {
  return (
    <SvgIcon viewBox="0 0 500 500" {...props}>
      <g>
        <g>
          <path
            className="st1"
            d="M261.2,290.5c6.2,22-2.5,44.8-20.1,57.3l0,0c4.4-22,2.8-37.8-22.7-56c-7.5-5.4-13.2-10.6-17.5-15.3
			c-4.2-4.6-7.1-8.8-9-12c-1.3-2.4-2.4-4.9-3.4-7.5c-0.4-1.1-0.8-2.2-1.1-3.4c-6.2-22,2.5-44.8,20.1-57.3l0,0
			c-4.4,22-2.8,37.8,22.7,56c7.5,5.4,13.2,10.6,17.5,15.3c4.3,4.7,7.1,8.8,9,12.1c1.3,2.4,2.4,4.9,3.3,7.5
			C260.5,288.2,260.9,289.3,261.2,290.5z"
          />
          <path
            className="st1"
            d="M299.2,246c6.2,22-2.5,44.8-20.1,57.3l0,0c4.4-22,2.8-37.8-22.7-56c-7.5-5.4-13.2-10.6-17.5-15.3
			c-4.2-4.6-7.1-8.8-9-12c-1.3-2.4-2.4-4.9-3.4-7.5c-0.4-1.1-0.8-2.2-1.1-3.4c-6.2-22,2.5-44.8,20.1-57.3l0,0
			c-4.4,22-2.8,37.8,22.7,56c7.5,5.4,13.2,10.6,17.5,15.3c4.3,4.7,7.1,8.8,9,12.1c1.3,2.4,2.4,4.9,3.3,7.5
			C298.5,243.7,298.9,244.9,299.2,246z"
          />
        </g>
        <path
          className="st1"
          d="M374.7,95.9H49.1v232.1c0,42,34.2,76.2,76.2,76.2h325.5V172.1C450.9,130,416.7,95.9,374.7,95.9z M125.3,375.3
		c-26.2,0-47.4-21.2-47.4-47.4V124.7h296.7c26.1,0,47.4,21.2,47.4,47.4v203.2H125.3z"
        />
      </g>
    </SvgIcon>
  );
}

export default BoxpressdLogoIcon;
