import IconButton from '@mui/material/IconButton';
import { Picker as EmojiPicker } from 'emoji-mart';
import { isMobile } from 'mobile-device-detect';
import React, { useState } from 'react';
import ReactGiphySearchbox from 'react-giphy-searchbox';
import { Popover, PopoverBody } from 'reactstrap';

import Icon from '@/components/Icon';

export const customEmojis = [
  {
    name: 'Boxpressd Like',
    colons: ':boxpressd-like:',
    short_names: ['boxpressd-like'],
    text: '',
    emoticons: [],
    keywords: ['boxpressd', 'cigar', 'thumb', 'like'],
    imageUrl: 'https://cdn.boxpressd.io/placeholder/boxpressd-liked.png',
    customCategory: 'Boxpressd',
  },
];

export const customEmojiForShortname = (shortname) => {
  for (let i = 0; i < customEmojis.length; i++) {
    const emoji = customEmojis[i];
    console.log(emoji);
    console.log(emoji.short_names);
    console.log(shortname);
    if (emoji.short_names.indexOf(shortname) !== -1) {
      console.log('Found custom emoji!!');
      console.log(emoji);
      return emoji;
    }
  }
  return null;
};

function PostActionIcons(props) {
  const [showEmojiOptions, setShowEmojiOptions] = useState(false);
  const [showGifOptions, setShowGifOptions] = useState(false);

  let addMedia;

  if (isMobile) {
    return (
      <>
        {props.showMediaIcon && (
          <IconButton
            // className={classes.iconButton}
            aria-label="add attachment"
            onClick={() => {
              addMedia.click();
              setShowEmojiOptions(false);
              setShowGifOptions(false);
            }}
          >
            <Icon name="camera" style={{ fontSize: 20 }} />
          </IconButton>
        )}
        <IconButton
          className="gif-icon"
          aria-label="add gif"
          onClick={() => {
            if (typeof props.onGifIconClick === 'function') {
              props.onGifIconClick();
            } else {
              setShowEmojiOptions(false);
              setShowGifOptions(!showGifOptions);
            }
          }}
        >
          <span
            style={{
              fontSize: 10,
              border: '2px solid',
              padding: '1px 3px',
              borderRadius: 6,
            }}
          >
            gif
          </span>
        </IconButton>
        <IconButton
          // className={classes.iconButton}
          aria-label="add emoji"
          onClick={() => {
            if (typeof props.onEmojiIconClick === 'function') {
              props.onEmojiIconClick();
            } else {
              setShowGifOptions(false);
              setShowEmojiOptions(!showEmojiOptions);
            }
          }}
        >
          <Icon name="smile" style={{ fontSize: 20 }} />
        </IconButton>
        {/* FIXME Swiith to FileUploader */}
        {/* <FileUploader */}
        {/*  id="image-picker" */}
        {/*  multiple */}
        {/*  accept="image/*" */}
        {/*  forwardedRef={addPhotos} */}
        {/*  files={thumbnails} */}
        {/*  folder={props.uploadFolder} */}
        {/*  filenameTemplate={() => { */}
        {/*    const userHash = hashids.encode(props.auth.user.id); */}
        {/*    return `${userHash}-uuid`; */}
        {/*  }} */}
        {/*  uploading={(isUploading) => { */}
        {/*    if (typeof props.uploading === 'function') { */}
        {/*      props.uploading(isUploading); */}
        {/*    } */}
        {/*  }} */}
        {/*  onUploading={(uploadingFile, files) => { */}
        {/*    if (typeof props.onMediaSelected === 'function') { */}
        {/*      props.onMediaSelected(files); */}
        {/*    } */}
        {/*  }} */}
        {/*  onUploaded={(uploadedFile, files) => { */}
        {/*    if (typeof props.onUploaded === 'function') { */}
        {/*      props.onUploaded(files); */}
        {/*    } */}
        {/*  }} */}
        {/* /> */}
        <input
          id={`file-picker-${props.uid}`}
          type="file"
          multiple
          accept="image/*,video/*"
          ref={(ref) => (addMedia = ref)}
          style={{ display: 'none' }}
          onChange={async (e) => {
            e.persist();
            console.log(e.target.files);
            if (typeof props.onMediaSelected === 'function') {
              props.onMediaSelected(e);
            }
          }}
        />
      </>
    );
  }
  return (
    <>
      {props.showMediaIcon && (
        <IconButton
          // className={classes.iconButton}
          aria-label="add attachment"
          onClick={() => {
            // TODO Enable the media input handling
            addMedia.click();
            setShowEmojiOptions(false);
            setShowGifOptions(false);
          }}
        >
          <Icon name="camera" style={{ fontSize: 20 }} />
        </IconButton>
      )}
      <IconButton
        id={`gif-icon-button-${props.uid}`}
        className="gif-icon"
        aria-label="add gif"
        onClick={() => {
          setShowEmojiOptions(false);
          setShowGifOptions(!showGifOptions);
        }}
      >
        <span style={{ fontSize: 12 }}>gif</span>
      </IconButton>
      <Popover
        className="popover-controller"
        placement="auto"
        // trigger="focus" // FIXME This closes before the onSelect action is handled
        isOpen={showGifOptions}
        target={`gif-icon-button-${props.uid}`}
        toggle={() => setShowGifOptions(!showGifOptions)}
      >
        <PopoverBody>
          <ReactGiphySearchbox
            apiKey="9Ixlv3DWC1biJRI57RanyL7RTbfzz0o7"
            onSelect={(item) => {
              // console.log(item);
              // console.log(item.embed_url);
              setShowGifOptions(false);
              if (typeof props.onGifSelected === 'function') {
                props.onGifSelected(item);
              }
            }}
            poweredByGiphyImage={null}
            // loadingImage={spinner}
            style={{ width: props.gifPickerWidth }}
            masonryConfig={[
              {
                columns: 3,
                imageWidth: 'calc((100vw - 52px) / 3)',
                gutter: 10,
              },
            ]}
          />
        </PopoverBody>
      </Popover>
      <IconButton
        id={`emoji-icon-button-${props.uid}`}
        // className={classes.iconButton}
        aria-label="add emoji"
        onClick={() => {
          setShowGifOptions(false);
          setShowEmojiOptions(!showEmojiOptions);
        }}
      >
        <Icon name="smile" style={{ fontSize: 20 }} />
      </IconButton>
      <Popover
        className="popover-controller"
        placement="auto"
        // trigger="click" // FIXME This closes before the onClick action is handled
        isOpen={showEmojiOptions}
        target={`emoji-icon-button-${props.uid}`}
        toggle={() => setShowEmojiOptions(!showEmojiOptions)}
      >
        <PopoverBody>
          {/* https://github.com/reactstrap/reactstrap/issues/1518 */}
          <EmojiPicker
            native // TODO We can let the user pick which set to use or default to their native set - 'apple', 'google', 'twitter', 'facebook'
            // set={}
            color="#d6c290"
            custom={customEmojis}
            showPreview={false}
            theme="auto" // TODO Base this on user's global theme selection (darkMode bool)
            style={{ width: props.emojiPickerWidth }}
            onClick={(data, event) => {
              // console.log('Emoji chosen', data);
              // console.log('Emoji chosen unified', `u\\${data.unified}`);
              // console.log('Emoji chosen symbol', data.native);
              if (typeof props.onEmojiSelected === 'function') {
                props.onEmojiSelected(data);
              }
            }}
          />
        </PopoverBody>
      </Popover>
      <input
        id={`file-picker-${props.uid}`}
        type="file"
        multiple
        accept="image/*,video/*"
        ref={(ref) => (addMedia = ref)}
        style={{ display: 'none' }}
        onChange={async (e) => {
          e.persist();
          console.log(e.target.files);
          if (typeof props.onMediaSelected === 'function') {
            props.onMediaSelected(e);
          }
        }}
      />
    </>
  );
}

PostActionIcons.defaultProps = {
  showMediaIcon: true,
  gifPickerWidth: '100%',
  gifPickerColumns: 3,
  emojiPickerWidth: '100%',
};

export default PostActionIcons;
