import { useIonActionSheet } from '@ionic/react';
import { List, ListItem, ListItemText } from '@mui/material';
import { isMobile } from 'mobile-device-detect';
import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

import BottomSheet from '@/components/BottomSheet';
import Icon from '@/components/Icon';
import { language, messages } from '@/utils/localeUtils';

interface Props {
  id?: string;
  open: boolean;
  toggle: () => void;
  title?: string;
  message?: string;
  items: {
    content: string;
    role?: string;
    onClick?: () => void;
    icon?: React.ReactNode;
  }[];
}

function ActionSheet({ id, open, toggle, title, items }: Props) {
  if (isMobile) {
    return (
      <BottomSheet
        id={id}
        open={open}
        title={title}
        className="auto-height-sheet"
        items={items.map((item) => ({
          content: item.content,
          icon: item.icon || undefined,
          role: item.role || undefined,
          onClick: item.onClick,
        }))}
        onClose={toggle}
      />
    );
  }
  return (
    <Modal isOpen={open} toggle={toggle} style={{ maxWidth: 320 }} fade>
      <div className="modal-header" style={{ padding: 12 }}>
        {title && <h3>{title}</h3>}
        <Button className="close" color="" onClick={toggle}>
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody style={{ padding: 4 }}>
        <List>
          {items.map((item, index) => (
            <ListItem
              key={index}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (typeof item.onClick === 'function') {
                  item.onClick();
                }
                toggle();
              }}
            >
              {item.icon && typeof item.icon === 'string' && (
                <Icon
                  name={item.icon}
                  // @ts-ignore
                  style={{ marginRight: 12 }}
                />
              )}
              {item.icon && typeof item.icon !== 'string' && item.icon}
              <ListItemText primary={item.content} />
            </ListItem>
          ))}
        </List>
      </ModalBody>
      <ModalFooter style={{ padding: 12 }}>
        <Button color="secondary" onClick={toggle}>
          {/* @ts-ignore */}
          {messages[language]?.close || 'Close'}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ActionSheet;

export const useActionSheet = () => {
  const [present] = useIonActionSheet();

  const showActionSheet = (options) => {
    if (isMobile) {
      // For mobile devices
      present({
        header: options.title,
        buttons: (options.buttons || []).map((button) => ({
          text: button.content,
          role: button.role || 'confirm', // cancel, confirm, destructive
          handler: () => {
            if (button.onClick) {
              button.onClick();
            }
          },
        })),
      });
    } else {
      present({
        header: options.title,
        buttons: (options.buttons || []).map((option) => ({
          text: option.content,
          value: option.value,
          role: option.role,
          handler: option.onClick,
        })),
      });
    }
  };

  return { showActionSheet };
};
