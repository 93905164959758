import ls from 'local-storage';

import firebaseApp from '@/config/firebase.config';
import type User from '@/models/User';
import { UserStore } from '@/stores';

export const updateAuth = (
  user: User,
  tokenId: string,
  callback?: (tokenId: string) => void
) => {
  UserStore.update((s) => {
    // @ts-ignore
    s.user = user;
  });

  console.log('Storing logged in user to local storage');
  ls('authenticated', true);
  ls('user', user);
  ls('boxpressd_token_id', tokenId);
  ls('firebase_id', firebaseApp.auth().currentUser?.uid);
  console.log('Stored logged in user to local storage');

  // FIXME Only use one or the other, not both cookie and ls - only benefit to a cookie might be to force user to reauthenticate after 30 days or something
  // Cookies.set('tokenId', action.token_id);
  // Cookies.set('tokenId', action.token_id, { expires: 3 });

  if (typeof callback === 'function') {
    console.log('Running callback for success');
    callback(tokenId);
  }
};
