import './style.scss';

import { Avatar } from '@mui/material';
import axios from 'axios';
// FIXME What's the updated version of this? See NextJS project - I think I updated it there already
import { emojiIndex } from 'emoji-mart';
import React from 'react';
import { MentionsInput } from 'react-mentions';

import Mention from '@/components/Mention';
import Cigar from '@/models/Cigar';
import Product from '@/models/Product';
import { config } from '@/settings';
import { AppStore } from '@/stores';

export const customEmojis = [
  {
    name: 'Boxpressd Like',
    colons: ':boxpressd-like:',
    short_names: ['boxpressd-like'],
    text: '',
    emoticons: [],
    keywords: ['boxpressd', 'cigar', 'thumb', 'like'],
    imageUrl: 'https://cdn.boxpressd.io/placeholder/boxpressd-liked.png',
    customCategory: 'Boxpressd',
  },
];

export const customEmojiForShortname = (shortname) => {
  for (let i = 0; i < customEmojis.length; i++) {
    const emoji = customEmojis[i];
    console.debug(emoji);
    console.debug(emoji.short_names);
    console.debug(shortname);
    if (emoji.short_names.indexOf(shortname) !== -1) {
      console.debug('Found custom emoji!!');
      console.debug(emoji);
      return emoji;
    }
  }
  return null;
};

function fetchUsers(query, callback) {
  if (!query) return;
  axios
    .get(`${config.apiEndPoint}/users/search`, {
      params: {
        q: query,
        limit: 30,
      },
    })
    .then((response) => {
      console.debug(response);
      callback(
        response.data.map((user) => ({
          display: user.full_name,
          id: user.id,
          imageUrl: user.image_url,
        }))
      );
    })
    .catch((err) => {
      console.error(err);
    });
}

function fetchCigars(query, callback) {
  if (!query) return;
  axios
    .get(`${config.apiEndPoint}/cigars/search`, {
      params: {
        q: query,
        limit: 30,
      },
    })
    .then((response) => {
      console.debug(response);
      callback(
        response.data.map((cigar) => ({
          display: cigar.full_name,
          id: cigar.id,
          imageUrl: Cigar.getBandImage(cigar),
        }))
      );
    })
    .catch((err) => {
      console.error(err);
    });
}

function fetchBrands(query, callback) {
  if (!query) return;
  axios
    .get(`${config.apiEndPoint}/brands`, {
      params: {
        q: query,
        limit: 30,
      },
    })
    .then((response) => {
      console.debug(response);
      callback(
        response.data.map((brand) => ({
          display: brand.name,
          id: brand.id,
          imageUrl: brand.logo_image_url,
        }))
      );
    })
    .catch((err) => {
      console.error(err);
    });
}

function fetchVenues(query, callback) {
  if (!query) return;
  axios
    .get(`${config.apiEndPoint}/venues/search`, {
      params: {
        q: query,
        limit: 30,
        // TODO Include the lat/lng if available
      },
    })
    .then((response) => {
      console.debug(response);
      callback(
        response.data.map((venue) => ({
          display: venue.name,
          id: venue.id,
          imageUrl: venue.profile_image_url,
        }))
      );
    })
    .catch((err) => {
      console.error(err);
    });
}

function fetchProducts(query, callback) {
  if (!query) return;
  axios
    .get(`${config.shopEndPoint}/products/search`, {
      params: {
        q: query,
        limit: 30,
      },
    })
    .then((response) => {
      console.debug(response);
      callback(
        response.data.map((product) => ({
          display: Product.title(product),
          id: product.id,
          imageUrl: Product.getImage(product),
        }))
      );
    })
    .catch((err) => {
      console.error(err);
    });
}

function fetchEmojis(query) {
  if (!query) return;
  // FIXME 'native' is default - user should be allowed to pick their Emoji set
  return emojiIndex
    .search(query)
    .map((emoji) => ({ id: emoji.native, display: emoji.colons }));
}

function fetchCustomEmojis(query) {
  if (!query) return;
  return customEmojis
    .filter((emoji) => {
      for (const shortName of emoji.short_names) {
        if (shortName.toLowerCase().indexOf(query.toLowerCase()) !== -1) {
          return true;
        }
      }
      return false;
    })
    .map((emoji) => ({
      id: emoji.colons,
      display: emoji.colons.replace(/:/g, ''),
    }));
}

function MentionsTextArea(props) {
  const darkMode = AppStore.useState((s) => s.darkMode);
  // FIXME This should use the same logic that richcard uses to parse out mentions and emojis. All other HTML should
  //  probably be sanitized and/or stripped out
  const formatContent = (content) => {
    return content;
  };
  const renderSuggestion = (entry) => (
    <div
      style={{
        padding: 4,
        lineHeight: '1.6rem',
        fontSize: '1.1rem',
        display: 'flex',
      }}
    >
      <Avatar
        src={entry.imageUrl}
        style={{ height: 24, width: 24, marginRight: 6 }}
      >
        {entry.display.charAt(0)}
      </Avatar>
      {entry.display}
    </div>
  );
  return (
    <div>
      {/* <ContentEditable */}
      {/*  id="comment-content" */}
      {/*  className="contenteditable form-control form-control-clean form-input" */}
      {/*  tagName="div" */}
      {/*  html={formatContent(props.value)} */}
      {/*  placeholder="Write a reply..." // TODO FB changes this text depending on whether it's a 'comment' on a post or a 'reply' to a comment */}
      {/*  // onChange={(e) => setTextContent(sanitizeText(e.target.value))} */}
      {/*  // onChange={(e) => setTextContent(e.target.value)} */}
      {/* /> */}
      <MentionsInput
        inputRef={props.innerRef}
        allowSpaceInQuery
        allowSuggestionsAboveCursor
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        autocomplete="off"
        style={{ height: 120, ...props.style }}
        className="mention-form-control"
        // classNames={classNames}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
      >
        {/* FIXME These should only attempt to render if they match exactly - make sure something like "@:" doesn't attempt to render */}
        <Mention
          trigger="@"
          // regex={/@(\S+)/}
          markup="[@__display__|userId:__id__]"
          data={fetchUsers}
          appendSpaceOnAdd
          renderSuggestion={renderSuggestion}
          // className={classNames['form-control__mention']}
          // FIXME Even we adjusting this manually, the mentions still aren't fully in the right place
          style={{
            position: 'relative',
            zIndex: 1,
            fontWeight: 600,
            color: darkMode ? '#ffffff' : '#1b1b1b',
            backgroundColor: darkMode ? '#17191d' : 'white',
            // lineHeight: '1.8rem',
          }}
        />
        <Mention
          trigger="@"
          markup="[@__display__|brandId:__id__]"
          data={fetchBrands}
          appendSpaceOnAdd
          renderSuggestion={renderSuggestion}
          style={{
            position: 'relative',
            zIndex: 1,
            fontWeight: 600,
            // color: '#d3a966',
            color: darkMode ? '#ffffff' : '#1b1b1b',
            backgroundColor: darkMode ? '#17191d' : 'white',
            // lineHeight: '1.8rem',
          }}
        />
        <Mention
          trigger="@"
          markup="[@__display__|cigarId:__id__]"
          data={fetchCigars}
          appendSpaceOnAdd
          renderSuggestion={renderSuggestion}
          style={{
            position: 'relative',
            zIndex: 1,
            fontWeight: 600,
            // color: '#d3a966',
            color: darkMode ? '#ffffff' : '#1b1b1b',
            backgroundColor: darkMode ? '#17191d' : 'white',
            // lineHeight: '1.8rem',
          }}
        />
        <Mention
          trigger="@"
          markup="[@__display__|venueId:__id__]"
          data={fetchVenues}
          appendSpaceOnAdd
          renderSuggestion={renderSuggestion}
          style={{
            position: 'relative',
            zIndex: 1,
            fontWeight: 600,
            // color: '#d3a966',
            color: darkMode ? '#ffffff' : '#1b1b1b',
            backgroundColor: darkMode ? '#17191d' : 'white',
            // lineHeight: '1.8rem',
          }}
        />
        <Mention
          trigger="@"
          markup="[@__display__|productId:__id__]"
          data={fetchProducts}
          appendSpaceOnAdd
          renderSuggestion={renderSuggestion}
          style={{
            position: 'relative',
            zIndex: 1,
            fontWeight: 600,
            // color: '#d3a966',
            color: darkMode ? '#ffffff' : '#1b1b1b',
            backgroundColor: darkMode ? '#17191d' : 'white',
            // lineHeight: '1.8rem',
          }}
        />
        {/* FIXME Re-introduce this later (maybe) it doesn't work very well right now */}
        {/* <Mention */}
        {/*  trigger=":" */}
        {/*  markup="__id__" */}
        {/*  appendSpaceOnAdd */}
        {/*  renderSuggestion={renderSuggestion} */}
        {/*  regex={/($a)/} */}
        {/*  data={fetchEmojis} */}
        {/* /> */}
        {/* <Mention */}
        {/*  trigger=":" */}
        {/*  // markup="__id__" */}
        {/*  markup=":__display__:" */}
        {/*  appendSpaceOnAdd */}
        {/*  displayTransform={(display) => { */}
        {/*    const emoji = customEmojiForShortname(display); */}
        {/*    let imageUrl = ''; */}
        {/*    if (emoji) { */}
        {/*      imageUrl = emoji.imageUrl; */}
        {/*    } */}
        {/*    return ( */}
        {/*      <img */}
        {/*        src={imageUrl} */}
        {/*        className="emoji" */}
        {/*        data-shortname={`:${display}:`} */}
        {/*        style={{ */}
        {/*          position: 'relative', */}
        {/*          zIndex: 1, */}
        {/*          backgroundColor: darkMode ? '#17191d' : 'white', */}
        {/*        }} */}
        {/*      /> */}
        {/*    ); */}
        {/*  }} */}
        {/*  renderSuggestion={(entry) => { */}
        {/*    const emoji = customEmojiForShortname(entry.display); */}
        {/*    const imageUrl = emoji && emoji.imageUrl; */}
        {/*    return <div style={{ fontSize: '1.1rem' }}><img src={imageUrl} className="emoji" data-shortname={entry.id} /> {entry.id}</div>; */}
        {/*  }} */}
        {/*  data={fetchCustomEmojis} */}
        {/* /> */}
        {/* TODO Allow hashtags? Should search `tags` table if so */}
        {/* <Mention */}
        {/*  trigger="#" */}
        {/*  markup="__id__" */}
        {/*  appendSpaceOnAdd */}
        {/*  renderSuggestion={renderSuggestion} */}
        {/*  regex={/($a)/} */}
        {/*  data={fetchTags} */}
        {/* /> */}
      </MentionsInput>
    </div>
  );
}

export default MentionsTextArea;
