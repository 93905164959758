import { IonProgressBar, IonToast } from '@ionic/react';
import { isMobile } from 'mobile-device-detect';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { useUploadStatus } from '@/utils/useUploadStatus';

const UploadStatus = () => {
  const { uploadStatus } = useUploadStatus();
  const [showToast, setShowToast] = useState(false);
  const [progress, setProgress] = useState(0);

  const toastId = useRef(null);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (uploadStatus.status === 'uploading') {
        const message =
          'Your upload is still in progress. Are you sure you want to leave?';
        event.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
      }
    };

    setProgress(uploadStatus.progress / 100);
    setShowToast(uploadStatus.progress !== 0 && uploadStatus.progress < 100);

    console.log('Updating upload status...');
    console.log(uploadStatus);

    if (!isMobile && uploadStatus.progress !== 0) {
      if (toastId.current === null) {
        toastId.current = toast.info(
          'Upload in progress... Do not close this window.',
          { progress }
        );
      } else {
        toast.update(toastId.current, { progress });
      }
      if (uploadStatus.progress === 100 && toastId.current) {
        toast.done(toastId.current);
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [uploadStatus]);

  if (isMobile) {
    return (
      <>
        <IonToast
          isOpen={showToast}
          message="Uploading media... Do not close app."
          position="bottom"
        />
        {showToast && (
          <IonProgressBar
            value={progress}
            style={{ position: 'fixed', bottom: '60px', left: 0, right: 0 }}
          />
        )}
      </>
    );
  }
  return null;
};

export default UploadStatus;
